import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { IArea } from '../../../model/area.model';
import { IProject } from '../../../model/project.model';

@Component({
    selector: 'bp-select-schedule-area',
    templateUrl: './select-schedule-area.component.html',
    styleUrls: ['select-schedule-area.scss']
})
export class SelectScheduleAreaComponent {
    @Input() project: IProject;
    @Input() scheduleAreas: IArea[];
    @Output() onChanged = new EventEmitter();

    selectedScheduleArea: IArea = null;

    areaDropDown = false;

    constructor() {}

    onClickOutside(): void {
        this.closeAreaDropDown();
    }

    openAreaDropdown(): void {
        if (!this.areaDropDown) {
            this.areaDropDown = true;
        }
    }

    selectArea(scheduleArea: IArea): void {
        this.selectedScheduleArea = scheduleArea.id === -1 ? null : scheduleArea;
        this.closeAreaDropDown();
        this.onChanged.emit(this.selectedScheduleArea);
    }

    closeAreaDropDown(): void {
        this.areaDropDown = false;
    }

    getAreasToShow(): IArea[] {
        return _.filter(this.scheduleAreas, (scheduleArea: IArea) => {
            if (this.selectedScheduleArea === null) {
                return scheduleArea.id !== -1;
            } else {
                return scheduleArea;
            }
        });
    }
}
