import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[bpPercent]',
    providers: [NgModel]
})
export class BpPercentDirective {
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

    constructor(private el: ElementRef) {}

    @HostListener('change') onInputChange() {
        const value = this.el.nativeElement.value / 100;
        this.ngModelChange.emit(value);
    }
}
