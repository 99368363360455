<form (ngSubmit)="addScheduleStage()"
      name="addStageForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Add Stage
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <bp-select-input
            (onSelectionChange)="onStageSelectionChange($event)"
            *ngIf="stageSelectInputData"
            [data]="stageSelectInputData"
            class="select-stage-id">
        </bp-select-input>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                class="btn btn-secondary btn-border-radius"
                data-dismiss="modal"
                type="button">
            Cancel
        </button>
        <button [disabled]="!selectedStage"
                class="btn btn-primary btn-border-radius waves-effect add-stage"
                type="submit">
            Add Stage
        </button>
    </div>
</form>
