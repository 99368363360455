<div class="flex flex-column gap-20 m-b-20">
    <bp-project-details-invitations>
    </bp-project-details-invitations>

    <bp-suggested-quoters *ngIf="this.context.showContractorFeature">
    </bp-suggested-quoters>

    <div class="row m-t-100">
        <div class="col-sm-6">
            <button [routerLink]="['/scheduler/schedule', context.project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous m-r-20"
                    ngbTooltip="Back to Schedule"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back to Schedule
            </button>
        </div>
        <div class="col-sm-6">
            <div class="text-right">
                <button (click)="context.compareQuotes()"
                        class="btn btn-primary btn-border-radius waves-effect next"
                        ngbTooltip="Next"
                        type="button">
                    Next
                    <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
