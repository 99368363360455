<form #f="ngForm"
      name="form"
      novalidate>
    <div class="m-b-10">
        <div class="row">
            <div class="col-sm-4">
                <a [routerLink]="['../../../schedule', project.id]"
                   class="back-link">
                    <i class="material-icons">arrow_back</i> Back to schedule
                </a>
            </div>

            <div class="col-sm-4 text-center">
                <h1>
                    Task Details
                </h1>
            </div>
            <div class="col-sm-4 text-right">
                <a (click)="showDeleteConfirmation()"
                   class="btn btn-secondary btn-border-radius m-r-10"
                   ngbTooltip="Delete">
                    <span>Delete</span>
                </a>
                <button (click)="(f.disabled || f.valid) && onSaveTaskClick()"
                        [disabled]="inProcess()"
                        class="btn btn-primary btn-border-radius waves-effect pull-right"
                        ngbTooltip="Save and Exit"
                        type="button">
                    Save and Exit
                </button>
            </div>
        </div>

        <div class="row m-t-15">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header flex justify-content-between">
                        <h2>
                            {{ scheduleTask.task }}
                        </h2>

                        <h2>
                            {{ ((taskTotal$ | async) || 0) | currency:'GBP' }}
                        </h2>
                    </div>
                    <div class="horizontal-line"></div>

                    <div>
                        <table class="af-table align-right materials m-t-30">
                            <thead>
                            <tr>
                                <th style="width: 40%;"
                                    class="align-left">
                                </th>
                                <th style="width: 20%;"
                                    class="align-left">
                                    Reference URL
                                </th>
                                <th style="width: 7%;">
                                    Qty
                                </th>
                                <th style="width: 7%; padding-right: 15px">
                                    Unit
                                </th>
                                <th style="width: 10%;">
                                    Cost/Unit
                                </th>
                                <th style="width: 14%; text-align: center;">
                                    Client Supplied
                                </th>

                                <th style="text-align: center;">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="m-t-30">
                                <td colspan="1000"
                                    class="text-left">
                                    <h4>
                                        Materials
                                    </h4>
                                </td>
                            </tr>

                            <tr *ngFor="let actualMaterialComponent of scheduleTask.actualMaterialComponents | sort:'isPrime' : 'desc'"
                                class="line-height"
                                [ngClass]="{'secondary-material' : !actualMaterialComponent.isPrime }">
                                <td style="padding-right: 30px;"
                                    class="align-left"
                                    *ngIf="actualMaterialComponent.materialId">
                                    <ng-container *ngIf="actualMaterialComponent.isPrime; else secondaryMaterial">
                                        <bp-select-input
                                            *ngIf="primeMaterialSelectInputData"
                                            (onSelectionChange)="onMaterialSelectionChange($event, actualMaterialComponent)"
                                            [data]="primeMaterialSelectInputData">
                                        </bp-select-input>
                                    </ng-container>
                                    <ng-template #secondaryMaterial>
                                        <div>{{ actualMaterialComponent.material }}</div>
                                    </ng-template>
                                </td>
                                <td style="padding-right: 30px;"
                                    class="align-left"
                                    *ngIf="!actualMaterialComponent.materialId">
                                    Could not find material
                                </td>
                                <td class="line-height">
                                    <input #referenceUrl="ngModel"
                                           [(ngModel)]="actualMaterialComponent.referenceUrl"
                                           [disabled]="!actualMaterialComponent.isPrime"
                                           [name]="'referenceUrl-' + actualMaterialComponent.id"
                                           [id]="'referenceUrl-' + actualMaterialComponent.id"
                                           class="af-input url"
                                           autocomplete="off"
                                           placeholder="Reference url"
                                           type="text">
                                </td>
                                <td class="line-height">
                                    {{ actualMaterialComponent.quantity }}
                                </td>
                                <td style="padding-right: 15px">
                                    {{ actualMaterialComponent.unit }}
                                </td>
                                <td>
                                    <div
                                        *ngIf="!actualMaterialComponent.clientSupplied && !scheduleTask.isSchedulerSubcontract && !scheduleTask.isSchedulerProvisional"
                                        class="flex-line">
                                        <input #materialCost="ngModel"
                                               [(ngModel)]="actualMaterialComponent.cost"
                                               (ngModelChange)="updateTaskTotal()"
                                               [name]="'materialCost-' + actualMaterialComponent.id"
                                               [id]="'materialCost-' + actualMaterialComponent.id"
                                               class="af-input text-right"
                                               autocomplete="off"
                                               mask="separator.2"
                                               allowNegativeNumbers="true"
                                               thousandSeparator=","
                                               prefix="&pound;&nbsp;"
                                               type="text">
                                    </div>

                                    <div *ngIf="scheduleTask.isSchedulerSubcontract"
                                         class="flex-line">
                                        <input #subcontractCost="ngModel"
                                               [(ngModel)]="actualMaterialComponent.subcontractCost"
                                               (blur)="updateTaskTotal()"
                                               [name]="'actualMaterialSubcontractCost-' + actualMaterialComponent.id"
                                               [id]="'actualMaterialSubcontractCost-' + actualMaterialComponent.id"
                                               class="af-input text-right"
                                               autocomplete="off"
                                               mask="separator.2"
                                               allowNegativeNumbers="true"
                                               thousandSeparator=","
                                               prefix="&pound;&nbsp;"
                                               type="text">
                                    </div>

                                    <div *ngIf="scheduleTask.isSchedulerProvisional"
                                         class="flex-line">
                                        <input #provisionalCost="ngModel"
                                               [(ngModel)]="actualMaterialComponent.cost"
                                               (ngModelChange)="onSubComponentProvisionalCostChange()"
                                               [name]="'actualMaterialProvisionalCost-' + actualMaterialComponent.id"
                                               [id]="'actualMaterialProvisionalCost-' + actualMaterialComponent.id"
                                               class="af-input text-right"
                                               autocomplete="off"
                                               mask="separator.2"
                                               allowNegativeNumbers="true"
                                               thousandSeparator=","
                                               prefix="&pound;&nbsp;"
                                               type="text">
                                    </div>
                                </td>
                                <td class="form-check text-center"
                                    style="top: -4px">
                                    <label class="form-check-label"
                                           *ngIf="actualMaterialComponent.isPrime">
                                        <input [checked]="!actualMaterialComponent.clientSupplied"
                                               (change)="actualMaterialComponent.clientSupplied = !actualMaterialComponent.clientSupplied; updateTaskTotal()"
                                               [name]="'clientSupplied-' + actualMaterialComponent.id"
                                               [id]="'clientSupplied-' + actualMaterialComponent.id"
                                               class="form-check-input"
                                               type="checkbox">
                                        <span class="form-check-sign"><span class="check"></span></span>
                                    </label>
                                </td>
                                <td class="text-center">
                                    <button (click)="removeActualMaterialComponent($event, actualMaterialComponent)"
                                            type="button"
                                            title="Remove material"
                                            class="btn btn-link">
                                        <fa-icon [icon]="['fas', 'ban']"></fa-icon>
                                    </button>
                                </td>
                            </tr>

                            <tr *ngIf="scheduleTask.actualMaterialComponents.length === 0"
                                class="no-items text-left">
                                <td colspan="1000">
                                    No items
                                </td>
                            </tr>

                            <tr>
                                <td colspan="1000"
                                    class="text-left">
                                    <h4 class="m-t-30">
                                        Labour
                                    </h4>
                                </td>
                            </tr>

                            <tr *ngFor="let actualLabourComponent of scheduleTask.actualLabourComponents"
                                class="line-height">
                                <td class="align-left">
                                    <p class="value">
                                        {{ actualLabourComponent.trade }}
                                    </p>
                                </td>
                                <td colspan="3">
                                </td>
                                <td>
                                    <div *ngIf="scheduleTask.isSchedulerSubcontract"
                                         class="flex-line">
                                        <input #subcontractCost="ngModel"
                                               [(ngModel)]="actualLabourComponent.subcontractCost"
                                               [name]="'actualMaterialSubcontractCost-' + actualLabourComponent.id"
                                               [id]="'actualMaterialSubcontractCost-' + actualLabourComponent.id"
                                               class="af-input text-right"
                                               autocomplete="off"
                                               placeholder="Subcontract cost"
                                               allowNegativeNumbers="true"
                                               mask="separator.2"
                                               thousandSeparator=","
                                               prefix="&pound;&nbsp;"
                                               type="text">
                                    </div>

                                    <div *ngIf="scheduleTask.isSchedulerProvisional"
                                         class="flex-line">
                                        <input #provisionalCost="ngModel"
                                               [(ngModel)]="actualLabourComponent.cost"
                                               (ngModelChange)="onSubComponentProvisionalCostChange()"
                                               [name]="'actualMaterialProvisionalCost-' + actualLabourComponent.id"
                                               [id]="'actualMaterialProvisionalCost-' + actualLabourComponent.id"
                                               class="af-input text-right"
                                               autocomplete="off"
                                               placeholder="Provisional cost"
                                               allowNegativeNumbers="true"
                                               mask="separator.2"
                                               thousandSeparator=","
                                               prefix="&pound;&nbsp;"
                                               type="text">
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>

                            <tr *ngIf="scheduleTask.actualLabourComponents.length === 0"
                                class="no-items text-center">
                                <td colspan="1000">
                                    No items
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <h4 class="m-t-15">
                            Details
                        </h4>

                        <table class="af-table align-right details">
                            <tr>
                                <th style="width: 20%;"
                                    class="align-left">
                                    Build up
                                </th>

                                <th style="width: 20%;"
                                    class="align-left">
                                    Component Area
                                </th>
                                <th style="width: 30%; text-align: center">
                                    Ratio
                                </th>
                                <th style="width: 15%; padding-right: 31pt;">
                                    Qty
                                </th>
                                <th style="width: 15%; padding-right: 10pt;">
                                    Unit
                                </th>
                            </tr>

                            <tr>
                                <td style="padding-right: 30px;"
                                    class="align-left">
                                    <bp-select-input
                                        #buildUpSelectInput
                                        (onSelectionChange)="onBuildUpSelectionChange($event)"
                                        *ngIf="buildUpSelectInputData"
                                        [data]="buildUpSelectInputData"
                                        class="search-build-up">
                                    </bp-select-input>
                                </td>

                                <td style="padding-right: 30px;"
                                    class="align-left">
                                    <bp-search-by-select
                                        (onSelectionChange)="onComponentAreaSelectionChange($event)"
                                        *ngIf="componentAreaSearchBySelectInputData"
                                        [data]="componentAreaSearchBySelectInputData"
                                        class="search-component-area">
                                    </bp-search-by-select>
                                </td>
                                <td style="text-align: center">
                                    <input #ratio="ngModel"
                                           (input)="onRatioChange()"
                                           [(ngModel)]="scheduleTask.ratio"
                                           [dropSpecialCharacters]="false"
                                           [mask]="'0*.00'"
                                           [ngClass]="{ 'error': (f.submitted || ratio.dirty || ratio.touched) && ratio.invalid }"
                                           class="af-input text-right width-limited"
                                           name="ratio"
                                           id="ratio"
                                           placeholder="Ratio"
                                           required
                                           type="text">
                                    <div *ngIf="(f.submitted || ratio.dirty) && ratio.invalid">
                                        <label *ngIf="ratio.errors.required"
                                               class="error">
                                            This field is required
                                        </label>
                                    </div>
                                </td>
                                <td style="padding-right: 30px; padding-left: 20px;">
                                    <input #unitValue="ngModel"
                                           (ngModelChange)="onQtiChange($event)"
                                           (blur)="updateTaskTotal()"
                                           [ngModel]="scheduleTask.ratio * scheduleTask.componentAreaUnitValue"
                                           [dropSpecialCharacters]="false"
                                           [mask]="'0*.00'"
                                           [ngClass]="{ 'error': (f.submitted || unitValue.dirty || unitValue.touched) && unitValue.invalid }"
                                           class="af-input text-right width-limited"
                                           name="unitValue"
                                           id="unitValue"
                                           placeholder="Unit value"
                                           required
                                           type="text">
                                    <div *ngIf="(f.submitted || unitValue.dirty) && unitValue.invalid">
                                        <label *ngIf="unitValue.errors.required"
                                               class="error">
                                            This field is required
                                        </label>
                                    </div>
                                </td>

                                <td style="padding-right: 10pt;">
                                    {{ scheduleTask.unit }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="header">
                <div class="row clearfix">
                    <div class="col-lg-6">
                        <label class="form-label">
                            Notes
                        </label>
                        <div class="form-line">
                            <input type="text"
                                   class="form-control"
                                   placeholder="Notes"
                                   [(ngModel)]="scheduleTask.note"
                                   name="note"
                                   id="note"
                                   #notes="ngModel">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label class="form-label">
                            Drawing ref
                        </label>
                        <div class="form-line">
                            <input type="text"
                                   class="form-control"
                                   placeholder="Drawing ref"
                                   [(ngModel)]="scheduleTask.drawingRef"
                                   name="drawingRef"
                                   id="drawingRef"
                                   #drawingRef="ngModel">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="header">
                <div class="m-t-20 row">
                    <div class="col-6 display-flex items-center">
                        <p class="font-weight-bold m-t-10 m-r-15">
                            Provisional
                        </p>
                        <label class="form-check-label">
                            <input [(ngModel)]="scheduleTask.isSchedulerProvisional"
                                   (change)="onIsSchedulerProvisionalChange()"
                                   [name]="'isScheduleProvisional-' + scheduleTask.id"
                                   [id]="'isScheduleProvisional-' + scheduleTask.id"
                                   class="af-input text-right"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                        </label>
                        <div class="provisional"
                             *ngIf="scheduleTask.isSchedulerProvisional">
                            <input #provisionalCost="ngModel"
                                   (ngModelChange)="provisionalCostWasUpdatedByUser = true;"
                                   (blur)="updateTaskTotal()"
                                   [(ngModel)]="scheduleTask.provisionalCost"
                                   name="provisionalCost"
                                   id="provisionalCost"
                                   class="af-input text-right"
                                   autocomplete="off"
                                   placeholder="Provisional cost"
                                   allowNegativeNumbers="true"
                                   mask="separator.2"
                                   thousandSeparator=","
                                   prefix="&pound;&nbsp;"
                                   type="text">
                        </div>
                    </div>
                    <div class="col-6 display-flex items-center">
                        <p class="font-weight-bold m-t-10 m-r-15">
                            Subcontract / Supplier
                        </p>
                        <label class="form-check-label">
                            <input [(ngModel)]="scheduleTask.isSchedulerSubcontract"
                                   (change)="onIsSchedulerSubcontractChange()"
                                   name="isScheduleSubcontract"
                                   id="isScheduleSubcontract"
                                   class="af-input text-right"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

