<div class="dashboard">
    <bp-dashboard-menu>
    </bp-dashboard-menu>

    <ng-container [ngSwitch]="dashboardStore.dashboardMode">
        <ng-container *ngSwitchCase="null">
            <div class="flex flex-column gap-30">
                <div class="flex flex-row flex-wrap gap-30">
                    <div class="w-d-1">
                        <bp-dashboard-tile [title]="'Project Cost'"
                                           [mode]="'pie-chart-view'">
                        </bp-dashboard-tile>
                    </div>

                    <div class="w-d-2 flex flex-column gap-30 d-none d-md-flex">
                        <bp-dashboard-tile [title]="'Benchmarks'"
                                           [mode]="'comparison-view'"
                                           [showRefreshMode]="!appState.isHomeownerView() && !dashboardStore.readOnly && dashboardStore.isUpdateAllToLatestVersionButtonEnabled"
                                           [refreshFunc]="onUpdateAllToLatestVersionClick.bind(this)"
                                           [refreshText]="''">
                        </bp-dashboard-tile>

                        <bp-dashboard-tile [title]="'Resource Cost Breakdown'"
                                           [mode]="'resource-cost-breakdown'"
                                           [showViewButton]="false">
                        </bp-dashboard-tile>
                    </div>
                </div>
                <div class="d-none d-md-block">
                    <bp-dashboard-tile [title]="'Timeline'"
                                       [mode]="'timeline-view'">
                    </bp-dashboard-tile>
                </div>
            </div>
        </ng-container>

        <div *ngSwitchCase="'pie-chart-view'"
             class="mode-container flex justify-content-center">
            <bp-dashboard-project-cost>
            </bp-dashboard-project-cost>
        </div>

        <div *ngSwitchCase="'timeline-view'"
             class="mode-container">
            <bp-dashboard-timeline>
            </bp-dashboard-timeline>
        </div>

        <div *ngSwitchCase="'comparison-view'"
             class="mode-container">
            <bp-dashboard-comparison>
            </bp-dashboard-comparison>
        </div>

        <div *ngSwitchCase="'resource-cost-breakdown'"
             class="mode-container">
            <bp-dashboard-resource-cost-breakdown>
            </bp-dashboard-resource-cost-breakdown>
        </div>
    </ng-container>
</div>
