import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'app/shared/constants/links.constants';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IRegistrationAccount, RegisterService } from 'app/shared/dataservices/register.service';
import { LoginService } from 'app/core/login/login.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhiteSpaceValidator } from 'app/shared/validators/no-white-space.validator';
import { MainBpBackgroundService } from 'app/shared/services/main-bp-background.service';
import { IStripeCheckoutSessionResponse, IStripePlan } from 'app/shared/model/stripe.model';
import { UUID } from 'angular2-uuid';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { IRegion } from 'app/shared/model/region.model';
import { GoogleApi } from 'app/shared/services/google.api';
import { Subscription } from 'rxjs';
import { GtagService } from 'app/shared/services/gtag.service';
import { IStripeCheckoutSession, SubscriptionApi } from "app/shared/dataservices/subscription.api";

type SignUpPlan = 'starter-plan' | 'standard-plan' | 'pro-plan';

const OTHER = 'Other';

@Component({
    selector: 'bp-sign-up-stripe',
    templateUrl: './sign-up-stripe.component.html',
    styleUrls: ['sign-up-stripe.scss']
})
export class BpSingUpStripeComponent implements OnInit, AfterViewInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    protected success: boolean;

    protected TERMS_OF_USE_URL = TERMS_OF_USE_URL;
    protected PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;

    protected teamInvitationToken: string;
    protected kwTracking?: string;

    protected stripePlans: IStripePlan[] | null = null;
    protected currentStripePlan: IStripePlan | null = null;
    protected plan: SignUpPlan | null = null;

    protected showPassword = false;

    protected form: FormGroup;

    protected routeQuerySub = Subscription.EMPTY;

    protected get planName(): string {
        switch (this.plan) {
            case 'starter-plan':
                return 'Starter Plan inc. VAT'
            case 'standard-plan':
                return 'Standard Plan inc. VAT';
            case 'pro-plan':
                return 'Pro Plan inc. VAT';
            default:
                return ''
        }
    }

    protected get price(): string {
        return this.currentStripePlan?.prices[0]?.cost.toFixed(2);
    }

    protected defaultRegion: IRegion | null = null;

    constructor(
        private registerService: RegisterService,
        private regionService: RegionApi,
        private elementRef: ElementRef,
        private alertService: BpAlertService,
        private router: Router,
        private invitationService: InvitationService,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private formBuilder: FormBuilder,
        private mainBpBackgroundService: MainBpBackgroundService,
        private subscriptionApi: SubscriptionApi,
        private googleApi: GoogleApi,
        private gtagService: GtagService
    ) {
    }

    ngOnInit(): void {
        this.mainBpBackgroundService.activate();

        this.showPassword = false;

        this.routeQuerySub = this.route.queryParams.subscribe(params => {
            this.teamInvitationToken = params['teamInvitationToken'];
            this.kwTracking = params['kw'];
            this.plan = params['plan'];

            if (this.plan != null) {
                this.subscriptionApi.queryPlans().subscribe((res: HttpResponse<IStripePlan[]>) => {
                    this.stripePlans = res.body;

                    this.currentStripePlan = this.stripePlans.find((sp) => {
                        switch (this.plan) {
                            case 'starter-plan':
                                return sp.name.toLowerCase() === 'Starter Plan inc. VAT'.toLowerCase();
                            case 'standard-plan':
                                return sp.name.toLowerCase() === 'Standard Plan inc. VAT'.toLowerCase();
                            case 'pro-plan':
                                return sp.name.toLowerCase() === 'Pro Plan inc. VAT'.toLowerCase();
                        }
                    })
                });
            }
        });

        this.form = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50), noWhiteSpaceValidator]),
        });

        this.regionService.query().subscribe((regionsRes) => {
            this.defaultRegion = regionsRes.body.find(r => r.region?.toLowerCase() === 'london') || regionsRes.body[0];
        });
    }

    ngOnDestroy(): void {
        this.routeQuerySub.unsubscribe();
        this.mainBpBackgroundService.deactivate();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#email').focus();
        }, 0);
    }

    protected register(): void {
        this.blockUI.start('Please wait..');

        const registerAccount: IRegistrationAccount = {};

        registerAccount.login = 'unnamed__' + UUID.UUID();
        registerAccount.email = this.form.controls.email.value;
        registerAccount.password = this.form.controls.password.value;
        registerAccount.langKey = 'en';
        registerAccount.authorities = ['ROLE_SCHEDULER'];
        registerAccount.heardAboutUs = OTHER;
        registerAccount.profession = {
            id: 6,
            name: OTHER
        };
        registerAccount.region = this.defaultRegion;

        if (this?.teamInvitationToken?.length) {
            registerAccount.activationKey = this?.teamInvitationToken;
        }

        if (this?.kwTracking?.length) {
            registerAccount.kwTracking = this.kwTracking;
        }

        this.registerService.save(registerAccount).pipe(
            finalize(() => {
                this.blockUI.stop();
            })).subscribe(
            (tokenRes: { id_token: string }) => {
                const onSuccess = () => {
                    this.success = true;
                    this.alertService.success('Registration saved! Confirmation has been sent to your email.', 3 * 1000);
                    setTimeout(() => {
                        this.loginService.loginWithToken(tokenRes.id_token).then(() => {
                            this.goToStripe();
                        })
                    }, 3 * 1000);
                }

                if (this?.teamInvitationToken?.length) {
                    this.invitationService.acceptTeamMember(this.teamInvitationToken).subscribe((res: HttpResponse<any>) => {
                        onSuccess();
                    });
                } else {
                    this.gtagService.sendConversionEvent();
                    onSuccess();
                }
            },
            error => {
                this.success = null;
                throw error;
            }
        );
    }

    protected login(): void {
        this.router.navigate(['/login']);
    }

    protected signInWithGoogle(): void {
        this.googleApi.authInWithGoogle(true);
    }

    private goToStripe(): void {
        this.blockUI.start('Please wait..');

        const goToHomePage = () => {
            this.router.navigate(['']);
            this.blockUI.stop();
        }

        if (this.currentStripePlan == null) {
            goToHomePage();
            return;
        }

        const stripeCheckoutSession: IStripeCheckoutSession = {
            priceId: this.currentStripePlan.prices[0].id,
            email: this.form.controls.email.value,
            trialDays: 14,
            quantity: 1
        }

        if (environment.stripe_promotion_code?.length) {
            stripeCheckoutSession.promotionCode = environment.stripe_promotion_code;
        }

        this.subscriptionApi.stripeCheckoutSession(stripeCheckoutSession)
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                }))
            .subscribe((res: HttpResponse<IStripeCheckoutSessionResponse>) => {
                window.location.href = res.body.url;
            })
    }
}
