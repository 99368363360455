import { Component, OnDestroy } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { Subscription, switchMap, take, tap, filter, finalize, distinctUntilChanged } from 'rxjs';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ApplicationStateService } from "app/core/application-state.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

/**
 * Dashboard
 */
@Component({
    selector: 'bp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['dashboard.scss']
})
@UntilDestroy()
export class DashboardComponent implements OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    private _filterStateSub = Subscription.EMPTY;

    constructor(
        protected appState: ApplicationStateService,
        protected dashboardStore: DashboardStore,
        protected dashboardService: DashboardService
    ) {
        this.appState.currentItem$
            .pipe(
                untilDestroyed(this),
                filter(item => item === "DASHBOARD")
            )
            .subscribe(() => {
                this.dashboardStore.dashboardMode = null;
            });

        this.appState.currentItem$
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(),
                filter(item => item === "DASHBOARD"),
                tap(() => {
                    this.dashboardStore.dashboardMode = null;
                    this.blockUI.start("Loading dashboard...");
                }),
                switchMap(() =>
                    this.dashboardService.checkInvitations().pipe(
                        take(1),
                        tap(() => this.dashboardService.setDefaults()),
                        switchMap(() => this.dashboardService.init().pipe(finalize(() => this.blockUI.stop()))))
                )
            )
            .subscribe(() => {
                if (this._filterStateSub) {
                    this._filterStateSub.unsubscribe();
                }
                this._filterStateSub = this.dashboardStore.filterState$.subscribe(() => {
                    this.dashboardService.updateItemsIds();
                    this.dashboardService.updateValueColumns();
                    this.dashboardService.updateResourceCost();
                });
            });
    }


    ngOnDestroy(): void {
        this.dashboardService.destroy();
        this._filterStateSub.unsubscribe();
    }

    protected onUpdateAllToLatestVersionClick(): void {
        this.blockUI.start('Please wait...');
        this.dashboardService.updateAllToLatestVersion().finally(() => {
            this.blockUI.stop();
        }).then(() => {
            window.location.reload();
        })
    }

}
