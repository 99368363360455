import { Component, OnInit } from '@angular/core';
import { CostPlanService } from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IComparisonQoter } from 'app/shared/model/comparison.model';

@Component({
    selector: 'bp-display-quoters-selector',
    templateUrl: './display-quoters-selector.component.html',
    styleUrls: ['display-quoters-selector.scss']
})
export class DisplayQuotersSelectorComponent implements OnInit {
    MAX_SELECTED_COUNT = 4;

    constructor(public costPlanService: CostPlanService) {
    }

    ngOnInit(): void {
    }

    select(quoter: IComparisonQoter, event): void {
        if (this.costPlanService.selectedQuoters.length === this.MAX_SELECTED_COUNT && !quoter.selected) {
            event.preventDefault();
            return;
        }

        quoter.selected = !quoter.selected;
        this.costPlanService.update();
    }
}
