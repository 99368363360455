import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment from 'moment';
import * as _ from 'lodash';
import { DATE_FORMAT } from '../constants/input.constants';
import { map } from 'rxjs/operators';
import { ActivityResource, IProject, ProjectStatus } from '../model/project.model';
import { IStage } from 'app/shared/model/stage.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import { IQuoter } from 'app/shared/model/quoter.model';
import { IInvitation } from 'app/shared/model/invitation.model';
import { PaymentProvider } from 'app/shared/model/payment-provider.model';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import { IComparisonElement } from 'app/shared/model/comparison-element.model';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';
import { IBuildUp, ICssElement } from 'app/shared/model/bp.model';

export type UpdateQuoterToCurrentProjectVersionResponse = 'IN_PROGRESS' | 'UP_TO_DATE';

export interface ITemplateWithTag {
    templateId?: number;
    tagId?: number;
}

export interface IProjectAmount {
    amount: number;
    percentage: number;
    projectAmount: number;
}

type ProjectResponseType = HttpResponse<IProject>;
type ProjectArrayResponseType = HttpResponse<IProject[]>;
type ScheduleStageArrayResponseType = HttpResponse<IStage[]>;

export interface GetReportDataParams {
    isAreaGroup: boolean;
    includeMargin?: boolean;
    areaIds?: number[];
    stageIds?: number[];
    quoterIds?: number[];
}

export interface IQueryStagesResult {
    total: number;
    stages: IStage[];
}

@Injectable({ providedIn: 'root' })
export class ProjectApi {
    public resourceUrl = SERVER_API_URL + 'api/projects';

    constructor(private http: HttpClient) {
    }

    isRevertPossible(id: number): Observable<HttpResponse<boolean>> {
        return this.http.get<boolean>(`${this.resourceUrl}/${id}/is-revert`, { observe: 'response' });
    }

    updateStatus(id: number, status: ProjectStatus): Observable<HttpResponse<ProjectStatus>> {
        return this.http.post<ProjectStatus>(`${this.resourceUrl}/${id}/status?status=${status}`, null, { observe: 'response' });
    }

    createInvoice(projectId: number): Observable<HttpResponse<void>> {
        return this.http.put<void>(`${this.resourceUrl}/${projectId}/invoice`, null, { observe: 'response' });
    }

    createSampleProject(): Observable<ProjectResponseType> {
        const url = `${this.resourceUrl}/sample`;
        return this.http
            .post<IProject>(url, null, { observe: 'response' })
            .pipe(map((res: ProjectResponseType) => this.convertDateFromServer(res)));
    }

    copyProject(id: number): Observable<IProject> {
        return this.http.post<IProject>(`${this.resourceUrl}/${id}/copy`, { observe: 'response' });
    }

    deepCopyProject(id: number): Observable<IProject> {
        return this.http.post<IProject>(`${this.resourceUrl}/${id}/deep-copy`, { observe: 'response' });
    }

    createWithTemplates(project: IProject, files: File[], templates: ITemplateWithTag[]): Observable<ProjectResponseType> {
        const projectCopy = this.convertDateFromClient(project);
        delete projectCopy.specification.icon;

        const projectObject = {
            specId: project.specification.id,
            project: projectCopy,
            templates
        };

        const formData = new FormData();
        formData.append('project', JSON.stringify(projectObject));

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        return this.http
            .post<IProject>(`${this.resourceUrl}`, formData, { observe: 'response' })
            .pipe(map((res: ProjectResponseType) => this.convertDateFromServer(res)));
    }

    applySchedulerTemplates(id: number, templates: ITemplateWithTag[], specId: number): Observable<any> {
        return this.http.post<any>(
            `${this.resourceUrl}/${id}/apply-schedules`,
            {
                specId,
                templates
            },
            {
                observe: 'response'
            }
        );
    }

    update(project: IProject): Observable<ProjectResponseType> {
        const copy = this.convertDateFromClient(project);
        return this.http
            .put<IProject>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: ProjectResponseType) => this.convertDateFromServer(res)));
    }

    updateLatLong(projectId: number, latitude: number, longitude: number): Observable<ProjectResponseType> {
        return this.http.put<IProject>(
            `${this.resourceUrl}/${projectId}/lat-long`,
            {
                latitude,
                longitude
            },
            { observe: 'response' }
        );
    }

    projectExists(address: string): Observable<HttpResponse<Boolean>> {
        return this.http.get<Boolean>(`${this.resourceUrl}/exist?address=${address}`, { observe: 'response' });
    }

    find(id: number): Observable<ProjectResponseType> {
        return this.http
            .get<IProject>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: ProjectResponseType) => this.convertDateFromServer(res)));
    }

    getProjectAmount(id: number): Observable<HttpResponse<IProjectAmount>> {
        return this.http.get<IProjectAmount>(`${this.resourceUrl}/${id}/project-amount`, { observe: 'response' });
    }

    query(req?: any, rnd?: string): Observable<ProjectArrayResponseType> {
        if (rnd != null) {
            req.rnd = rnd;
        }
        const options = createRequestOption(req);

        return this.http
            .get<IProject[]>(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: ProjectArrayResponseType) => this.convertDateArrayFromServer(res)));
    }

    queryScheduleStages(projectId: number, areaIds?: number[], stageIds?: number[]): Observable<ScheduleStageArrayResponseType> {
        const query: any = {};

        if (areaIds != null && areaIds.length > 0) {
            query.areaIds = areaIds.join(',');
        }

        if (stageIds != null && stageIds.length > 0) {
            query.stageIds = stageIds.join(',');
        }

        const options = createRequestOption(query);

        return this.http.get<IStage[]>(`${this.resourceUrl}/${projectId}/stages`, {
            params: options,
            observe: 'response'
        });
    }

    queryAvailableScheduleAreas(projectId: number, req?: any): Observable<HttpResponse<IScheduleArea[]>> {
        const options = createRequestOption(req, { sort: 'area,asc' });

        return this.http.get<IScheduleArea[]>(`${this.resourceUrl}/${projectId}/schedule-areas`, {
            params: options,
            observe: 'response'
        });
    }

    queryCssElements(projectId: number, areaIds?: number[], cssElementIds?: number[]): Observable<HttpResponse<ICssElement[]>> {
        const query: any = {};

        if (areaIds != null && areaIds.length > 0) {
            query.areaIds = areaIds.join(',');
        }

        if (cssElementIds != null && cssElementIds.length > 0) {
            query.cssElementIds = cssElementIds.join(',');
        }

        const options = createRequestOption(query);

        return this.http.get<ICssElement[]>(`${this.resourceUrl}/${projectId}/css-elements`, {
            params: options,
            observe: 'response'
        });
    }

    queryBuildUps(projectId: number, areaIds?: number[], buildUpIds?: number[]): Observable<HttpResponse<IBuildUp[]>> {
        const query: any = {};

        if (areaIds != null && areaIds.length > 0) {
            query.areaIds = areaIds.join(',');
        }

        if (buildUpIds != null && buildUpIds.length > 0) {
            query.buildUpIds = buildUpIds.join(',');
        }

        const options = createRequestOption(query);

        return this.http.get<ICssElement[]>(`${this.resourceUrl}/${projectId}/build-ups`, {
            params: options,
            observe: 'response'
        });
    }

    queryQuoters(projectId: number): Observable<HttpResponse<IComparisonQoter[]>> {
        return this.http.get<IComparisonQoter[]>(`${this.resourceUrl}/${projectId}/quoters`, {
            observe: 'response'
        });
    }

    refreshProjectTask(projectId: number): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/${projectId}/refresh`, { observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    queryComparison(
        projectId: number,
        quoterIds: number[],
        areaIds?: number[],
        stageIds?: number[]
    ): Observable<HttpResponse<IComparison>> {
        const query: any = { quoterIds: quoterIds.join(',') };
        if (areaIds && areaIds.length > 0) {
            query.areaIds = areaIds.join(',');
        }
        if (stageIds && stageIds.length > 0) {
            query.stageIds = stageIds.join(',');
        }
        const options = createRequestOption(query);

        return this.http.get<IComparison>(`${this.resourceUrl}/${projectId}/comparison`, {
            params: options,
            observe: 'response'
        });
    }

    queryQuoterView(projectId: number, areaId?: number, searchKey?: string): Observable<HttpResponse<IComparison>> {
        const query: any = {};
        if (areaId) {
            query.areaId = areaId;
        }
        if (searchKey) {
            query.searchKey = searchKey;
        }
        const options = createRequestOption(query);
        return this.http.get<IComparison>(`${this.resourceUrl}/${projectId}/quoter-view`, {
            params: options,
            observe: 'response'
        });
    }

    queryQuoterViewHistory(projectId: number, areaId: number, searchKey?: string): Observable<HttpResponse<IComparison>> {
        const query: any = {};
        if (areaId) {
            query.areaId = areaId;
        }
        if (searchKey) {
            query.searchKey = searchKey;
        }
        const options = createRequestOption(query);
        return this.http
            .get<IComparison>(`${this.resourceUrl}/${projectId}/quoter-view-history`, {
                params: options,
                observe: 'response'
            })
            .pipe(
                map((res: HttpResponse<IComparison>) => {
                    /* BP-1131 - currently we calculate totals on frontend side as it required too many work on backend side */
                    res.body.total = 0;
                    _.each(res.body.stageDTOs, (stageDTO: IComparisonStage) => {
                        stageDTO.totals[0] = 0;
                        _.each(stageDTO.elementDTOs, (elementDTO: IComparisonElement) => {
                            elementDTO.totals[0] = 0;

                            _.each(elementDTO.taskDTOs, (taskDTO: IComparisonScheduleTask) => {
                                let delta = taskDTO.historyStatus === 'REMOVED' ? -taskDTO.totals[0] : +taskDTO.totals[0];

                                // super hack
                                if (taskDTO.historyStatus === 'MODIFIED') {
                                    delta = delta - taskDTO.previous.totals[0];
                                }

                                elementDTO.totals[0] += delta;
                                stageDTO.totals[0] += delta;
                                res.body.total += delta;
                            });
                        });
                    });

                    return res;
                })
            );
    }

    submitQuoterView(projectId: number, provider: PaymentProvider): Observable<any> {
        let url = `${this.resourceUrl}/${projectId}/submit-quote`;

        if (provider != null) {
            url = `${url}/${provider}`;
        }
        return this.http.post<any>(url, null, {
            observe: 'response'
        });
    }

    queryDefaultQuoters(projectId?: number): Observable<HttpResponse<IQuoter[]>> {
        return this.http.get<IQuoter[]>(`${this.resourceUrl}/default-quoter${projectId != null ? '?id=' + projectId : ''}`, {
            observe: 'response'
        });
    }

    updateDefaultQuoter(projectId: number, defaultQuoterId: number): Observable<HttpResponse<void>> {
        const body: { id?: number } = {};

        if (defaultQuoterId != null) {
            body.id = defaultQuoterId;
        }

        return this.http.post<void>(`${this.resourceUrl}/${projectId}/default-quoter`, body, {
            observe: 'response'
        });
    }

    reSubmitQuoter(projectId: number, quoterId: number): Observable<HttpResponse<void>> {
        return this.http.post<void>(
            `${this.resourceUrl}/${projectId}/quoter/${quoterId}/re-submit`,
            {},
            {
                observe: 'response'
            }
        );
    }

    updateQuoterToCurrentProjectVersion(
        projectId: number,
        quoterId: number
    ): Observable<HttpResponse<UpdateQuoterToCurrentProjectVersionResponse>> {
        return this.http.post<UpdateQuoterToCurrentProjectVersionResponse>(
            `${this.resourceUrl}/${projectId}/quoter/${quoterId}/apply`,
            {},
            {
                observe: 'response'
            }
        );
    }

    getQuoterToCurrentProjectVersionUpdateStatus(projectId: number, quoterId: number): Observable<HttpResponse<IInvitation>> {
        return this.http.get<IInvitation>(`${this.resourceUrl}/${projectId}/quoter/${quoterId}/check`, {
            observe: 'response'
        });
    }

    getTotalForProjectByQuoter(projectId: number, quoterId: number): Observable<HttpResponse<number>> {
        return this.http.get<number>(`${this.resourceUrl}/${projectId}/quoter/${quoterId}/total`, {
            observe: 'response'
        });
    }

    defaultProject(): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.resourceUrl}/default-project`, {
            observe: 'response'
        });
    }

    queryStagesForTheProject(
        projectId: number,
        areaIds?: number[],
        stageIds?: number[],
        elementId?: number,
        defaultQuoterId?: number,
        searchKey?: string
    ): Observable<HttpResponse<IQueryStagesResult>> {
        const query: any = {};

        if (areaIds) {
            query.areaIds = areaIds;
        }
        if (stageIds) {
            query.stageIds = stageIds;
        }
        if (elementId) {
            query.elementId = elementId;
        }
        if (defaultQuoterId) {
            query.defaultQuoterId = defaultQuoterId;
        }

        if (searchKey) {
            query.searchKey = searchKey;
        }

        const options = createRequestOption(query);
        return this.http.get<IQueryStagesResult>(`${this.resourceUrl}/${projectId}/scheduler-tasks`, {
            params: options,
            observe: 'response'
        });
    }

    getExcelData(projectId: number, query: GetReportDataParams): Observable<string> {
        const options = {};
        options['observe'] = 'response';
        options['responseType'] = 'blob';

        const httpParams: any = {};

        if (query.areaIds && query.areaIds.length > 0) {
            httpParams.areaIds = query.areaIds.join(',');
        }

        if (query.stageIds && query.stageIds.length > 0) {
            httpParams.stageIds = query.stageIds.join(',');
        }

        if (query.quoterIds && query.quoterIds.length > 0) {
            httpParams.quoterIds = query.quoterIds.join(',');
        }

        httpParams.isAreaGroup = query.isAreaGroup;

        if (query.includeMargin != null) {
            httpParams.includeMargin = query.includeMargin;
        }

        options['params'] = httpParams;

        return this.http.get<any>(`${this.resourceUrl}/${projectId}/excel`, options).pipe(
            map((res: HttpResponse<string>) => {
                return res.body;
            })
        );
    }

    getDocxData(projectId: number, query: GetReportDataParams): Observable<string> {
        const options = {};
        options['observe'] = 'response';
        options['responseType'] = 'blob';

        const httpParams: any = {};

        if (query.areaIds && query.areaIds.length > 0) {
            httpParams.areaIds = query.areaIds.join(',');
        }

        if (query.stageIds && query.stageIds.length > 0) {
            httpParams.stageIds = query.stageIds.join(',');
        }

        if (query.quoterIds && query.quoterIds.length > 0) {
            httpParams.quoterIds = query.quoterIds.join(',');
        }

        httpParams.isAreaGroup = query.isAreaGroup;

        if (query.includeMargin != null) {
            httpParams.includeMargin = query.includeMargin;
        }

        options['params'] = httpParams;

        return this.http.get<any>(`${this.resourceUrl}/${projectId}/word`, options).pipe(
            map((res: HttpResponse<string>) => {
                return res.body;
            })
        );
    }

    getPDFData(projectId: number, query: GetReportDataParams): Observable<string> {
        const options = {};
        options['observe'] = 'response';
        options['responseType'] = 'blob';

        const httpParams: any = {};

        if (query.areaIds && query.areaIds.length > 0) {
            httpParams.areaIds = query.areaIds.join(',');
        }

        if (query.stageIds && query.stageIds.length > 0) {
            httpParams.stageIds = query.stageIds.join(',');
        }

        if (query.quoterIds && query.quoterIds.length > 0) {
            httpParams.quoterIds = query.quoterIds.join(',');
        }

        httpParams.isAreaGroup = query.isAreaGroup;

        if (query.includeMargin != null) {
            httpParams.includeMargin = query.includeMargin;
        }

        options['params'] = httpParams;

        return this.http.get<any>(`${this.resourceUrl}/${projectId}/pdf`, options).pipe(
            map((res: HttpResponse<string>) => {
                return res.body;
            })
        );
    }

    getTempLink(projectId?: number): Observable<string> {
        if (projectId != null) {
            return this.http.post(`${this.resourceUrl}/${projectId}/temp-link`, null, { responseType: 'text' });
        }
        return this.http.post(`${this.resourceUrl}/temp-link`, null, { responseType: 'text' });
    }

    setActivity(projectId: number, resource: ActivityResource) : Observable<string> {
        return this.http.post(`${this.resourceUrl}/${projectId}/activity/${resource}`, null, { responseType: 'text' });
    }

    private convertDateFromClient(project: IProject): IProject {
        const copy: IProject = Object.assign({}, project, {
            createdDate: project.createdDate != null && project.createdDate.isValid() ? project.createdDate.format(DATE_FORMAT) : null,
            startDate: project.startDate != null && project.startDate.isValid() ? project.startDate.format(DATE_FORMAT) : null,
            endDate: project.endDate != null && project.endDate.isValid() ? project.endDate.format(DATE_FORMAT) : null,
            tenderDecision:
                project.tenderDecision != null && project.tenderDecision.isValid() ? project.tenderDecision.format(DATE_FORMAT) : null,
            tenderDeadline:
                project.tenderDeadline != null && project.tenderDeadline.isValid() ? project.tenderDeadline.format(DATE_FORMAT) : null
        });
        return copy;
    }

    private convertDateFromServer(res: ProjectResponseType): ProjectResponseType {
        if (res.body) {
            res.body.createdDate = res.body.createdDate != null ? moment(res.body.createdDate) : null;
            res.body.startDate = res.body.startDate != null ? moment(res.body.startDate) : null;
            res.body.endDate = res.body.endDate != null ? moment(res.body.endDate) : null;
            res.body.tenderDecision = res.body.tenderDecision != null ? moment(res.body.tenderDecision) : null;
            res.body.tenderDeadline = res.body.tenderDeadline != null ? moment(res.body.tenderDeadline) : null;
        }
        return res;
    }

    private convertDateArrayFromServer(res: ProjectArrayResponseType): ProjectArrayResponseType {
        if (res.body) {
            res.body.forEach((project: IProject) => {
                project.createdDate = project.createdDate != null ? moment(project.createdDate) : null;
                project.startDate = project.startDate != null ? moment(project.startDate) : null;
                project.endDate = project.endDate != null ? moment(project.endDate) : null;
                project.tenderDecision = project.tenderDecision != null ? moment(project.tenderDecision) : null;
                project.tenderDeadline = project.tenderDeadline != null ? moment(project.tenderDeadline) : null;
            });
        }
        return res;
    }
}
