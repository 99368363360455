import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TemplateWizardComponent } from 'app/flows/scheduler/template-wizard/template-wizard.component';
import { TEMPLATE_WIZARD_ROUTE } from 'app/flows/scheduler/template-wizard/template-wizard.route';
import { Step1Component } from 'app/flows/scheduler/template-wizard/steps/step1/step1.component';
import { Step2Component } from 'app/flows/scheduler/template-wizard/steps/step2/step2.component';
import { Step3Component } from 'app/flows/scheduler/template-wizard/steps/step3/step3.component';
import {
    ScheduleTemplateCardComponent
} from 'app/flows/scheduler/template-wizard/components/schedule-template-card/schedule-template-card.component';
import {
    TemplateListComponent
} from 'app/flows/scheduler/template-wizard/components/template-list/template-list.component';
import {
    RequestTemplateModalComponent
} from 'app/flows/scheduler/template-wizard/components/request-template-modal/request-template-modal.component';
import {
    RequestNewCardComponent
} from 'app/flows/scheduler/template-wizard/components/request-new-card/request-new-card.component';
import { BpFrontendSharedModule } from 'app/shared/shared.module';

@NgModule({
    imports: [BpFrontendSharedModule, RouterModule.forRoot([TEMPLATE_WIZARD_ROUTE])],
    declarations: [
        TemplateWizardComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        TemplateListComponent,
        ScheduleTemplateCardComponent,
        RequestTemplateModalComponent,
        RequestNewCardComponent
    ],
    exports: [TemplateWizardComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpTemplateWizardModule {}
