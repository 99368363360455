import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserReportModalComponent } from 'app/account/user-report-modal/user-report-modal.component';
import { ReportType } from 'app/shared/dataservices/user-report.api';
import { Role } from "app/shared/model/common.model";

@Injectable({ providedIn: 'root' })
export class UserReportModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(type: ReportType, role?: Role): NgbModalRef {
        this.ngbModalRef = this.modalService.open(UserReportModalComponent as Component, {
            windowClass: 'bp-modal user-report',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.type = type;
        this.ngbModalRef.componentInstance.role = role;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
