<div class="settings-container"
     *ngIf="account && form">
    <form [formGroup]="form"
          name="settings-form"
          novalidate
          role="form">
        <div class="controls-container w-full justify-content-end flex flex-row gap-15">
            <button (click)="copyCostPlanLinkToBuffer()"
                    *ngIf="!appState.isHomeownerView()"
                    class="d-none d-md-inline btn btn-secondary btn-border-radius"
                    ngbTooltip="Cost plan link">
                Cost plan link
            </button>

            <button (click)="copyCostPlanLinkToBuffer()"
                    *ngIf="!appState.isHomeownerView()"
                    class="d-md-none btn btn-secondary btn-circle"
                    ngbTooltip="Cost plan link">
                <i class="material-icons md-24">
                    share
                </i>
            </button>
            <button class="btn btn-border-radius btn-primary"
                    type="button"
                    (click)="save()">
                Save & Exit
            </button>
        </div>

        <div class="flex justify-content-between flex-wrap gap-40">
            <div class="settings-container__image-container">
                <div>
                    <div #logo
                         class="img-preview m-b-20">
                    </div>

                    <bp-image-upload (onUploaded)="onImageUploaded($event)">
                    </bp-image-upload>

                    <div class="m-t-15">
                        <div class="form-check clear w-full">
                            <label class="form-check-label">
                                White label
                                <input formControlName="replaceBPLogo"
                                       class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-sign"><span class="check"></span></span>
                            </label>
                        </div>

                        <div *ngIf="form.controls.replaceBPLogo.value"
                             class="m-t-20">
                            <div>Primary color</div>
                            <input [(colorPicker)]="themeSettingsService.primaryColorSetting.value"
                                   [style.background]="themeSettingsService.primaryColorSetting.value"
                                   (colorPickerChange)="themeSettingsService.primaryColorSetting.value = $event"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-container__user-settings">
                <h1>
                    User Settings
                </h1>

                <div class="flex flex-between-container flex-wrap gap-40 m-t-30">
                    <div class="flex-1 min-w-300">
                        <div class="form-group">
                            <label class="form-control-label"
                                   for="firstName">
                                First Name
                            </label>
                            <input formControlName="firstName"
                                   class="af-input"
                                   id="firstName"
                                   name="firstName"
                                   type="text">
                            <div *ngIf="form.controls.firstName.touched && form.controls.firstName.invalid">
                                <small *ngIf="form.controls.firstName.errors.required"
                                       class="form-text text-danger">
                                    Your first name is required.
                                </small>
                                <small *ngIf="form.controls.firstName.errors.minlength"
                                       class="form-text text-danger">
                                    Your first name is required to be at least 1 character.
                                </small>
                                <small *ngIf="form.controls.firstName.errors.maxlength"
                                       class="form-text text-danger">
                                    Your first name cannot be longer than 50 characters.
                                </small>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label"
                                   for="email">
                                Email
                            </label>
                            <input formControlName="email"
                                   class="af-input"
                                   email
                                   id="email"
                                   name="email"
                                   type="email">
                            <div *ngIf="form.controls.email.touched && form.controls.email.invalid">
                                <small *ngIf="form.controls.email.errors.required"
                                       class="form-text text-danger">
                                    Your email is required.
                                </small>
                                <small *ngIf="form.controls.email.errors.email"
                                       class="form-text text-danger">
                                    Your email is invalid.
                                </small>
                                <small *ngIf="form.controls.email.errors.minlength"
                                       class="form-text text-danger">
                                    Your email is required to be at least 5 characters.
                                </small>
                                <small *ngIf="form.controls.email.errors.maxlength"
                                       class="form-text text-danger">
                                    Your email cannot be longer than 100 characters.
                                </small>
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly() || isSchedulerOnly()">
                            <label class="form-control-label"
                                   for="company-name">
                                Company Name
                            </label>
                            <input formControlName="company"
                                   class="af-input"
                                   id="company-name"
                                   name="company-name"
                                   type="text">
                        </div>

                        <div class="form-group">
                            <label class="form-control-label"
                                   for="company-address">
                                Company Address
                            </label>
                            <input formControlName="companyAddress"
                                   class="af-input"
                                   id="company-address"
                                   name="company-address"
                                   type="text">
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly()">
                            <label class="form-control-label"
                                   for="incorporationDate">
                                Incorporation Date
                            </label>
                            <div>
                                <bp-date-picker
                                    id="incorporationDate"
                                    [date]="incorporationDate"
                                    (onDateChanged)="onIncorporationDateSelect($event)">
                                </bp-date-picker>
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly()">
                            <label class="form-control-label"
                                   for="insuranceAmount">
                                Insurance amount
                            </label>
                            <div class="flex-line">
                                <input formControlName="insuranceAmount"
                                       id="insuranceAmount"
                                       name="insuranceAmount"
                                       mask="separator.2"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly()">
                            <label class="form-control-label">
                                Payment method
                            </label>
                            <div>
                                <bp-select-payment-provider
                                    [account]="account">
                                </bp-select-payment-provider>
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isSchedulerOnly()">
                            <label class="form-control-label">
                                Payment method
                            </label>
                            <div>
                                {{ schedulerPaymentMethods() }}
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isSchedulerOnly()">
                            <label class="form-control-label"
                                   for="vatRate">
                                Vat Rate
                            </label>
                            <div class="flex gap-10 align-items-center" style="width: 200px">
                                <input formControlName="vatRate"
                                       id="vatRate"
                                       name="vatRate"
                                       mask="separator.2"
                                       thousandSeparator=","
                                       type="text">
                                <span>%</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex-1 min-w-300">
                        <div class="form-group">
                            <label class="form-control-label"
                                   for="lastName">
                                Last Name
                            </label>
                            <input formControlName="lastName"
                                   class="af-input"
                                   id="lastName"
                                   name="lastName"
                                   type="text">
                            <div *ngIf="form.controls.lastName.touched && form.controls.lastName.invalid">
                                <small *ngIf="form.controls.lastName.errors.required"
                                       class="form-text text-danger">
                                    Your last name is required.
                                </small>
                                <small *ngIf="form.controls.lastName.errors.minlength"
                                       class="form-text text-danger">
                                    Your last name is required to be at least 1 character.
                                </small>
                                <small *ngIf="form.controls.lastName.errors.maxlength"
                                       class="form-text text-danger">
                                    Your last name cannot be longer than 50 characters.
                                </small>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label"
                                   for="phone-number">
                                Phone Number
                            </label>
                            <input formControlName="phoneNumber"
                                   class="af-input"
                                   id="phone-number"
                                   name="phone-number"
                                   type="number">
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly()">
                            <label class="form-control-label"
                                   for="website">
                                Website
                            </label>
                            <input formControlName="website"
                                   class="af-input"
                                   id="website"
                                   name="website"
                                   type="text">
                        </div>

                        <div class="form-group">
                            <label class="form-control-label"
                                   for="company-postcode">
                                Company Postcode
                            </label>
                            <input formControlName="companyPostcode"
                                   class="af-input"
                                   id="company-postcode"
                                   name="company-postcode"
                                   type="text">
                        </div>

                        <div class="form-group">
                            <label class="form-control-label"
                                   for="company-number">
                                Company Registration Number
                            </label>
                            <input formControlName="companyNumber"
                                   class="af-input"
                                   id="company-number"
                                   name="company-number"
                                   type="number">
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly()">
                            <label class="form-control-label"
                                   for="field_region">
                                Region
                            </label>
                            <select
                                formControlName="region"
                                class="form-control"
                                id="field_region"
                                name="region">
                                <option *ngFor="let regionOption of regions"
                                        [ngValue]="regionOption.id === account.region?.id ? account.region : regionOption">
                                    {{ regionOption.region }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group"
                             *ngIf="isQuoterOnly()">
                            <label class="form-control-label"
                                   for="insuranceExpiry">
                                Insurance Expiry
                            </label>
                            <div>
                                <bp-date-picker
                                    id="insuranceExpiry"
                                    [date]="insuranceExpiryDate"
                                    (onDateChanged)="onInsuranceExpiryDateSelect($event)">
                                </bp-date-picker>
                            </div>
                        </div>

                        <div class="form-group"
                             *ngIf="isSchedulerOnly()">
                            <div *ngIf="account.hasPaymentProvider; else noPaymentProvider"
                                 class="flex flex-row gap-20 align-items-center">
                                <ng-container [ngSwitch]="account.paymentProviderStatus">
                                    <ng-container *ngSwitchCase="'ACTIVE'">
                                        <button (click)="unsubscribe()"
                                                class="btn btn-secondary btn-border-radius-5 waves-effect settings-container__unsubscribe"
                                                type="button"
                                                ngbTooltip="Unsubscribe">
                                            Unsubscribe
                                        </button>
                                        <div>
                                            OR
                                        </div>
                                        <button (click)="pause()"
                                                class="btn btn-secondary btn-border-radius-5 waves-effect settings-container__pause"
                                                type="button"
                                                ngbTooltip="Pause">
                                            Pause
                                        </button>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'COLLECTION_PAUSED'">
                                        <div>
                                            SUBSCRIPTION PAUSED
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>

                            <ng-template #noPaymentProvider>
                                <button (click)="onSubscribeClick()"
                                        class="btn btn-primary btn-border-radius-5 waves-effect settings-container__subscribe"
                                        type="button"
                                        ngbTooltip="Subscribe">
                                    Subscribe
                                </button>
                            </ng-template>
                        </div>

                        <button (click)="clearStorage()"
                                *ngIf="accountService.isAdmin() || proxyAdmin"
                                type="button"
                                ngbTooltip="Remove all bp related data from local storage"
                                class="btn btn-secondary btn-border-radius-5 waves-effect">
                            Clear Storage
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

