<div [ngClass]="{'no-padding-vert': !scheduleService.showSubStages, 'm-b-30': scheduleService.showSubStages}"
     *ngIf="scheduleService.showSubStages || filteredScheduleTasks().length > 0">

    <!-- headings if showSubstages -->
    <div class="header-row flex flex-row align-items-center gap-20 justify-content-between"
         *ngIf="scheduleService.showSubStages">
        <div class="flex flex-row align-items-center gap-20">
            <div class="header-row__reference-number reference-number"
                 [title]="element?.referenceNumber">
                {{ element?.referenceNumber }}
            </div>

            <div class="bp-cursor-pointer"
                 (click)="toggleElementExpand()">
                {{ element?.element }}
                <i class="material-icons">{{ expanded ? "expand_more" : "chevron_right" }}</i>
            </div>
        </div>
        <div>
            {{ elementTotal | currency:'GBP' }}
        </div>
    </div>

    <div class="element-container"
         id="element-container_{{ element.id }}"
         *ngIf="expanded">
        <bp-schedule-task-for-filter-by-stage-grouping
            *ngFor="let scheduleTask of filteredScheduleTasks();"
            [element]="element"
            [scheduleTask]="scheduleTask"
            [stage]="stage">
        </bp-schedule-task-for-filter-by-stage-grouping>

        <bp-add-schedule-task-for-filter-by-stage-grouping
            *ngIf="!scheduleService.readOnly && scheduleService.showSubStages"
            [element]="element"
            [stage]="stage">
        </bp-add-schedule-task-for-filter-by-stage-grouping>
    </div>
</div>
