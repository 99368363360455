import { Injectable } from '@angular/core';
import { IThemeSetting } from "app/shared/model/bp.model";
import { lastValueFrom } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { UserApi } from "app/shared/dataservices/user.api";
import { AccountService } from "app/core/auth/account.service";

@Injectable({ providedIn: 'root' })
export class ThemeSettingsService {
    constructor(private accountService: AccountService,
                private userApi: UserApi) {
    }

    private _themeSettings: IThemeSetting[] = [];

    get themeSettings(): IThemeSetting[] {
        return this._themeSettings ?? [];
    }

    public get primaryColorSetting(): IThemeSetting {
        return this.themeSettings.find(themeSetting => themeSetting.property === "primaryColor");
    }

    async get(force?: boolean): Promise<IThemeSetting[]> {
        if (force) {
            this._themeSettings = [{
                userId: this.accountService.getAccountId(),
                property: 'primaryColor',
                value: '#0086aa'
            }];
        }

        if (this._themeSettings) {
            return Promise.resolve(this._themeSettings);
        }

        const res = await lastValueFrom(this.userApi.getThemeSettings().pipe(debounceTime(700)));
        this._themeSettings = res.body;

        if (!this.primaryColorSetting) {
            this._themeSettings.push({
                userId: this.accountService.getAccountId(),
                property: 'primaryColor',
                value: '#0086aa'
            })
        }
        if (this.accountService.isReplaceBPLogo()) {
            this.updatePrimaryColor(this.primaryColorSetting.value);
        } else {
            this.updatePrimaryColor('#0086aa');
        }
        return this._themeSettings;
    }

    async update(): Promise<IThemeSetting[]> {
        return lastValueFrom(this.userApi.updateThemeSettings(this.themeSettings).pipe(map(() => this.get(true))));
    }

    private updatePrimaryColor(color: string): void {
        document.documentElement.style.setProperty('--primary', color);
    }
}
