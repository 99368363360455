import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { map } from "rxjs/operators";
import { IStripeCheckoutSessionResponse, IStripePlan, IStripeUpgradePlan } from "app/shared/model/stripe.model";
import { StripeUpgradeType } from "app/shared/model/bp.model";

export interface IStripeCheckoutSession {
    priceId: string,
    email: string;
    trialDays: number, //set 0 if you dont wanna add trial
    quantity: 1, // quantity of subscription
    promotionCode?: string // ex: promo_1MlCkJIpV58FRykGCjezyLie, promocode, if this is added user will not have option to input manually
}

@Injectable({ providedIn: 'root' })
export class SubscriptionApi {
    public resourceUrl = SERVER_API_URL + 'api/subscription';

    constructor(private http: HttpClient) {
    }

    pause(userId: number, months: number): Observable<HttpResponse<void>> {
        return this.http.put<void>(`${this.resourceUrl}/pause`, { userId, months }, { observe: 'response' });
    }

    levels(): Observable<string[]> {
        return this.http.get<{ subscriptionLevels: string[] }>(`${this.resourceUrl}/levels`)
            .pipe(
                map((res) => res.subscriptionLevels)
            );
    }

    queryPlans(): Observable<HttpResponse<IStripePlan[]>> {
        return this.http.get<IStripePlan[]>(`${this.resourceUrl}/stripe/plans`, { observe: 'response' });
    }

    queryUpgradePlans(): Observable<HttpResponse<IStripeUpgradePlan[]>> {
        return this.http.get<IStripeUpgradePlan[]>(
            `${this.resourceUrl}/stripe/upgrade_plans`, { observe: 'response' });
    }

    stripeCheckoutSession(stripeCheckoutSession: IStripeCheckoutSession): Observable<HttpResponse<IStripeCheckoutSessionResponse>> {
        return this.http.post<HttpResponse<IStripeCheckoutSessionResponse>>(
            `${this.resourceUrl}/stripe/checkout_session`
            , stripeCheckoutSession, { observe: 'response' });
    }

    // To upgrade plan
    upgrade(userId: number, upgradeType: StripeUpgradeType): Observable<HttpResponse<{ message: string }>> {
        return this.http.put<{ message: string }>(
            `${this.resourceUrl}/stripe/plan`, { userId, upgradeType }, { observe: 'response' });
    }
}
