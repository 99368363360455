import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { HttpResponse } from '@angular/common/http';
import { IProfession } from 'app/shared/model/profession.model';
import { HEARD_ABOUT_US_PREDEFINED_VALUES } from 'app/shared/constants/heard-about-us.constants';
import { ProfessionService } from 'app/shared/dataservices/profession.service';
import { AccountService } from 'app/core/auth/account.service';
import { IAccount } from 'app/shared/model/account.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserApi } from 'app/shared/dataservices/user.api';
import { lastValueFrom } from 'rxjs';
import { IRegion } from 'app/shared/model/region.model';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { AuthServerProvider } from "app/core/auth/auth-jwt.service";

interface AfterSignupWizardStepField {
    code: string,
    required: boolean,
    placeholder: string,
    pattern?: string,
    validationMessage?: string;
    type: 'select' | 'text',
    selectInputData?: SelectInputData
    value?: any;
    additionalValue?: {
        code: string,
        showWhen:
            {
                code: string;
                required: boolean;
                selectValue: string,
                placeholder: string,
                value?: string;
            }[];
    },
}

interface AfterSignupWizardStep {
    fields: AfterSignupWizardStepField[]
}

@Component({
    selector: 'after-signup-wizard-modal',
    templateUrl: './after-signup-wizard-modal.component.html',
    styleUrls: ['after-signup-wizard-modal.scss']
})
export class AfterSignupWizardModalComponent {
    @BlockUI() blockUI: NgBlockUI;

    steps: AfterSignupWizardStep[] = [
        {
            fields: [
                {
                    code: 'profession',
                    required: true,
                    placeholder: `What's your profession?`,
                    type: 'select'
                },
                {
                    code: 'heardAboutUs',
                    required: true,
                    placeholder: 'How did you hear about us?',
                    type: 'select',
                    additionalValue: {
                        code: 'heardAboutUsComment',
                        showWhen: [
                            {
                                code: 'heardAboutUsComment',
                                required: true,
                                selectValue: 'other',
                                placeholder: 'Where did you hear about us?',
                            },
                            {
                                code: 'heardAboutUsComment',
                                required: false,
                                selectValue: 'referral',
                                placeholder: 'Who referred you?'
                            }
                        ]
                    },

                },
                {
                    code: 'region',
                    required: true,
                    placeholder: `Where are you based?`,
                    type: 'select'
                },
            ]
        },
        {
            fields: [
                {
                    code: 'company',
                    required: false,
                    placeholder: 'Company Name',
                    type: 'text'
                },
                {
                    code: 'phoneNumber',
                    required: true,
                    placeholder: 'Phone number',
                    type: 'text',
                    pattern: '[0-9\\s\\+\\(\\)]{9,}',
                    validationMessage: 'Must contain numbers, +, brackets, spaces, only and have a length of at least 9'
                }
            ]
        },
    ]

    currentStepId = 0;

    get isCurrentStepLast(): boolean {
        return this.currentStepId === this.steps.length - 1;
    }

    constructor(
        public activeModal: NgbActiveModal,
        private professionService: ProfessionService,
        private accountService: AccountService,
        private userApi: UserApi,
        private regionApi: RegionApi,
        private authServerProvider: AuthServerProvider
    ) {
        this.fillProfessionSelectInputData();
        this.fillHeardAboutUsSelectInputData();
        this.fillRegionsSelectInputData();
    }

    onOkClick(): void {
        const goToNext = () => {
            this.currentStepId++;
        }
        this.blockUI.start();

        this.accountService.identity().then((account: IAccount) => {
            this.steps[this.currentStepId].fields.forEach((field) => {
                if (field.value) {
                    account[field.code] = field.value;
                }
                if (field.additionalValue?.code && field.additionalValue?.showWhen?.length) {
                    field.additionalValue?.showWhen.forEach((rule) => {
                        if (field.value?.toLowerCase() === rule.selectValue?.toLowerCase() && rule.value?.length) {
                            account[rule.code] = rule.value;
                        }
                    })
                }
            })

            lastValueFrom(this.accountService.save(account)).finally(() => {
                lastValueFrom(this.userApi.activity('UPDATED_ADDITIONAL_INFO')).finally(() => {
                    this.accountService.identity(true).finally(() => {
                        if (this.isCurrentStepLast) {
                            this.activeModal.close(true);
                        } else {
                            goToNext();
                        }

                        this.blockUI.stop();
                    })
                });
            }).then((accountResponse) => {
                this.authServerProvider.storeAdminProxy(accountResponse.body.proxyAdmin);
                this.authServerProvider.storeAuthenticationToken(accountResponse.body.id_token);
            })
        })
    }

    onSelectionChange(field: AfterSignupWizardStepField, event: any): void {
        field.value = ['profession', 'region'].includes(field.code) ? event : event.value;
    }

    isStepValid(stepId: number): boolean {
        let valid = true;
        this.steps[stepId].fields.forEach((field) => {
            if (field.required && !field.value) {
                valid = false;
            }

            if (field.pattern != null && !(new RegExp(field.pattern)).test(field.value)) {
                valid = false;
            }

            if (field.additionalValue?.code && field.additionalValue?.showWhen?.length) {
                field.additionalValue?.showWhen.forEach((rule) => {
                    if (field.value?.toLowerCase() === rule.selectValue?.toLowerCase()) {
                        if (rule.required && !rule.value) {
                            valid = false;
                        }
                    }
                })
            }
        })
        return valid;
    }

    private fillProfessionSelectInputData(): void {
        this.professionService.query().subscribe((res: HttpResponse<IProfession[]>) => {
            this.steps[0].fields.find(f => f.code === 'profession').selectInputData = {
                data: res.body,
                indexProperty: 'id',
                titleProperty: 'name',
                dropdownPosition: 'auto',
                searchable: false
            };
        });
    }

    private fillHeardAboutUsSelectInputData(): void {
        this.steps[0].fields.find(f => f.code === 'heardAboutUs').selectInputData = {
            data: HEARD_ABOUT_US_PREDEFINED_VALUES,
            indexProperty: 'id',
            titleProperty: 'value',
            dropdownPosition: 'auto',
            searchable: false
        };
    }

    private fillRegionsSelectInputData(): void {
        this.regionApi.query().subscribe((res: HttpResponse<IRegion[]>) => {
            this.steps[0].fields.find(f => f.code === 'region').selectInputData = {
                data: res.body,
                indexProperty: 'id',
                titleProperty: 'region',
                dropdownPosition: 'auto',
                searchable: false
            };
        });
    }
}
