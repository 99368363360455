import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

@Directive({
    selector: '[containerLoading]'
})
export class LoadingDirective implements OnInit, OnChanges {
    @HostBinding('style.position')
    hostPosition: string = 'relative';

    @Input() containerLoading: boolean = false;

    uid: string;

    constructor(private targetEl: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.uid = 'loading-container-' + uuidv4();

        const loadingContainer = this.renderer.createElement('div');
        this.renderer.setStyle(loadingContainer, 'display', this.containerLoading ? 'flex' : 'none');
        this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
        this.renderer.setStyle(loadingContainer, 'align-items', 'center');
        this.renderer.addClass(loadingContainer, this.uid);
        this.renderer.setStyle(loadingContainer, 'position', 'absolute');
        this.renderer.setStyle(loadingContainer, 'top', '0');
        this.renderer.setStyle(loadingContainer, 'background', 'rgba(0, 0, 63, 0.2)');
        this.renderer.setStyle(loadingContainer, 'width', '100%');
        this.renderer.setStyle(loadingContainer, 'height', '100%');

        // custom spinner -- start
        const spinnerContainer = this.renderer.createElement('div');
        const spinnerInnerDiv1 = this.renderer.createElement('div');
        this.renderer.addClass(spinnerInnerDiv1, 'loader');

        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv1);

        this.renderer.appendChild(loadingContainer, spinnerContainer);
        // custom spinner -- end

        this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.containerLoading) {
            const container = this.targetEl.nativeElement;
            const div = container.querySelector('.' + this.uid);
            if (div) {
                this.renderer.setStyle(div, 'display', this.containerLoading ? 'flex' : 'none');
            }
        }
    }
}
