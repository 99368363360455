<div class="modal-header">
    <div class="w-full flex justify-content-center">
        <h2>
            Customise {{ typeToLabel(type) }} export
        </h2>
        <button (click)="close()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
</div>
<div class="modal-body">
    <div *ngIf="userReportStructure"
         class="flex flex-column gap-10 m-20">

        <div *ngFor="let sheet of userReportStructure.sheets">
            <label class="form-check-label">
                <input [(ngModel)]="sheet._checked"
                       (ngModelChange)="toggleAllColumns(sheet)"
                       class="form-check-input"
                       type="checkbox">
                <span class="m-l-20">{{ sheet.label }}</span>
            </label>

            <div *ngIf="sheet.columns?.length"
                 class="flex flex-column gap-1 m-l-20 m-t-10">
                <div *ngFor="let column of sheet.columns">
                    <label class="form-check-label">
                        <input [(ngModel)]="column._checked"
                               (ngModelChange)="onSelectColumn(sheet, column)"
                               class="form-check-input"
                               type="checkbox">
                        <span class="m-l-20">{{ column.label }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer flex flex-row gap-15">
    <button class="btn btn-default btn-border-radius waves-effect"
            (click)="close()"
            data-dismiss="modal"
            type="button">
        Cancel
    </button>
    <button class="btn btn-primary btn-border-radius waves-effect"
            (click)="generateExport()"
            type="button">
        Save
    </button>
</div>
