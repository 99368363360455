import { Component, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    RequestProjectQuoterModalComponent
} from 'app/flows/scheduler/components/request-project-quoter-modal/request-project-quoter-modal.component';

@Injectable({ providedIn: 'root' })
export class RequestProjectQuoterModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;
    private isOpen = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal) {}

    open(projectId: number): NgbModalRef {
        this.ngbModalRef = this.modalService.open(RequestProjectQuoterModalComponent as Component, {
            size: 'md',
            windowClass: 'bp-modal',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.projectId = projectId;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
