import { Component } from '@angular/core';
import { QuotesService } from "app/flows/scheduler/quotes/services/quotes.service";


@Component({
    selector: 'bp-project-details-invitations',
    templateUrl: './project-details-invitations.component.html',
    styleUrls: ['project-details-invitations.scss']
})
export class ProjectDetailsInvitationsComponent{
    constructor(protected context: QuotesService) {
    }
}
