import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'bp-all-stages',
    templateUrl: './all-stages.component.html',
    styleUrls: ['all-stages.scss']
})
export class AllStagesComponent {
    @Input() expanded = false;
    @Input() total: number = null;
    @Output() onChanged = new EventEmitter();

    toggleExpand() {
        this.expanded = !this.expanded;
        this.onChanged.emit(this.expanded);
    }
}
