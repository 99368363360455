import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IComponentArea } from 'app/shared/model/component-area.model';
import * as _ from 'lodash';
import { TagService } from 'app/shared/dataservices/tag.service';
import { ITag } from 'app/shared/model/tag.model';

@Component({
    selector: 'bp-tag-update',
    templateUrl: './tag-update.component.html'
})
export class TagUpdateComponent implements OnInit {
    tag: ITag;
    tags: ITag[];
    isSaving: boolean;

    constructor(private tagService: TagService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.isSaving = false;
        this.activatedRoute.data.subscribe(({ tag }) => {
            this.tag = tag;
        });

        this.loadParentTags();
    }

    previousState(): void {
        window.history.back();
    }

    save(): void {
        this.isSaving = true;
        if (this.tag.id !== undefined) {
            this.subscribeToSaveResponse(this.tagService.update(this.tag));
        } else {
            this.subscribeToSaveResponse(this.tagService.create(this.tag));
        }
    }

    trackById(index: number, item: ITag): number {
        return item.id;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ITag>>): void {
        result.subscribe(
            (res: HttpResponse<ITag>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess(): void {
        this.isSaving = false;
        this.previousState();
    }

    private onSaveError(): void {
        this.isSaving = false;
    }

    private loadParentTags(): void {
        this.tags = [];
        this.tagService.query().subscribe(
            (res: HttpResponse<IComponentArea[]>) => {
                this.tags = _.filter(res.body, (t: ITag) => {
                    return t.id !== this.tag.id;
                });
            }
        );
    }
}
