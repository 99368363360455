import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectInputData } from '../../common/select-input/select-input.component';
import { IElement } from '../../../model/element.model';

@Component({
    selector: 'bp-add-element-modal',
    templateUrl: './add-element-modal.component.html',
    styleUrls: ['add-element-modal.scss']
})
export class AddElementModalComponent {
    selectedElement: IElement;
    elementSelectInputData: SelectInputData;

    constructor(public activeModal: NgbActiveModal) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    onElementSelectionChange(event) {
        this.selectedElement = event;
    }

    addElement() {
        this.activeModal.close(this.selectedElement);
    }
}
