import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IValuationResponse } from 'app/shared/model/bp.model';
import { IProject } from 'app/shared/model/project.model';
import { IAccount } from 'app/shared/model/account.model';
import { IComparison } from 'app/shared/model/comparison.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NUMBER_PATTERN } from 'app/shared/constants/patterns';
import { ApplicationStateService } from "app/core/application-state.service";

export type ValuationPageMode = 'view' | 'create';

@Injectable({ providedIn: 'root' })
export class ValuationsStore {
    public valuationMode: ValuationPageMode = 'view';
    public account: IAccount;
    public nativeComparison: IComparison = null;
    private valuationResponseSubject = new BehaviorSubject<IValuationResponse | null>(null);
    public valuationResponse$ = this.valuationResponseSubject.asObservable();

    public createForm = new FormGroup({
        valuations: this.fb.array([]),
        deposit: new FormControl(10, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
        retention: new FormControl(5, [Validators.pattern(NUMBER_PATTERN), Validators.required]),
    });

    constructor(private appStateService: ApplicationStateService,
                private fb: FormBuilder) {
    }

    public get valuationResponse(): IValuationResponse | null {
        return this.valuationResponseSubject.value;
    }

    public set valuationResponse(value: IValuationResponse | null) {
        this.valuationResponseSubject.next(value);
    }

    get project(): IProject {
        return this.appStateService.project;
    }

}
