import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ActivatedRoute } from '@angular/router';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IArea } from 'app/shared/model/area.model';
import { HttpResponse } from '@angular/common/http';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import * as _ from 'lodash';
import { IComparisonStage } from 'app/shared/model/comparison-stage.model';
import {
    IndividualQuoteExpandStagesStorageService
} from 'app/flows/scheduler/individual-quote/services/expand-stages-storage.service';
import { IStage } from 'app/shared/model/stage.model';
import {
    IndividualQuoteExpandAreaGroupStorageService
} from 'app/flows/scheduler/individual-quote/services/expand-area-group-storage.service';
import { IAccount } from 'app/shared/model/account.model';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import {
    ApplyMainFilterToComparisonService,
    IMainFilterApplyingToComparisonResult
} from 'app/flows/scheduler/services/apply-main-filter-to-comparison.service';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { AccountService } from 'app/core/auth/account.service';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';
import { ExportReportsService, IMainReportData } from 'app/shared/services/export/export-reports.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { lastValueFrom } from 'rxjs';
import { NgxPopperjsContentComponent } from "ngx-popperjs";
import { UserReportModalService } from "app/account/user-report-modal/user-report-modal.service";

/**
 * Main Individual Quoter Component
 */
@Component({
    selector: 'bp-individual-quote',
    templateUrl: './individual-quote.component.html'
})
export class IndividualQuoteComponent implements OnInit {

    MENU_TOOLTIP_OPEN_DELAY = 100;

    project: IProject;
    quoterId: number;
    quoter: IComparisonQoter;

    nativeScheduleAreas: IScheduleArea[];
    filteredScheduleAreas: IScheduleArea[];

    stages: IStage[];

    routeData: any;

    nativeComparison: IComparison = null;
    comparison: IComparison;

    account: IAccount;

    filterState: IMainViewFilterState;
    scheduleAreaItems: IMainViewFilterItemItem[] = [];
    stageItems: IMainViewFilterItemItem[] = [];

    expandedAll = false;

    inProcessLoadingScheduleAreas = false;
    inProcessLoadingStages = false;
    inProcessLoadingComparison = false;
    inProcessLoadingComparisonForArea = false;
    inProcessExportingAsPDF = false;

    inited = false;

    @ViewChild(NgxPopperjsContentComponent) bpExportSelector: NgxPopperjsContentComponent;

    constructor(
        private projectApi: ProjectApi,
        private expandStagesStorageService: IndividualQuoteExpandStagesStorageService,
        private expandAreaGroupStorageService: IndividualQuoteExpandAreaGroupStorageService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private applyMainFilterToComparisonService: ApplyMainFilterToComparisonService,
        private activatedRoute: ActivatedRoute,
        private alertService: BpAlertService,
        private accountService: AccountService,
        private exportReportsService: ExportReportsService,
        private freemiumModalService: FreemiumModalService,
        private _userReportModalService: UserReportModalService
    ) {
        this.routeData = this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
            this.quoterId = data.quoterId;
        });
    }

    ngOnInit(): void {
        this.accountService.identity().then((account: IAccount) => {
            this.account = account;
            this.reloadAll();
        });
    }

    inProcess(): boolean {
        return (
            this.inProcessLoadingScheduleAreas ||
            this.inProcessLoadingComparison ||
            this.inProcessLoadingStages ||
            this.inProcessLoadingComparisonForArea ||
            this.inProcessExportingAsPDF
        );
    }

    onMainViewFilterChangedFunc(newFilterState: IMainViewFilterState): void {
        this.filterState = newFilterState;
        this.mainFilterInitialStateStorageService.store(this.project.id, 'individual_quote', newFilterState);
        this.applyMainViewFilter();
    }

    onExportTypeSelected(exportType: ExportType): void {
        this.bpExportSelector.hide();

        const showSuccessMessage = () => {
            this.alertService.success(`Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
        }

        this.freemiumModalService.verify('export').then((res) => {
            if (res) {
                const mainReportData: IMainReportData = {
                    project: this.project,
                    filteredScheduleAreas: this.filteredScheduleAreas,
                    filterState: this.filterState,
                    scheduleAreaItems: this.scheduleAreaItems,
                    stageItems: this.stageItems,
                    cssElementItems: [],
                    buildUpItems: []
                }

                switch (exportType) {
                    case 'csv':
                        this._userReportModalService.open('EXCEL').result.then((res) => {
                            if (res) {
                                this.exportReportsService.exportAsExcelIndividualQuoter(mainReportData, this.quoter);
                                showSuccessMessage();
                            }
                        })
                        break;
                    case 'docx':
                        this._userReportModalService.open('WORD').result.then((res) => {
                            if (res) {
                                this.exportReportsService.exportAsDocxIndividualQuoter(mainReportData, this.quoter);
                                showSuccessMessage();
                            }
                        })
                        break;
                    case 'pdf':
                        this._userReportModalService.open('PDF').result.then((res) => {
                            if (res) {
                                this.exportReportsService.exportAsPDFIndividualQuoter(mainReportData, this.quoter);
                                showSuccessMessage();
                            }
                        })
                        break;
                }

                this.alertService.success(`Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
            }
        });
    }

    toggleExpand(): void {
        this.expandedAll = !this.expandedAll;

        const stagesToExpand = this.getActualStages();

        if (this.filterState.groupBy === 'area') {
            _.each(this.filteredScheduleAreas, (area: IArea) => {
                //area.expanded = this.expandedAll;
                this.expandAreaGroupStorageService.store(this.project, this.quoterId, area, this.expandedAll);
            });
        }
        _.each(stagesToExpand, (stage: IStage) => {
            //stage.expanded = this.expandedAll;
            this.expandStagesStorageService.store(this.project, this.quoterId, stage, this.expandedAll);
        });
    }

    private applyMainViewFilter(): void {
        const result: IMainFilterApplyingToComparisonResult = this.applyMainFilterToComparisonService.apply(
            [this.quoter],
            this.nativeComparison,
            this.nativeScheduleAreas,
            this.filterState,
            this.scheduleAreaItems,
            this.stageItems,
            []
        );

        this.filteredScheduleAreas = result.scheduleAreas;
        this.comparison = result.comparison;
    }

    private updateExpandedAll(): void {
        const actualStages = this.getActualStages();
        const collapsedStage = _.find(actualStages, { expanded: false });

        this.expandedAll = collapsedStage == null;

        if (this.filterState.groupBy === 'area') {
            const collapsedArea = _.find(this.nativeScheduleAreas, { expanded: false });
            this.expandedAll = this.expandedAll && collapsedArea == null;
        }
    }

    private getActualStages(): IStage[] | IComparisonStage[] {
        let actualStages = [];

        switch (this.filterState.groupBy) {
            case 'area':
            case 'area_room':
                _.each(this.nativeScheduleAreas, (area: IArea) => {
                    actualStages = _.union(actualStages, area['comparison']['stageDTOs']);
                });
                break;
            case 'stage':
            case 'stage_room':
                actualStages = this.comparison.stageDTOs;
                break;
        }

        return actualStages;
    }

    private reloadAll(): void {
        this.loadStages().then(() => {
            this.loadScheduleAreas().then(() => {
                this.loadComparison().then(() => {
                    try {
                        this.filterState = this.mainFilterInitialStateStorageService.retrieve(
                            this.project.id,
                            'individual_quote'
                        );
                    } catch (e) {
                        console.warn('Cannot restore main filter view from local storage');
                    }

                    this.applyMainViewFilter();
                    this.restoreExpandState();
                    this.updateExpandedAll();
                    this.inited = true;
                });
            });
        });
    }

    private loadStages(): Promise<void> {
        this.inProcessLoadingStages = true;

        return lastValueFrom(this.projectApi
            .queryScheduleStages(this.project.id)
            .pipe(
                finalize(() => {
                    this.inProcessLoadingStages = false;
                })
            )).then(
            (res: HttpResponse<IStage[]>) => {
                this.stages = res.body;
                this.stageItems = _.map(this.stages, (a: IStage) => {
                    return {
                        id: a.id,
                        title: a.stage
                    };
                });
            });
    }

    private loadComparison(): Promise<void> {
        this.inProcessLoadingComparison = true;

        return lastValueFrom(this.projectApi
            .queryComparison(this.project.id, this.getQuoterIdsQueryParam())
            .pipe(
                finalize(() => {
                    this.inProcessLoadingComparison = false;
                })
            )).then((res: HttpResponse<IComparison>) => {
            this.nativeComparison = res.body;
            this.quoter = this.nativeComparison.quoters[0];
        });

    }

    private loadScheduleAreas(): Promise<void> {
        this.inProcessLoadingScheduleAreas = true;

        return lastValueFrom(this.projectApi
            .queryAvailableScheduleAreas(this.project.id)
            .pipe(
                finalize(() => {
                    this.inProcessLoadingScheduleAreas = false;
                })
            )).then(
            (res: HttpResponse<IScheduleArea[]>) => {
                this.nativeScheduleAreas = _.clone(res.body);

                this.scheduleAreaItems = _.map(this.nativeScheduleAreas, (a: IScheduleArea) => {
                    return {
                        id: a.id,
                        title: a.area
                    };
                });

                return this.loadComparisonsForAreas();
            },
        );
    }

    private loadComparisonsForAreas(): Promise<void> {
        const promises = [];

        _.each(this.nativeScheduleAreas, (area: IScheduleArea) => {
            promises.push(this.getComparisonForArea(area));
        });

        if (promises.length === 0) {
            return Promise.resolve();
        }

        this.inProcessLoadingComparisonForArea = true;

        return Promise.all(promises).then((result: Array<IComparison[]>) => {
            let i = 0;

            _.each(this.nativeScheduleAreas, (area: IScheduleArea) => {
                area['comparison'] = result[i++];
            });
        }).finally(() => {
            this.inProcessLoadingComparisonForArea = false;
        })
    }

    private getComparisonForArea(area: IScheduleArea): Promise<IComparison> {
        return lastValueFrom(this.projectApi.queryComparison(this.project.id, this.getQuoterIdsQueryParam(), [area.id]))
            .then(
                (res: HttpResponse<IComparison>) => {
                    return res.body;
                }
            );
    }

    private restoreExpandState(): void {
        switch (this.filterState.groupBy) {
            case 'area':
                _.each(this.filteredScheduleAreas, (area: IScheduleArea) => {
                    area.expanded = this.expandAreaGroupStorageService.retrieve(this.project, this.quoterId, area);
                });
                break;
            case 'stage':
                _.each(this.comparison.stageDTOs, (stage: IComparisonStage) => {
                    stage.expanded = this.expandStagesStorageService.retrieve(this.project, this.quoterId, stage);
                });
                break;
        }
    }

    private getQuoterIdsQueryParam(): number[] {
        return [this.quoterId];
    }
}
