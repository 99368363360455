<div class="card"
     [ngClass]="{'selected' : tag.selected}"
     (click)="onClick()">
    <div class="header text-center">
        <h1 class="tag-name ellipsis-overflow-container"
            title="{{tag.name}}">
            {{ tag.name }}
        </h1>
    </div>
</div>
