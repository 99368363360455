<form (ngSubmit)="saveNote()"
      name="editScheduleTaskNoteForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Edit note
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <form #editForm="ngForm"
              name="editForm"
              novalidate
              role="form">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input type="text"
                               class="form-control"
                               placeholder="Enter notes here"
                               [(ngModel)]="note"
                               name="note"
                               #notes="ngModel">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                class="btn btn-secondary btn-border-radius"
                data-dismiss="modal"
                type="button">
            Cancel
        </button>
        <button class="btn btn-primary btn-border-radius save-note"
                type="submit">
            Save
        </button>
    </div>
</form>
