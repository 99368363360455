import { BuilderLabour, IBuilderLabour, IEndpointLabour } from 'app/shared/model/builder-labour.model';
import * as _ from 'lodash';
import { ILaboursWrapper } from 'app/shared/dataservices/quoter.api';

export interface ILabourRatesDataModel {
    margin: number;
    labours: IBuilderLabour[];

    updateMargin(value: number): void;

    fill(laboursInput: IEndpointLabour[]): void;

    isMarginValid(): boolean;

    applyMarginToAll(): void;

    endpointData(): ILaboursWrapper;
}

export class LabourRatesDataModel implements ILabourRatesDataModel {
    private _margin: number;
    private _labours: IBuilderLabour[];

    constructor() {
        this._margin = 0;
        this._labours = [];
    }

    get margin(): number {
        return this._margin;
    }

    set margin(value: number) {
        this._margin = value;
    }

    get labours(): IBuilderLabour[] {
        return this._labours;
    }

    set labours(values: IBuilderLabour[]) {
        this._labours = _.map(values, (value: IBuilderLabour) => new BuilderLabour(value));
    }

    updateMargin(value: number): void {
        this._margin = +(value * 100).toFixed(2);
    }

    fill(laboursInput: IEndpointLabour[]): void {
        _.each(laboursInput, (_labour: IEndpointLabour) => {
            const existedLabour = _.find(this._labours, { id: _labour.id });
            if (existedLabour) {
                _.merge(existedLabour, new BuilderLabour(_labour));
            } else {
                this._labours.push(new BuilderLabour(_labour));
            }
        });
    }

    isMarginValid(): boolean {
        return this.margin != null && typeof this.margin === 'number' && this.margin >= 0;
    }

    endpointData(): ILaboursWrapper {
        return {
            margin: this.margin / 100,
            labours: _.map(this.labours, (labour: IBuilderLabour) => {
                return labour.endpointData();
            })
        };
    }

    applyMarginToAll(): void {
        _.each(this.labours, (labour: IBuilderLabour) => {
            labour.margin = this.margin;
        });
    }
}
