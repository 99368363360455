import { Component, OnInit } from '@angular/core';
import {
    RequestTemplateModalService
} from 'app/flows/scheduler/template-wizard/components/request-template-modal/request-template-modal.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-request-new-card',
    templateUrl: './request-new-card.component.html',
    styleUrls: ['request-new-card.scss']
})
export class RequestNewCardComponent implements OnInit {
    constructor(private requestTemplateModalService: RequestTemplateModalService,
                private appStateService: ApplicationStateService,
                private freemiumModalService: FreemiumModalService) {
    }

    ngOnInit(): void {
    }

    onRequestNewClick(): void {
        this.freemiumModalService.verify('request-template').then((res) => {
            if (res) {
                this.requestTemplateModalService.open(this.appStateService.project);
            }
        });
    }
}
