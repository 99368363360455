import { Component, Input, OnInit } from '@angular/core';
import {
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { IMainViewFilterItemItem } from "app/shared/components/common/main-view-filter/main-view-filter.component";
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-dashboard-cost-plan-table-default-quoter',
    templateUrl: './cost-plan-table-default-quoter.component.html',
    styleUrls: ['cost-plan-table-default-quoter.scss']
})
export class DashboardCostPlanTableDefaultQuoterComponent implements OnInit {
    @Input() useInTile: boolean = false;

    VIEW_MORE_LIMIT = 8;
    viewMoreMode = true;

    constructor(
        private _appStateService: ApplicationStateService,
        protected dashboardService: DashboardService,
        protected dashboardStore: DashboardStore) {
    }

    get quoter(): IComparisonQoter {
        return this.dashboardStore.quoters.find(q => q.default);
    }

    get valueColumn(): ICostPlanValueColumn | null {
        return this.dashboardStore.valueColumns?.find(valueColumn => valueColumn.quoter.id === this._appStateService.project.defaultQuoter.id ||
            (valueColumn.quoter.default && valueColumn.quoter.company.toLowerCase().startsWith('m')));
    }

    ngOnInit(): void {
    }

    itemChecked(item: IMainViewFilterItemItem): boolean {
        return this.dashboardStore.filterStateIds == null || this.dashboardStore.filterStateIds.indexOf(item.id) !== -1;
    }

    onCheckboxClick(item: IMainViewFilterItemItem): void {
        this.dashboardService.toggleItem(item);
    }

    getValue(column: ICostPlanValueColumn, item: IMainViewFilterItemItem): number {
        return column.data.find(d => d.item.id === item.id)?.total || 0;
    }

}
