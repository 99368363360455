<img alt=""
     class="login-logo"
     src="../../../content/images/bp_logo.png"/>

<div class="wrap-login">
    <form #registerForm="ngForm"
          (ngSubmit)="register()"
          class="form validate-form"
          name="form"
          role="form"
          autocomplete="off">
        <span class="login-form-title">
            Sign Up
        </span>

        <div class="flex-between-container m-b-20">
            <div style="flex: 1">
                <label class="form-label">
                    First name
                </label>
                <input #firstName="ngModel"
                       [(ngModel)]="registerAccount.firstName"
                       class="form-control"
                       id="first-name"
                       name="first-name"
                       type="string">
            </div>
            <div style="flex: 1">
                <label class="form-label">
                    Last name
                </label>
                <input #lastName="ngModel"
                       [(ngModel)]="registerAccount.lastName"
                       class="form-control"
                       id="last-name"
                       name="last-name"
                       type="string">
            </div>
        </div>

        <div class="flex-between-container m-b-20">
            <div style="flex: 1">
                <label class="form-label">
                    Profession*
                </label>
                <bp-select-input
                    (onSelectionChange)="onProfessionSelectionChange($event)"
                    *ngIf="professionSelectInputData"
                    [data]="professionSelectInputData"
                    [placeholder]="'Select'"
                    class="select-profession">
                </bp-select-input>
            </div>
            <div style="flex: 1">
                <label class="form-label">
                    Company
                </label>
                <input #company="ngModel"
                       [(ngModel)]="registerAccount.company"
                       class="form-control"
                       id="company"
                       name="company"
                       type="string">
            </div>
        </div>

        <div class="flex-between-container m-b-20">
            <div style="flex: 1">
                <label class="form-label">
                    Email*
                </label>
                <input #email="ngModel"
                       [(ngModel)]="registerAccount.email"
                       class="form-control"
                       email
                       id="email"
                       maxlength=254
                       minlength=5
                       name="email"
                       required
                       type="email">
                <div *ngIf="email.dirty && email.invalid">
                    <small *ngIf="email.errors.required"
                           class="form-text text-danger">
                        Your email is required.
                    </small>
                    <small *ngIf="email.errors.invalid"
                           class="form-text text-danger">
                        Your email is invalid.
                    </small>
                    <small *ngIf="email.errors.minlength"
                           class="form-text text-danger">
                        Your email is required to be at least 5 characters.
                    </small>
                    <small *ngIf="email.errors.maxlength"
                           class="form-text text-danger">
                        Your email cannot be longer than 100 characters.
                    </small>
                </div>
            </div>
            <div style="flex: 1">
                <label class="form-label">
                    Phone Number
                </label>
                <input #companyNumber="ngModel"
                       [(ngModel)]="registerAccount.companyNumber"
                       class="form-control"
                       id="company-number"
                       name="company-number "
                       type="string">
            </div>
        </div>

        <div class="m-b-20">
            <label class="form-label">
                Where did you hear about us?
            </label>
            <bp-select-input
                (onSelectionChange)="onHeardAboutUsSelectionChange($event)"
                *ngIf="heardAboutUsSelectInputData"
                [data]="heardAboutUsSelectInputData"
                [placeholder]="'Select'"
                class="select-heard-about-us">
            </bp-select-input>
            <div class="m-t-10"
                 *ngIf="selectedHeardAboutUs?.value === OTHER">
                <input #heardAboutUsOther="ngModel"
                       [(ngModel)]="registerAccount.heardAboutUs"
                       class="form-control"
                       placeholder="Please give more details"
                       id="heard-about-us-other"
                       name="heard-about-us-other"
                       type="text">
            </div>
            <div class="m-t-10"
                 *ngIf="selectedHeardAboutUs?.value === REFERRAL">
                <input #heardAboutUsOther="ngModel"
                       [(ngModel)]="registerAccount.heardAboutUs"
                       class="form-control"
                       placeholder="Name of person who referred you. We like to thank our users!"
                       id="heard-about-us-referral"
                       name="heard-about-us-referral"
                       type="text">
            </div>
        </div>

        <div class="m-b-20">
            <label class="form-label">
                Password*
            </label>
            <input #password="ngModel"
                   [(ngModel)]="registerAccount.password"
                   class="form-control"
                   id="password"
                   pattern="^\S{4,50}$"
                   name="password"
                   type="password"
                   autocomplete="new-password">
            <div *ngIf="password.dirty && password.invalid">
                <small *ngIf="password.errors.pattern"
                       class="form-text text-danger">
                    Your password must not contain whitespaces. Minimum length is 4. Maximum length is 50.
                </small>
            </div>
            <bp-password-strength-bar
                *ngIf="false"
                [passwordToCheck]="registerAccount.password">
            </bp-password-strength-bar>
        </div>

        <div class="m-b-20">
            <label class="form-label">
                Confirm Password*
            </label>
            <input #confirmPasswordInput="ngModel"
                   [(ngModel)]="confirmPassword"
                   class="form-control"
                   id="confirmPassword"
                   maxlength=50
                   minlength=8
                   name="confirmPassword"
                   required
                   type="password"
                   autocomplete="new-password">
            <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                <small *ngIf="confirmPasswordInput.errors.required"
                       class="form-text text-danger">
                    Your confirmation password is required.
                </small>
                <small *ngIf="confirmPasswordInput.errors.minlength"
                       class="form-text text-danger">
                    Your confirmation password is required to be at least 8 characters.
                </small>
                <small *ngIf="confirmPasswordInput.errors.maxlength"
                       class="form-text text-danger">
                    Your confirmation password cannot be longer than 50 characters.
                </small>
            </div>
        </div>

        <div class="agree-license-container">
            <div class="form-check">
                <label class="form-check-label"
                       for="agree-license">
                    I agree to Buildpartner <a [href]="TERMS_OF_USE_URL" target="_blank" rel="noopener" class="link">Terms
                    of use</a> and <a [href]="PRIVACY_POLICY_URL" target="_blank" rel="noopener" class="link">Privacy
                    Policy</a>
                    <input [(ngModel)]="agreeLicense"
                           class="form-check-input"
                           id="agree-license"
                           name="agree-license"
                           type="checkbox">
                    <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                </label>
            </div>
        </div>

        <div class="container-signup-form-btn">
            <button [disabled]="registerForm?.form?.invalid || registerAccount?.profession == null || !agreeLicense"
                    class="signup btn-primary btn-border-radius btn"
                    type="submit">
                Start Free Trial
            </button>
        </div>

        <div class="text-center m-t-40">
            <span class="link-title">Already have an account?</span>
            <a (click)="login()"
               class="link"
               href="javascript:void(0);">
                Sign In
            </a>
        </div>
    </form>
</div>
