import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'bp-items-selector',
    templateUrl: './items-selector.component.html',
    styleUrls: ['items-selector.scss']
})
export class ItemsSelectorComponent implements OnInit {
    @Input() items: any[] = [];
    @Input() labelField = 'label';

    @Output() onChanged = new EventEmitter<any>();

    ngOnInit(): void {
    }

    select(item: any): void {
        this.onChanged?.emit(item);
    }
}
