import { Component, Input, OnInit } from '@angular/core';
import { IMainViewFilterItemItem } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import {
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-dashboard-cost-plan-table-comparison',
    templateUrl: './cost-plan-table-comparison.component.html',
    styleUrls: ['cost-plan-table-comparison.scss']
})
export class DashboardCostPlanTableComparisonComponent implements OnInit {
    @Input() useInTile: boolean = false;

    protected VIEW_MORE_LIMIT = 8;
    protected viewMoreMode = true;

    constructor(protected appState: ApplicationStateService,
                protected dashboardService: DashboardService,
                protected dashboardStore: DashboardStore) {
    }

    get quoters(): IComparisonQoter[] {
        return this.dashboardStore.quoters.filter((q) => q.selected);
    }

    get valueColumns(): ICostPlanValueColumn[] {
        return this.dashboardStore.valueColumns.filter((vc) => vc.quoter.selected);
    }

    ngOnInit(): void {
    }

    protected itemChecked(item: IMainViewFilterItemItem): boolean {
        return this.dashboardStore.filterStateIds == null || this.dashboardStore.filterStateIds.indexOf(item.id) !== -1;
    }

    protected onCheckboxClick(item: IMainViewFilterItemItem): void {
        this.dashboardService.toggleItem(item);
    }

    protected getValue(column: ICostPlanValueColumn, item: IMainViewFilterItemItem): number {
        return column.data.find(d => d.item.id === item.id)?.total || 0;
    }

    protected selectDefaultQuoter(quoter: IComparisonQoter): void {
        if (this.dashboardStore.quoters.length === 1 || (!this.appState.project.isOwner && ['OWNER', 'EDIT'].indexOf(this.appState.project.privilege) === -1)) {
            return;
        }
        if (this.dashboardService.isQuoterDefault(quoter)) {
            return;
        }
        this.dashboardService.updateDefaultQuoter(quoter);
    }

    protected getTooltip(quoter: IComparisonQoter): string {
        let additionalInfo = null;

        switch (quoter.company?.toLowerCase()) {
            case 'low benchmark':
            case 'small': {
                additionalInfo = 'Smaller companies are generally lower cost but less specialised and with low project management function, good for low/mid-spec projects and tight budgets';
                break;
            }
            case 'medium benchmark':
            case 'mid':
            case 'medium': {
                additionalInfo = 'Medium-sized companies are generally moderately priced and can cover a wide range of projects with low or high project management requirements.';
                break;
            }
            case 'high benchmark':
            case 'large': {
                additionalInfo = 'Larger companies generally have a higher cost base but can handle a wide variety of specialist tasks, good for mid/high-spec projects and high project management requirements.';
                break;
            }
        }

        let result = `${quoter.company || quoter.email}`;

        if (this.dashboardStore.quoters.length > 1 && (this.appState.project.isOwner || ['OWNER', 'EDIT'].indexOf(this.appState.project.privilege) !== -1)) {
            result = `Select ${result}`;
        }

        if (additionalInfo) {
            result = `${result} (${additionalInfo})`;
        }

        return result;
    }
}
