<div>
    <div class="sticky-button-container">
        <button *ngIf="isAdmin"
                [disabled]="f.invalid || inProcess()"
                (click)="showUpdateConfirmation()"
                ngbTooltip="Save"
                class="btn btn-primary btn-border-radius save"
                type="button">
            {{ inProcessSavingMaterials ? "Saving..." : "Save" }}
        </button>

        <button *ngIf="!isAdmin"
                (click)="submitFormButton.click()"
                [disabled]="inProcess()"
                class="btn btn-primary btn-border-radius save"
                ngbTooltip="Save"
                type="button">
            {{ inProcessSavingMaterials ? "Saving..." : "Save" }}
        </button>
    </div>
    <div class="flex flex-row justify-content-between items-center flex-wrap gap-40 m-r-80">
        <div class="flex-1">
            <h1>
                Default material rates
                <a (click)="showHelpModal()">
                    <i class="material-icons">info_outline</i>
                </a>
            </h1>
        </div>
        <div class="flex-1 flex justify-content-end">
            <button (click)="refreshCosts()"
                    [disabled]="inProcess()"
                    class="btn btn-secondary btn-border-radius"
                    type="button">
                Restore default rates
            </button>
        </div>
    </div>

    <div class="card materials m-t-20">
        <div class="header">
            <div class="flex flex-row flex-wrap gap-40 justify-content-between m-b-40">
                <div style="min-width: 300px">
                    <input [formControl]="searchControl"
                           class="af-input"
                           name="materialSearchValue"
                           placeholder="Input search text here.."
                           type="text">
                </div>
                <div class="flex flex-row flex-wrap gap-40">
                    <div class="flex gap-10 align-items-baseline flex-wrap">
                        <h4>
                            Handling charge
                        </h4>
                        <div class="percent">
                            <input [(ngModel)]="dataModel.handlingCharge"
                                   [disabled]="inProcess()"
                                   class="af-input text-right"
                                   placeholder="Enter handling charge"
                                   required
                                   mask="percent.2"
                                   suffix="%"
                                   thousandSeparator=","
                                   type="text">
                        </div>
                    </div>
                    <div class="flex gap-10 align-items-baseline flex-wrap">
                        <h4>
                            Margin
                        </h4>
                        <div class="percent">
                            <input [(ngModel)]="dataModel.margin"
                                   [disabled]="inProcess()"
                                   class="af-input text-right"
                                   placeholder="Enter margin"
                                   required
                                   mask="separator.2"
                                   [dropSpecialCharacters]="true"
                                   suffix="%"
                                   thousandSeparator=","
                                   type="text">
                        </div>
                    </div>
                    <div class="text-right">
                        <button (click)="applyMarginToAllConfirmation()"
                                [disabled]="!dataModel.isMarginValid() || inProcess()"
                                class="btn btn-primary btn-small btn-border-radius"
                                ngbTooltip="Apply margin to all"
                                type="button">
                            Apply to All
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-5">
                    <h4>
                        Materials
                    </h4>
                </div>
            </div>

            <div [(ascending)]="reverse"
                 [(predicate)]="predicate"
                 [callback]="transition.bind(this)"
                 class="row"
                 bpSort>
                <div class="col-sm-3">
                    <span class="table-title">
                        Tasks
                    </span>
                </div>
                <div class="col-sm-5"
                     bpSortBy="material">
                    <span class="table-title">
                        Material
                    </span>
                    <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                </div>
                <div class="col-sm-1 text-right">
                    <span class="table-title">
                       Unit
                    </span>
                </div>
                <div class="col-sm-1 text-right"
                     bpSortBy="cost">
                    <span class="table-title">
                       Cost
                    </span>
                    <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                </div>
                <div class="col-sm-1 text-right"
                     bpSortBy="margin">
                    <span class="table-title">
                        Margin
                    </span>
                    <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                </div>
                <div class="col-sm-1 text-right">
                    <span class="table-title">
                        Rate
                    </span>
                </div>
            </div>

            <form #f="ngForm"
                  (ngSubmit)="!f.invalid && saveAndApply()"
                  class="material-container m-t-20"
                  name="form"
                  novalidate>
                <button #submitFormButton
                        id="submitFormButton"
                        type="submit">
                </button>
                <div *ngIf="inProcess()"
                     class="text-center">
                    <strong>
                        Please wait..
                    </strong>
                </div>

                <div *ngFor="let material of dataModel.materials; trackBy: trackId;"
                     class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <div class="form-line">
                                <input [value]="getTasksValue(material)"
                                       [disabled]="true"
                                       class="af-input"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group">
                            <div class="form-line">
                                <input [(ngModel)]="material.material"
                                       [disabled]="true"
                                       class="af-input"
                                       name="material_{{material.materialId}}"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <div class="form-group">
                            <div class="form-line">
                                <input [(ngModel)]="material.unit"
                                       [disabled]="true"
                                       class="af-input text-right"
                                       name="unit_{{material.materialId}}"
                                       type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <div class="form-group">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input #cost="ngModel"
                                           [(ngModel)]="material.cost"
                                           [disabled]="true"
                                           [ngClass]="{ 'error': (f.submitted || cost.dirty || cost.touched) && cost.invalid }"
                                           class="af-input text-right"
                                           name="cost_{{material.materialId}}"
                                           placeholder="Enter cost"
                                           required
                                           mask="separator.2"
                                           thousandSeparator=","
                                           prefix="&pound;&nbsp;"
                                           type="text">
                                </div>
                                <div *ngIf="(f.submitted || cost.dirty) && cost.invalid">
                                    <label *ngIf="cost.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <div class="form-group percent">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input #margin="ngModel"
                                           [(ngModel)]="material.margin"
                                           [disabled]="true"
                                           [ngClass]="{ 'error': (f.submitted || margin.dirty || margin.touched) && margin.invalid }"
                                           class="af-input text-right"
                                           name="margin_{{material.materialId}}"
                                           placeholder="Enter margin"
                                           required
                                           mask="separator.2"
                                           [dropSpecialCharacters]="true"
                                           suffix="%"
                                           thousandSeparator=","
                                           type="text">
                                </div>
                                <div *ngIf="(f.submitted || margin.dirty) && margin.invalid">
                                    <label *ngIf="margin.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <div class="form-group pound">
                            <div class="form-line">
                                <div class="flex-line">
                                    <input [disabled]="true"
                                           [(ngModel)]="material.rate"
                                           class="af-input text-right"
                                           name="rate_{{material.materialId}}"
                                           mask="separator.2"
                                           thousandSeparator=","
                                           prefix="&pound;&nbsp;"
                                           type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <small *ngIf="dataModel?.materials?.length === 0">
                    No items
                </small>
            </form>
        </div>
    </div>

    <div *ngIf="hasMore()">
        <div class="row justify-content-center">
            <button (click)="loadPage(page + 1)"
                    [disabled]="inProcess()"
                    class="btn btn-default btn-small btn-border-radius">
                {{ inProcess() ? 'Loading...' : 'Load More..' }}
            </button>
        </div>
    </div>
</div>
