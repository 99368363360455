<div *ngIf="buildUp"
     class="build-up-container w-full"
     id="build-up-container_{{ scheduleAreaId }}_{{ buildUp.id }}">
    <div>
        <div class="m-l-20">
            <div class="w-full flex flex-row flex-wrap gap-10 justify-content-between align-items-center"
                 style="height: 36px;">
                <div class="flex-1">
                    <div class="root-header m-l--20 flex flex-row items-center gap-15">
                        <button [disabled]="scheduleService.inProcess()"
                                [popper]="bpBuildUpSelectorSelector"
                                class="btn btn-secondary btn-border-radius">
                            <div class="flex align-items-center gap-1">
                               {{ buildUp.name }}
                                <i class="material-icons cursor-pointer">
                                    expand_more
                                </i>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="flex-1 explanations-data-line">
                    <span class="p-l-15">Specification</span>
                </div>

                <div class="flex-0_5 explanations-data-line">
                    <span>Area</span>
                </div>

                <div class="qti explanations-data-line">
                    <span>Qty</span>
                </div>

                <div
                    class="totals font-12 flex flex-row justify-content-between items-center gap-10 explanations-data-line">
                    <div class="flex-1 text-center overflow-ellipsis">
                        Labour<br>cost/unit
                    </div>
                    <div class="flex-1 text-center overflow-ellipsis">
                        Materials<br>cost/unit
                    </div>
                    <div class="flex-1 text-center overflow-ellipsis">
                        Unit<br>total cost
                    </div>
                    <div class="flex-1 text-center overflow-ellipsis">
                        Line<br>Total
                    </div>
                    <div class="flex-1 text-center overflow-ellipsis">
                        Client<br>Supplied
                    </div>
                </div>
                <div class="controls">
                    <div [title]="total | currency:'GBP'"
                         class="total">
                        {{ total | currency:'GBP' }}
                    </div>
                    <button (click)="onDeleteBiildUpClick()"
                            [disabled]="scheduleService.disabled"
                            class="delete-stage btn btn-circle btn-small btn-secondary"
                            ngbTooltip="Delete build-up">
                        <i class="material-icons md-18">delete_forever</i>
                    </button>
                </div>
            </div>
        </div>

        <div class="m-t-10">
            <bp-schedule-task-for-filter-by-build-up-grouping
                *ngFor="let scheduleTask of filteredScheduleTasks()"
                [element]="scheduleService.getElement(scheduleTask)"
                [scheduleTask]="scheduleTask"
                [stage]="scheduleService.getStage(scheduleTask)">
            </bp-schedule-task-for-filter-by-build-up-grouping>
        </div>
    </div>
</div>

<popper-content #bpBuildUpSelectorSelector>
    <bp-items-selector [items]="relatedBuildUps"
                       [labelField]="'name'"
                       (onChanged)="onBuildUpChange($event)">
    </bp-items-selector>
</popper-content>

