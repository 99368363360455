import { Component, Input } from '@angular/core';
import { IScheduleTemplate } from 'app/shared/model/schedule-template.model';
import * as _ from 'lodash';

@Component({
    selector: 'bp-template-list',
    templateUrl: './template-list.component.html',
    styleUrls: ['template-list.scss']
})
export class TemplateListComponent {
    @Input() templates: IScheduleTemplate[];
    @Input() disabled = false;

    get rowArray(): number[] {
        return _.range(this.templates.length);
    }

    trackId(index: number, item: IScheduleTemplate): number {
        return item.id;
    }
}
