<div class="successfully-updated-plan-modal">
    <div class="modal-header flex flex-column gap-15 align-items-center position-relative">
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>

        <div class="successfully-updated-plan-modal__title">
            Success! You have updated<br>your account!
        </div>
    </div>

    <div class="modal-body flex flex-column align-items-center gap-20">
        <div class="flex flex-column gap-20">
            <div>
                Thanks for your continued support!
            </div>
            <div>
                We're pleased you're finding it useful. We will update your account and send confirmation via email.
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button (click)="onOkClick()"
                tabindex="0"
                class="btn btn-primary btn-border-radius waves-effect w-full successfully-updated-plan-modal__ok"
                type="button">
            Close
        </button>
    </div>
</div>
