import { Component, Input, OnInit } from '@angular/core';
import { IElement } from 'app/shared/model/element.model';
import { IStage } from 'app/shared/model/stage.model';
import * as _ from 'lodash';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { StorageServiceProvider, WebStorageService } from "app/shared/services/web-storage.service";
import { skip } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'bp-schedule-element-for-filter-by-stage-grouping',
    templateUrl: './element.component.html',
    styleUrls: [
        '../../root-container/element.scss',
        '../../../schedule-common.scss'
    ],
    providers: [
        StorageServiceProvider, {
            provide: 'storageKeyConfig',
            useValue: { prefix: 'sc-elem-for-st-grg', separator: '-' }
        }]
})
export class ElementForFilterByStageGroupingComponent implements OnInit {
    @Input() stage: IStage;
    @Input() element: IElement;

    protected expanded = true;

    constructor(public scheduleService: ScheduleService,
                private storageService: WebStorageService) {
    }

    get elementTotal(): number {
        return this.filteredScheduleTasks()
            .reduce((total, task) => task.taskTotal.total + total, 0);
    }

    private get webStoragePath(): string[] {
        return [String(this.scheduleService.project.id), String(this.stage.id), String(this.element.id)];
    }

    filteredScheduleTasks(): IScheduleTask[] {
        return _.filter(this.element.scheduleTasks, scheduleTask => {
            return !scheduleTask.markedAsDeleted && (this.scheduleService.showSubStagesMode !== "finishes" || scheduleTask.finishes);
        });
    }

    ngOnInit(): void {
        this.expanded = this.storageService.retrieve(this.webStoragePath, true) as boolean;

        this.scheduleService.expandAll$
            .pipe(
                skip(1),
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res;
            this.saveToWebStorage(res);
        })
    }

    protected toggleElementExpand(): void {
        this.expanded = !this.expanded;
        this.saveToWebStorage(this.expanded);
    }

    private saveToWebStorage(value: boolean): void {
        if (value) {
            this.storageService.clear(this.webStoragePath);
        } else {
            this.storageService.store(this.webStoragePath, false);
        }
    }
}
