import { Component, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    SuccessfullyUpdatedPlanModalComponent
} from "app/shared/components/common/successfully-updated-plan-modal/successfully-updated-plan-modal.component";

@Injectable({ providedIn: 'root' })
export class SuccessfullyUpdatedPlanModalService {
    constructor(private modalService: NgbModal) {
    }

    showModal(): Promise<boolean> {
        return this.modalService.open(SuccessfullyUpdatedPlanModalComponent as Component, {
            windowClass: 'bp-modal successfully-updated-plan-modal',
            size: 'lg',
            backdrop: 'static'
        }).result.then((res: boolean) => {
            return res;
        });
    }
}
