import { Component, OnInit } from '@angular/core';
import { IAccount } from 'app/shared/model/account.model';
import { HttpResponse } from '@angular/common/http';
import { AccountService } from 'app/core/auth/account.service';
import { ExportSettingsService, IExportSettings } from 'app/shared/dataservices/export-settings.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { finalize } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UserReportModalService } from 'app/account/user-report-modal/user-report-modal.service';
import { ReportType } from 'app/shared/dataservices/user-report.api';
import { Role } from "app/shared/model/common.model";

@Component({
    selector: 'bp-export-settings',
    templateUrl: './export-settings.component.html',
    styleUrls: ['export-settings.scss']
})
export class ExportSettingsComponent implements OnInit {
    protected error: string;
    protected success: string;

    protected account: IAccount;
    protected exportSettings: IExportSettings;

    protected tabIndex = 0;

    protected _inProcess = false;

    protected get inProcess(): boolean {
        return this._inProcess;
    }

    constructor(
        private alertService: BpAlertService,
        private accountService: AccountService,
        private userReportModalService: UserReportModalService,
        private exportSettingsService: ExportSettingsService
    ) {
    }

    ngOnInit(): void {
        this.accountService.identity(true).then((account: IAccount) => {
            this.account = account;
        });

        this.exportSettingsService.get().subscribe((res: HttpResponse<IExportSettings>) => {
            this.exportSettings = res.body;
        });
    }

    protected customizeUserExport(type: ReportType, role?: Role): void {
        this.userReportModalService.open(type, role);
    }

    protected save(): void {
        this._inProcess = true;

        this.exportSettingsService.update(this.exportSettings)
            .pipe(finalize(() => {
                this._inProcess = false;
            }))
            .subscribe((res: HttpResponse<IExportSettings>) => {
                this.exportSettings = res.body;
               // this.alertService.success('Save successful');
            });
    }

    protected getEditorConfig(): AngularEditorConfig {
        const editorConfig: AngularEditorConfig = {
            editable: true,
            spellcheck: true,
            height: 'calc(60vh)',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: false,
            showToolbar: false,
            defaultParagraphSeparator: '',
            defaultFontName: 'helvetica',
            defaultFontSize: '12px',
            fonts: [{class: 'helvetica', name: 'Helvetica'}],
            customClasses: [
                {
                    name: 'quote',
                    class: 'quote'
                },
                {
                    name: 'redText',
                    class: 'redText'
                },
                {
                    name: 'titleText',
                    class: 'titleText',
                    tag: 'h1'
                }
            ],
            sanitize: true,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [[], ['link', 'unlink', 'insertImage', 'insertVideo']]
        };

        switch (this.tabIndex) {
            case 0:
                editorConfig.placeholder = 'Cover Letter text';
                break;
            case 1:
                editorConfig.placeholder = 'Terms & Conditions text';
                break;
        }

        return editorConfig;
    }
}
