<div class="templates m-t-30">

    <div *ngIf="templates.length === 0"
         class="text-center">
        No areas to select
    </div>

    <div class="show-for-lg">
        <div *ngFor="let row of rowArray"
             class="row">
            <div *ngFor="let template of (templates | slice: (row * 4): (row * 4 + 4)); trackBy: trackId; let i = index"
                 class="col-lg-3">
                <bp-schedule-template-card
                    [template]="template">
                </bp-schedule-template-card>
            </div>
        </div>
    </div>

    <div class="show-for-tablets-hor">
        <div *ngFor="let row of rowArray"
             class="row">
            <div *ngFor="let template of (templates | slice: (row * 3): (row * 3 + 3)); trackBy: trackId; let i = index"
                 class="col-sm-4">
                <bp-schedule-template-card
                    [template]="template">
                </bp-schedule-template-card>
            </div>
        </div>
    </div>

    <div class="show-for-tablets-ver">
        <div *ngFor="let row of rowArray"
             class="row">
            <div *ngFor="let template of (templates | slice: (row * 2): (row * 2 + 2)); trackBy: trackId; let i = index"
                 class="col-sm-6">
                <bp-schedule-template-card
                    [template]="template">
                </bp-schedule-template-card>
            </div>
        </div>
    </div>
</div>
