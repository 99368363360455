<form (ngSubmit)="addElement()"
      name="addElementForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Select Element to Add
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <bp-select-input
            (onSelectionChange)="onElementSelectionChange($event)"
            *ngIf="elementSelectInputData"
            [data]="elementSelectInputData"
            class="select-element-id">
        </bp-select-input>
    </div>
    <div class="modal-footer">
        <button (click)="clear()"
                class="btn btn-inverted cancel"
                data-dismiss="modal"
                type="button">
            Cancel
        </button>
        <button [disabled]="!selectedElement"
                class="btn btn-primary btn-border-radius waves-effect add-element"
                type="submit">
            Add Element
        </button>
    </div>
</form>
