<div class="freemium-model">
    <div class="modal-header flex flex-column gap-15 align-items-center">
        <img class="freemium-model__builder"
             height="100px"
             src="../../../../../content/images/builder.png"/>
        <h4 class="freemium-model__modal-title" [innerHTML]="title">
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>

    <div class="modal-body flex flex-column align-items-center gap-20 text-center">
        <ng-container [ngSwitch]="type">
            <div *ngSwitchCase="'type_create_project'">
                Create your next project by trying a paid plan.
            </div>
            <div *ngSwitchCase="'type_create_project_monthly'"
                 class="gray-text">
                Great that you're finding it useful. If you want to start more projects you'll need to upgrade to a
                higher tiered plan.
            </div>
            <div *ngSwitchCase="'type_delete_project'">
                Create your next project by trying a paid plan.
            </div>
            <div *ngSwitchCase="'type_export'">
                <div>
                    Download your custom export by trying a paid plan.
                </div>
                <div>
                    <a [href]="PDF_REPORT_EXAMPLE_LINK" target="_blank" class="freemium-model__link">PDF</a>, <a
                    [href]="EXCEL_REPORT_EXAMPLE_LINK"
                    target="_blank" class="freemium-model__link">Excel</a> & <a
                    [href]="WORD_REPORT_EXAMPLE_LINK" target="_blank" class="freemium-model__link">Word</a> sample here.
                </div>
            </div>
            <div *ngSwitchCase="'type_upload_and_auto_calculate'">
                <div>
                    This feature allows you to upload your floorplans to effortlessly receive populated areas and
                    dimensions of your project.
                </div>
            </div>
            <ng-container *ngSwitchCase="'type_request_template'">
            </ng-container>
        </ng-container>

        <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'type_create_project_monthly'">
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ul class="freemium-model__list gray-text">
                    <li><span>Create quick cost plans.</span></li>
                    <li><span>Add detail to schedules.</span></li>
                    <li><span>Collect comparable quotes.</span></li>
                    <li><span>Access QS Services.</span></li>
                </ul>
            </ng-container>
        </ng-container>
    </div>

    <div class="modal-footer">
        <div class="flex justify-content-center flex flex-column gap-2 w-full">
            <ng-container [ngSwitch]="type">
                <ng-container *ngSwitchCase="'type_create_project_monthly'">
                    <button (click)="onOkClick()"
                            tabindex="0"
                            class="btn btn-primary btn-border-radius waves-effect freemium-model__ok"
                            type="button">
                        Upgrade now
                    </button>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <button (click)="onOkClick()"
                            tabindex="0"
                            class="btn btn-primary btn-border-radius waves-effect freemium-model__ok"
                            type="button">
                        View plans
                    </button>
                    <div class="text-center p-b-10">
                        50% off your first month. Cancel Anytime.
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
