import { Component, Input, OnInit } from '@angular/core';
import { IStage } from 'app/shared/model/stage.model';
import { IElement } from 'app/shared/model/element.model';
import { SearchBySelectInputData } from 'app/shared/components/common/search-by-select/search-by-select.component';
import { IAddScheduleTaskRequest } from 'app/shared/constants/events.constants';
import { ITask } from 'app/shared/model/task.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { INewTaskRequestCreationDTO } from 'app/shared/model/new-task-request.model';
import { NewTaskRequestApi } from 'app/shared/dataservices/new-task-request.api';
import { HttpResponse } from '@angular/common/http';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import {
    TaskRequestModalService
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { Subject } from 'rxjs';
import { TaskApi } from 'app/shared/dataservices/task.api';

@Component({
    selector: 'bp-add-schedule-task-for-filter-by-area-grouping',
    templateUrl: './add-schedule-task.component.html',
    styleUrls: ['../../root-container/schedule-task.scss']
})
export class AddScheduleTaskForFilterByAreaGroupingComponent implements OnInit {
    @Input() stage: IStage;
    @Input() area: IScheduleArea;
    @Input() element: IElement;

    protected taskSearchBySelectInputData: SearchBySelectInputData;

    constructor(
        public scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        private taskApi: TaskApi,
        private newTaskRequestApi: NewTaskRequestApi,
        private taskRequestModalService: TaskRequestModalService
    ) {
    }

    ngOnInit(): void {
        this.fillTaskSelectInputData();
    }

    protected onTaskSelectionChange(task: ITask | null): void {
        if (!task) {
            return;
        }

        const stage = this.scheduleService.allStages.find(stage => stage.id === task.stageId);
        let areaStage = this.area.stages.find((s) => s.id === stage.id);
        if (!areaStage) {
            this.area.stages.push(Object.assign({}, stage));
            areaStage = this.area.stages.find((s) => s.id === stage.id);
        }
        const element = stage.elements.find(el => el.id === task.elementId);

        task.otherStage = true;

        const request: IAddScheduleTaskRequest = {
            area: this.area,
            stage: areaStage,
            element: element,
            task
        };

        this.scheduleEventsService.addScheduleTask(request);

        this.taskSearchBySelectInputData.clearEventEmitter.next();
    }

    private fillTaskSelectInputData(): void {
        this.taskSearchBySelectInputData = {
            searchBy: 'task',
            indexProperty: 'id',
            titleProperty: 'task',
            displayProperty: 'task',
            itemService: this.taskApi,
            itemServiceMethod: 'queryForGroupAdding',
            dropdownPosition: 'bottom',
            additionalQueryPathParams: { stageId: this.stage.id, elementId: this.element ? this.element.id : null, elements: this.element ? this.stage.elements : [] },
            suggestionsField: 'otherStage',
            clearEventEmitter: new Subject<void>(),
            placeholder: 'Add task',
            placeholderInFocusedState: 'Type to search',
            onEnterClickHandler: this.onTaskSelectInputEnterClick.bind(this),
            doNotSort: true
        };
    }

    private onTaskSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length > 0) {
            const data: INewTaskRequestCreationDTO = {
                name: currentTextValue,
                projectId: this.scheduleService.project.id,
                stageId: this.stage.id,
                scheduleareaId: this.area.id,
                unitValue: 1
            };

            if (this.element?.id >= 0) {
                data.elementId = this.element?.id;
            }

            this.taskRequestModalService.open(data).result.then((updatedData: INewTaskRequestCreationDTO) => {
                this.newTaskRequestApi.newTask(updatedData).subscribe((res: HttpResponse<ITask>) => {
                    this.onTaskSelectionChange(res.body);
                });
            })
        }
    }
}
