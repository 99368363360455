import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { ProjectAttachmentsService } from 'app/shared/dataservices/project-attachments.service';
import {
    IProjectAttachment,
    IProjectAttachmentAdditionProperties,
    ProjectAttachmentService
} from 'app/shared/model/project-attachment.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IProject } from 'app/shared/model/project.model';
import { lastValueFrom } from 'rxjs';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'submit-for-analysis-modal',
    templateUrl: './submit-for-analysis-modal.component.html',
    styleUrls: ['submit-for-analysis-modal.scss']
})
export class SubmitForAnalysisModalComponent {
    @BlockUI() blockUI: NgBlockUI;

    protected serviceTypeControl = new FormControl(null);
    protected existingFloorPlanURLControl = new FormControl(null);
    protected proposedFloorPlanURLControl = new FormControl(null);

    protected existingFloorPlanFiles: File[] = [];
    protected proposedFloorPlanFiles: File[] = [];
    protected projectAttachments: IProjectAttachment[] = [];

    protected serviceTypes: { value: ProjectAttachmentService, label: string }[] = [
        {
            value: 'takeoff',
            label: 'Takeoff - Free for the first project, then £20 - £40 per floor'
        },
        {
            value: 'feasibility_study',
            label: 'Feasibility study - 0.1% of project value',
        },
        {
            value: 'specified_schedule',
            label: 'Specified schedule - 0.2% of project value'
        }
    ];

    constructor(
        public activeModal: NgbActiveModal,
        private appStateService: ApplicationStateService,
        private projectAttachmentsService: ProjectAttachmentsService,
    ) {
    }

    protected get existingFloorPlanAttachments(): IProjectAttachment[] {
        return this.projectAttachments?.filter(pa => pa.fileName?.length && pa.additionalProperties.subtype === 'existing');
    }

    protected get proposedFloorPlanAttachments(): IProjectAttachment[] {
        return this.projectAttachments?.filter(pa => pa.fileName?.length && pa.additionalProperties.subtype === 'proposed');
    }

    protected get submitEnabled(): boolean {
        return this.serviceTypeControl.valid && (
            this.projectAttachments?.length ||
            this.existingFloorPlanURLControl.value?.length ||
            this.proposedFloorPlanURLControl.value?.length ||
            this.existingFloorPlanFiles.length ||
            this.proposedFloorPlanFiles.length
        );
    }

    protected get project(): IProject {
        return this.appStateService.project;
    }

    protected restore(projectAttachments: IProjectAttachment[]): void {
        this.projectAttachments = projectAttachments;

        projectAttachments.forEach((attachment) => {
            if (attachment.service) {
                this.serviceTypeControl.setValue(attachment.service);
            }

            if (attachment.additionalProperties.url?.length) {
                switch (attachment.additionalProperties.subtype) {
                    case 'existing':
                        this.existingFloorPlanURLControl.setValue(attachment.additionalProperties.url);
                        break;
                    case 'proposed':
                        this.proposedFloorPlanURLControl.setValue(attachment.additionalProperties.url);
                        break;
                }
            }
        })
    }

    protected onOkClick(): void {
        const removePromises = [];

        this.projectAttachments?.forEach((attachment) => {
            if (attachment.additionalProperties.url?.length) {
                switch (attachment.additionalProperties.subtype) {
                    case 'existing':
                        removePromises.push(lastValueFrom(this.projectAttachmentsService.delete(this.project.id, attachment.id)));
                        break;
                    case 'proposed':
                        removePromises.push(lastValueFrom(this.projectAttachmentsService.delete(this.project.id, attachment.id)));
                        break;
                }
            }
        });

        const createAttachments = () => {
            const promises = [];

            this.proposedFloorPlanFiles.forEach((file) => {
                const additionalProperties: IProjectAttachmentAdditionProperties = {
                    'subtype': 'proposed',
                    'url': this.proposedFloorPlanURLControl.value || ''
                };

                const promise = lastValueFrom(this.projectAttachmentsService.upload(
                    this.project.id,
                    file,
                    'FLOOR_PLAN',
                    promises.length === 0,
                    true,
                    this.serviceTypeControl.value,
                    additionalProperties
                ));

                promises.push(promise);
            });

            if (this.proposedFloorPlanURLControl.value?.length) {
                const additionalProperties: IProjectAttachmentAdditionProperties = {
                    'subtype': 'proposed',
                    'url': this.proposedFloorPlanURLControl.value || ''
                };

                const promise = lastValueFrom(this.projectAttachmentsService.upload(
                    this.project.id,
                    null,
                    'FLOOR_PLAN',
                    promises.length === 0,
                    true,
                    this.serviceTypeControl.value,
                    additionalProperties
                ));

                promises.push(promise);
            }

            this.existingFloorPlanFiles.forEach((file) => {
                const additionalProperties: IProjectAttachmentAdditionProperties = {
                    'subtype': 'existing'
                };

                const promise = lastValueFrom(this.projectAttachmentsService.upload(
                    this.project.id,
                    file,
                    'FLOOR_PLAN',
                    promises.length === 0,
                    true,
                    this.serviceTypeControl.value,
                    additionalProperties
                ));

                promises.push(promise);
            });

            if (this.existingFloorPlanURLControl.value?.length) {
                const additionalProperties: IProjectAttachmentAdditionProperties = {
                    'subtype': 'existing',
                    'url': this.existingFloorPlanURLControl.value || ''
                };

                const promise = lastValueFrom(this.projectAttachmentsService.upload(
                    this.project.id,
                    null,
                    'FLOOR_PLAN',
                    promises.length === 0,
                    true,
                    this.serviceTypeControl.value,
                    additionalProperties
                ));

                promises.push(promise);
            }

            if (promises.length) {
                this.blockUI.start('Please wait..');

                Promise.all(promises).then(() => {
                    this.activeModal.close(true);
                }).finally(() => {
                    this.blockUI.stop();
                })
            } else {
                this.activeModal.close(false);
            }
        }

        if (removePromises.length) {
            this.blockUI.start('Please wait..');

            Promise.all(removePromises).then(() => {
                createAttachments();
            }).finally(() => {
                this.blockUI.stop();
            })
        } else {
            createAttachments();
        }
    }

    protected close(): void {
        this.activeModal.close(false);
    }
}
