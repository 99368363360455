import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEX_0_AT_START_POSITION, REGEXP_DECIMAL, REGEXP_PERCENTS_0_100 } from 'app/shared/constants/patterns';
import { notAllowedValidator } from 'app/shared/validators/not-allowed.validator';
import { IProject } from 'app/shared/model/project.model';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IQuterProjectSpecificData, QuoterApi } from 'app/shared/dataservices/quoter.api';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { forkJoin, lastValueFrom } from 'rxjs';
import { BuilderMaterial, IBuilderMaterial } from 'app/shared/model/builder-material.model';
import { IBuilderLabour } from 'app/shared/model/builder-labour.model';

@Component({
    selector: 'bp-material-rates-modal',
    templateUrl: './material-rates-modal.component.html',
    styleUrls: ['material-rates-modal.scss']
})
export class MaterialRatesModalComponent {
    @BlockUI() blockUI: NgBlockUI;

    protected applyToAllWasClicked = false;
    protected handlingChargeWasClicked = false;

    protected applyToOldInitialValue = -1;

    protected applyToAllMarginControl = new FormControl(0, [
        Validators.required,
        Validators.pattern(REGEXP_PERCENTS_0_100),
        Validators.pattern(REGEXP_DECIMAL)
    ]);

    protected handlingChargeControl = new FormControl(0, [
        Validators.required,
        Validators.pattern(REGEXP_PERCENTS_0_100),
        Validators.pattern(REGEXP_DECIMAL)
    ]);

    protected project: IProject;
    protected materials: BuilderMaterial[] = [];

    protected form!: FormGroup;

    constructor(private activeModal: NgbActiveModal,
                private alertService: BpAlertService,
                private fb: FormBuilder,
                private quoterService: QuoterApi) {
    }

    get materialForms(): FormArray {
        return this.form.controls.materials as FormArray;
    }

    ngOnInit(): void {
        this.blockUI.start('Loading data..');

        this.applyToAllWasClicked = false;
        this.handlingChargeWasClicked = false;

        forkJoin([
            this.quoterService.getProjectSpecificData(this.project.id),
            this.quoterService.queryProjectSpecificMaterials(this.project.id)
        ]).pipe(
            finalize(() => {
                this.blockUI.stop();
            })
        ).subscribe((res: [HttpResponse<IQuterProjectSpecificData>, HttpResponse<IBuilderLabour[]>]) => {
            this.applyToOldInitialValue = Math.round(res[0].body.materialMargin * 100 * 100) / 100;
            this.applyToAllMarginControl.setValue(this.applyToOldInitialValue);
            this.handlingChargeControl.setValue(Math.round(res[0].body.materialHandlingCharges * 100 * 100) / 100);
            this.materials = res[1].body.map(bl => new BuilderMaterial(bl));
            this.initForm();
        })
    };

    protected applyMarginToAll(): void {
        const newMargin = +this.applyToAllMarginControl.value;

        for (let index = 0; index < this.materials.length; index++) {
            this.materials[index].margin = newMargin;
            this.materials[index].updated = false;
            this.updateMaterialForm(index, this.materials[index]);
        }

        this.applyToAllWasClicked = true;
    }

    protected close(): void {
        this.activeModal.close();
    }

    protected save(): void {
        this.blockUI.start('Saving data..');

        const saveRowsAndClose = () => {
            this.saveRows().finally(() => {
                this.blockUI.stop();
            }).then(() => {
                this.alertService.success('Materials were successfully updated');
                this.activeModal.close();
            })
        }

        if (this.applyToAllWasClicked || this.applyToOldInitialValue !== this.applyToAllMarginControl.value) {
            this.saveMargin().then(() => {
                if (this.handlingChargeWasClicked) {
                    this.saveHandlingCharge().then(() => {
                        saveRowsAndClose();
                    })
                } else {
                    saveRowsAndClose();
                }
            })
        } else {
            if (this.handlingChargeWasClicked) {
                this.saveHandlingCharge().then(() => {
                    saveRowsAndClose();
                })
            } else {
                saveRowsAndClose();
            }
        }
    }

    protected isSaveDisabled(): boolean {
        return !this.applyToAllWasClicked && (this.applyToOldInitialValue === this.applyToAllMarginControl.value) && !this.handlingChargeWasClicked && !this.materials.filter(m => m.updated).length;
    }

    protected materialForm(index: number): FormGroup {
        return this.materialForms?.at(index) as FormGroup;
    }

    protected onCostInputBlur(event: any, index: number): void {
        const labourForm = this.materialForm(index);
        if (labourForm.controls.cost.invalid) {
            return;
        }

        this.materials[index].cost = labourForm.controls.cost.value;
        this.updateMaterialForm(index, this.materials[index]);
    }

    protected onMarginInputBlur(event: any, index: number): void {
        const materialForm = this.materialForm(index);
        if (materialForm.controls.margin.invalid) {
            return;
        }

        this.materials[index].margin = materialForm.controls.margin.value;
        this.updateMaterialForm(index, this.materials[index]);
    }

    protected onRateInputBlur(event: any, index: number): void {
        const materialForm = this.materialForm(index);
        if (materialForm.controls.rate.invalid) {
            return;
        }

        this.materials[index].rate = materialForm.controls.rate.value;
        this.updateMaterialForm(index, this.materials[index]);
    }

    private updateMaterialForm(index: number, material: BuilderMaterial) {
        const materialForm = this.materialForm(index);

        materialForm.controls.cost.setValue(material.cost);
        materialForm.controls.margin.setValue(material.margin);
        materialForm.controls.rate.setValue(material.rate);
    }

    private initForm(): void {
        this.form = this.fb.group({
            materials: this.fb.array([])
        });

        this.materials.forEach((material: IBuilderMaterial) => {
            const materialForm = this.fb.group({
                cost: [material.cost, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
                margin: [material.margin, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
                rate: [material.rate, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
            });

            this.materialForms.push(materialForm);
        })
    }

    private saveMargin(): Promise<HttpResponse<IQuterProjectSpecificData>> {
        const data: IQuterProjectSpecificData = {
            materialMargin: +this.applyToAllMarginControl.value / 100
        };

        return lastValueFrom(this.quoterService.updateProjectSpecificMaterial(this.project.id, data));
    }

    private saveHandlingCharge(): Promise<HttpResponse<IQuterProjectSpecificData>> {
        const data: IQuterProjectSpecificData = {
            materialHandlingCharges: +this.handlingChargeControl.value / 100
        };

        return lastValueFrom(this.quoterService.updateProjectSpecificMaterial(this.project.id, data));
    }

    private saveRows(): Promise<void> {
        const updatedMaterials = this.materials.filter(m => m.updated);
        const subscription = updatedMaterials.map(m => this.quoterService.updateProjectSpecificMaterials(this.project.id, m.endpointData()))

        if (subscription.length) {
            return new Promise(resolve => {
                forkJoin(subscription).subscribe(() => {
                    resolve();
                })
            });
        } else {
            return Promise.resolve();
        }
    }
}
