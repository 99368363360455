<div *ngIf="!dashboardStore.inProcess && valueColumns && appState.data"
     [ngClass]="{'use-in-tile': useInTile }">
    <div class="w-full flex flex-row gap-20 relative justify-content-center comparison-view">
        <div class="_items-container right-width flex flex-column gap-20">
            <div
                *ngFor="let item of dashboardStore.items?.slice(0, viewMoreMode ? VIEW_MORE_LIMIT : dashboardStore.items?.length)"
                class="cost-data-container flex flex-row w-full"
                [ngClass]="{'checked': itemChecked(item)}">
                <div class="form-check clear text-left"
                     style="width: 30px;">
                    <label class="form-check-label"
                           for="item_check_{{item.id}}">
                        <input [checked]="itemChecked(item)"
                               [disabled]="dashboardStore.inProcess"
                               (click)="onCheckboxClick(item)"
                               [value]="item.id"
                               class="form-check-input"
                               id="item_check_{{item.id}}"
                               name="item_check_{{item.id}}"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>

                <div class="text-left item-title"
                     [title]="item.title">
                    <span>{{ item.title }}</span>
                </div>
            </div>

            <div class="view-more-container"
                 *ngIf="dashboardStore.items?.length > VIEW_MORE_LIMIT">
                <div class="right-width">
                    <button (click)="viewMoreMode = !viewMoreMode"
                            class="btn btn-link">
                            <span *ngIf="viewMoreMode">View More <fa-icon
                                [icon]="['fas', 'arrow-down']"></fa-icon></span>
                        <span *ngIf="!viewMoreMode">View Less <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="flex justify-content-between">
            <div *ngFor="let column of valueColumns"
                 class="_value-column"
                 [ngClass]="{'selected': dashboardService.isQuoterDefault(column.quoter)}">

                <div class="_column-label b-font bp-cursor-pointer m-b-10"
                     [ngbTooltip]="getTooltip(column.quoter)"
                     (click)="selectDefaultQuoter(column.quoter)">
                    {{ column.quoter?.company || column.quoter?.email }}
                </div>

                <div class="_column-data flex flex-column gap-20">
                    <div *ngFor="let item of dashboardStore.items?.slice(0, viewMoreMode ? VIEW_MORE_LIMIT : dashboardStore.items?.length)"
                        class="height-24">
                        <span>{{ getValue(column, item) | currency:'GBP' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="cost-data-total-container flex justify-content-center flex-row gap-20 m-t-20 {{ dashboardStore.dashboardMode }}"
        *ngIf="valueColumns">
        <div class="text-left right-width p-t-10">
            <span class="b-font">Total</span>
        </div>
        <div class="flex justify-content-between">
            <div *ngFor="let column of valueColumns"
                 class="_value-column"
                 [ngClass]="{'selected': dashboardService.isQuoterDefault(column.quoter)}">
                <div class="_column-label b-font">
                    {{ column.quoterTotal || 0 | currency:'GBP' }}
                </div>
                <div class="ex-vat">Ex. VAT</div>

                <div class="m-t-10"
                     *ngIf="dashboardStore.quoters?.length > 1 && (appState.project.isOwner || ['OWNER', 'EDIT'].indexOf(appState.project.privilege) !== -1);">
                    <input [checked]="dashboardService.isQuoterDefault(column.quoter)"
                           [disabled]="dashboardStore.inProcess"
                           (click)="selectDefaultQuoter(column.quoter)"
                           class="form-check-input m-0"
                           id="item_check_default_quoter_{{column.quoter.id}"
                           name="item_check_default_quoter_{{column.quoter.id}"
                           type="checkbox">
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dashboardStore.inProcess"
     class="_value-column align-items-center justify-content-between m-t-40">
    <bp-spinner class="cost-plan-spinner">
    </bp-spinner>
</div>
