import { Component, Input } from '@angular/core';

@Component({
    selector: 'bp-labeled-value',
    templateUrl: './labeled-value.component.html',
    styleUrls: ['labeled-value.css']
})
export class LabeledValueComponent {
    @Input() label;
    @Input() value;
    @Input() type: 'text' | 'email' = 'text';
}
