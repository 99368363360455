<div (bpClickOutside)="onClickOutside()"
     class="bp-area-container">
    <a (click)="openAreaDropdown()"
       class="bp-area-dropdown">
        <span class="m-r-10">{{ selectedScheduleArea ? selectedScheduleArea.area : 'All Areas' }}<i
            class="material-icons">unfold_more</i></span>
    </a>
    <div *ngIf="areaDropDown"
         class="bp-dropdown-items-container">
        <a (click)="selectArea(area)"
           *ngFor="let area of getAreasToShow()"
           [ngClass]="[(area.id === -1) ? 'reset' : '', (selectedScheduleArea && selectedScheduleArea.id === area.id) ? 'selected' : '']"
           class="bp-dropdown-item">
            {{ area.area }}
        </a>
    </div>
</div>
