import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import {
    IndividualQuoteExpandAreaGroupStorageService
} from 'app/flows/scheduler/individual-quote/services/expand-area-group-storage.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';

@Component({
    selector: 'bp-individual-quote-stages',
    templateUrl: './stages.component.html',
    styleUrls: ['stages.scss']
})
export class IndividualQuoteStagesComponent {
    @Input() project: IProject;
    @Input() nativeScheduleAreas: IScheduleArea[];

    @Input() comparison: IComparison;
    @Input() quoter: IComparisonQoter;

    @Input() filterState: IMainViewFilterState;
    @Input() scheduleAreaItems: IMainViewFilterItemItem[];
    @Input() stageItems: IMainViewFilterItemItem[];

    @Output() onMainViewFilterChanged = new EventEmitter();

    constructor(private expandAreaGroupStorageService: IndividualQuoteExpandAreaGroupStorageService) {}

    onMainViewFilterChangedFunc(event) {
        this.onMainViewFilterChanged?.emit(event);
    }

    onClosedAreaIconClick(area: IScheduleArea) {
        this.expandArea(area);
    }

    onOpenedAreaIconClick(area: IScheduleArea) {
        this.collapseArea(area);
    }

    private expandArea(area: IScheduleArea) {
        area.expanded = true;
        this.expandAreaGroupStorageService.store(this.project, this.quoter.id, area, true);
    }

    private collapseArea(area: IScheduleArea) {
        area.expanded = false;
        this.expandAreaGroupStorageService.store(this.project, this.quoter.id, area, false);
    }
}
