import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IQuterProjectSpecificData, QuoterApi } from 'app/shared/dataservices/quoter.api';
import { HttpResponse } from '@angular/common/http';
import { BuilderLabour, IBuilderLabour } from 'app/shared/model/builder-labour.model';
import { IProject } from 'app/shared/model/project.model';
import { finalize } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEX_0_AT_START_POSITION, REGEXP_DECIMAL, REGEXP_PERCENTS_0_100 } from 'app/shared/constants/patterns';
import { notAllowedValidator } from 'app/shared/validators/not-allowed.validator';
import { forkJoin, lastValueFrom } from 'rxjs';
import { BpAlertService } from 'app/shared/services/bp-alert.service';

@Component({
    selector: 'bp-labour-rates-modal',
    templateUrl: './labour-rates-modal.component.html',
    styleUrls: ['labour-rates-modal.scss']
})
export class LabourRatesModalComponent {
    @BlockUI() blockUI: NgBlockUI;

    protected applyToAllWasClicked = false;
    protected applyToAllMarginControl = new FormControl(0, [
        Validators.required,
        Validators.pattern(REGEXP_PERCENTS_0_100),
        Validators.pattern(REGEXP_DECIMAL)
    ]);

    protected applyToOldInitialValue = -1;

    protected project: IProject;
    protected labours: BuilderLabour[] = [];

    protected form!: FormGroup;

    get labourForms(): FormArray {
        return this.form.controls.labours as FormArray;
    }

    constructor(private activeModal: NgbActiveModal,
                private alertService: BpAlertService,
                private fb: FormBuilder,
                private quoterService: QuoterApi) {
    }

    ngOnInit(): void {
        this.blockUI.start('Loading data..');

        this.applyToAllWasClicked = false;

        forkJoin([
            this.quoterService.getProjectSpecificData(this.project.id),
            this.quoterService.queryProjectSpecificLabours(this.project.id)
        ]).pipe(
            finalize(() => {
                this.blockUI.stop();
            })
        ).subscribe((res: [HttpResponse<IQuterProjectSpecificData>, HttpResponse<IBuilderLabour[]>]) => {
            this.applyToOldInitialValue = Math.round(res[0].body.labourMargin * 100 * 100) / 100;
            this.applyToAllMarginControl.setValue(this.applyToOldInitialValue);
            this.labours = res[1].body.map(bl => new BuilderLabour(bl));
            this.initForm();
        })
    };

    protected applyMarginToAll(): void {
        const newMargin = +this.applyToAllMarginControl.value;

        for (let index = 0; index < this.labours.length; index++) {
            this.labours[index].margin = newMargin;
            this.labours[index].updated = false;
            this.updateLabourForm(index, this.labours[index]);
        }

        this.applyToAllWasClicked = true;
    }

    protected close(): void {
        this.activeModal.close();
    }

    protected save(): void {
        this.blockUI.start('Saving data..');

        const saveRowsAndClose = () => {
            this.saveRows().finally(() => {
                this.blockUI.stop();
            }).then(() => {
                this.alertService.success('Labour rates successfully updated');
                this.activeModal.close();
            })
        }

        if (this.applyToAllWasClicked || this.applyToOldInitialValue !== this.applyToAllMarginControl.value) {
            this.saveMarginForAll().then(() => {
                saveRowsAndClose();
            })
        } else {
            saveRowsAndClose();
        }
    }

    protected labourForm(index: number): FormGroup {
        return this.labourForms?.at(index) as FormGroup;
    }

    protected onCostPerHourInputBlur(event: any, index: number): void {
        const labourForm = this.labourForm(index);
        if (labourForm.controls.costPerHour.invalid) {
            return;
        }

        this.labours[index].cost = labourForm.controls.costPerHour.value;
        this.updateLabourForm(index, this.labours[index]);
    }

    protected onCostPerDayInputBlur(event: any, index: number): void {
        const labourForm = this.labourForm(index);
        if (labourForm.controls.costPerDay.invalid) {
            return;
        }

        this.labours[index].costPerDay = labourForm.controls.costPerDay.value;
        this.updateLabourForm(index, this.labours[index]);
    }

    protected onRatePerDayInputBlur(event: any, index: number): void {
        const labourForm = this.labourForm(index);
        if (labourForm.controls.ratePerDay.invalid) {
            return;
        }

        this.labours[index].ratePerDay = labourForm.controls.ratePerDay.value;
        this.updateLabourForm(index, this.labours[index]);
    }

    protected onMarginInputBlur(event: any, index: number): void {
        const labourForm = this.labourForm(index);
        if (labourForm.controls.margin.invalid) {
            return;
        }

        this.labours[index].margin = labourForm.controls.margin.value;
        this.updateLabourForm(index, this.labours[index]);
    }

    private updateLabourForm(index: number, labour: BuilderLabour) {
        const labourForm = this.labourForm(index);

        labourForm.controls.costPerHour.setValue(labour.cost);
        labourForm.controls.costPerDay.setValue(labour.costPerDay);
        labourForm.controls.ratePerHour.setValue(labour.rate);
        labourForm.controls.ratePerDay.setValue(labour.ratePerDay);
        labourForm.controls.margin.setValue(labour.margin);
    }

    private initForm(): void {
        this.form = this.fb.group({
            labours: this.fb.array([])
        });

        this.labours.forEach((labour: IBuilderLabour) => {
            const labourForm = this.fb.group({
                costPerHour: [labour.cost, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
                costPerDay: [labour.costPerDay, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
                ratePerHour: [labour.rate, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
                ratePerDay: [labour.ratePerDay, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
                margin: [labour.margin, [Validators.required, Validators.pattern(REGEXP_DECIMAL), notAllowedValidator(REGEX_0_AT_START_POSITION)]],
            });

            this.labourForms.push(labourForm);
        })
    }

    private saveRows(): Promise<void> {
        const updatedLabours = this.labours; //.filter(l => l.updated);
        const subscription = updatedLabours.map(l => this.quoterService.updateProjectSpecificLabours(this.project.id, l.endpointData()));

        if (subscription.length) {
            return new Promise(resolve => {
                forkJoin(subscription).subscribe(() => {
                    resolve();
                })
            });
        } else {
            return Promise.resolve();
        }
    }

    private saveMarginForAll(): Promise<HttpResponse<IQuterProjectSpecificData>> {
        const data: IQuterProjectSpecificData = {
            labourMargin: +this.applyToAllMarginControl.value / 100
        };

        return lastValueFrom(this.quoterService.updateProjectSpecificMaterial(this.project.id, data));
    }
}
