import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ReviewByQsModalComponent
} from 'app/flows/scheduler/components/review-by-qs-modal/review-by-qs-modal.component';
import { IEstimatingAddon } from "app/shared/model/bp.model";
import { EstimatingAddonService } from "app/shared/dataservices/estimating-addon.service";
import {
    ServiceSelectorModalService
} from "app/shared/components/projects/service-selector-modal/service-selector-modal.service";
import { Router } from "@angular/router";
import { ApplicationStateService } from "app/core/application-state.service";

@Injectable({ providedIn: "root" })
export class ReviewByQsModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal,
                private estimatingAddonService: EstimatingAddonService,
                private serviceSelectorModalService: ServiceSelectorModalService,
                private appStateService: ApplicationStateService,
                private router: Router) {
    }

    open(): NgbModalRef {
        this.ngbModalRef = this.modalService.open(ReviewByQsModalComponent as Component, {
            windowClass: "bp-modal review-by-qs",
            backdrop: "static"
        });

        return this.ngbModalRef;
    }

    openNew(): void {
        this.estimatingAddonService.queryAllServices().then(addons => {
            this.serviceSelectorModalService.open(addons).result.then(
                (res: { addon: IEstimatingAddon }) => {
                    this.appStateService.resetProject();
                    this.router.navigate(
                        ['scheduler', 'new-project'],
                        { queryParams: { addonId: res.addon.id } }
                    )
                })
        })
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
