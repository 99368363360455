import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { TemplateWizardData } from 'app/flows/scheduler/template-wizard/template-wizard.data';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { TagService } from 'app/shared/dataservices/tag.service';
import { ScheduleService } from 'app/shared/dataservices/schedule.service';
import { HttpResponse } from '@angular/common/http';
import { IProject } from 'app/shared/model/project.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { ITag } from 'app/shared/model/tag.model';
import { AccountService } from 'app/core/auth/account.service';
import { TemplateWizardStore } from 'app/flows/scheduler/template-wizard/template-wizard.store';
import { TemplateWizardService } from 'app/flows/scheduler/template-wizard/template-wizard.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CreateProjectService } from 'app/flows/scheduler/services/create-project.service';
import { ApplicationStateService } from "app/core/application-state.service";
import { finalize } from "rxjs/operators";

const FIRST_STEP = 1;

export type ReturnPage = 'cost_plan' | 'schedule';

/**
 * Template Wizard
 */
@Component({
    selector: 'bp-template-wizard',
    templateUrl: './template-wizard.component.html',
    styleUrls: ['template-wizard.scss']
})
export class TemplateWizardComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    @Input() project; /* for new project wizard */
    @Input() addonId: number | null = null;
    @Input() files: File[]; /* for new project wizard */

    returnPage: ReturnPage = 'schedule';

    currentStep = 1;

    inProcessSaveProject = false;
    inProcessAddingDefaultQuoters = false;

    @ViewChild('content') content: ElementRef;

    constructor(
        private service: TemplateWizardService,
        protected store: TemplateWizardStore,
        private appState: ApplicationStateService,
        private specificationService: SpecificationService,
        private tagService: TagService,
        private scheduleService: ScheduleService,
        private projectApi: ProjectApi,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private alertService: BpAlertService,
        private accountService: AccountService,
        private createProjectService: CreateProjectService
    ) {
        this.store.data = new TemplateWizardData(this.appState, this.specificationService, this.tagService, this.scheduleService);

        /* for adding areas from schedule page */
        this.activatedRoute.data.subscribe(data => {
            if (data.project) {
                this.store.data.project = data.project;
            }
        });

        this.activatedRoute.queryParams.subscribe(params => {
            if (params['returnPage']) {
                this.returnPage = params['returnPage'];
            }
        });
    }

    ngOnInit(): void {
        this.store.init();

        if (this.project != null) {
            this.store.data.project = this.project;
        }

        this.accountService.identity().then((account: any) => {
            this.store.data.account = account;
        });
    }

    onPreviousButtonClick(): void {
        if (this.currentStep <= FIRST_STEP) {
            return;
        }

        this.currentStep--;
        this.scrollToTop();
    }

    onNextButtonClick(): void {
        if (this.currentStep >= this.getLastStepNumber()) {
            return;
        }

        if (this.currentStep === 2) {
            this.store.data.loadTemplates().subscribe(() => {
                this.currentStep++;
                this.scrollToTop();
            });
        } else {
            this.currentStep++;
            this.scrollToTop();
        }
    }

    onFinishButtonClick(): void {
        if (this.store.data.project.id == null) {
            this.createNewProjectWithTemplates();
        } else {
            this.saveTemplates();
        }
    }

    isPreviousButtonVisible(): boolean {
        return this.currentStep > FIRST_STEP;
    }

    isNextButtonVisible(): boolean {
        return this.currentStep < this.getLastStepNumber();
    }

    isFinishButtonVisible(): boolean {
        return this.currentStep === this.getLastStepNumber();
    }

    isNextButtonDisabled(): boolean {
        if (this.inProcess()) {
            return true;
        }

        switch (this.currentStep) {
            case 2:
                return !this.store.data?.tags?.filter((t: ITag) => t.selected).length;
            default:
                return false;
        }
    }

    isFinishedButtonDisabled(): boolean {
        if (this.inProcess()) {
            return true;
        }

        switch (this.currentStep) {
            case 2:
                return !this.store.data?.tags?.filter((t: ITag) => t.selected).length;
            default:
                return false;
        }
    }

    inProcess(): boolean {
        return this.inProcessSaveProject || this.inProcessAddingDefaultQuoters;
    }

    getLastStepNumber(): number {
        return 2 + this.store.data.getAllNeededSubtags().length;
    }

    private scrollToTop(): void {
        window.scrollTo(0, 0);
        this.content.nativeElement.scrollTop = 0;
    }

    private saveTemplates(): void {
        this.blockUI.start('Please wait..');

        this.projectApi
            .applySchedulerTemplates(this.store.data.project.id, this.store.data.getTemplatesWithTags(), this.store.data.project.specification.id)
            .pipe(finalize(() => this.blockUI.stop()))
            .subscribe(
                (res: HttpResponse<IProject>) => {
                    this.service.sendTemplateRequestsBatchIfNeeded(this.store.data.project.id).then((templateRequestsWereSubmitted) => {
                        this.blockUI.stop();

                        this.router.navigate(['../../edit-schedule-areas', this.store.data.project.id], {
                            queryParams: {
                                returnPage: this.project ? 'cost_plan' : this.returnPage
                            },
                            relativeTo: this.activatedRoute
                        });
                        let message = 'Template(s) have been successfully applied.'
                        if (templateRequestsWereSubmitted) {
                            message += 'And template Request(s) have been successfully submitted.'
                        }
                        this.alertService.success(message);
                    });
                }
            );
    }

    private createNewProjectWithTemplates(): void {
        this.createProjectService.create(this.addonId, this.activatedRoute, this.project, this.files, this.store.data.getTemplatesWithTags());
    }

}
