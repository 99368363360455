import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    EstimatorsWorkingOnProjectModalComponent
} from 'app/shared/components/projects/estimators-working-on-project-modal/estimators-working-on-project-modal.component';
import { AuthServerProvider } from "app/core/auth/auth-jwt.service";
import { IProject } from "app/shared/model/project.model";

@Injectable({ providedIn: 'root' })
export class EstimatorsWorkingOnProjectModalService implements OnDestroy {

    constructor(private modalService: NgbModal,
                private _authServerProvider: AuthServerProvider) {
    }

    ngOnDestroy(): void {
    }

    needToShow(project: IProject): boolean {
        return Boolean(!this._authServerProvider.getProxyAdmin() && project.estimatingAddOn?.find(addOn => addOn.status === 'PENDING'));
    }

    showModal(): Promise<boolean> {
        return this.modalService.open(EstimatorsWorkingOnProjectModalComponent as Component, {
            windowClass: 'bp-modal estimators-working-on-project-modal',
            size: 'lg',
            backdrop: 'static'
        }).result.then((res: boolean) => {
            return res;
        });
    }
}
