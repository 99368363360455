<div *ngIf="timeline == null; else timelineDiv"
     class="spinner"
     title="In progress.. Please wait..">
</div>

<ng-template #timelineDiv>
    <div class="timeline">
        <div class="timeline__header w-full">
            <div class="flex align-items-center gap-20">
                <div class="flex-1">Average no. of tradespeople:</div>
                <input [formControl]="tradespeopleControl"
                       [maxlength]="5"
                       class="timeline__number_input"
                       oninput="onNumberInput(value)"
                       type="text"/>
            </div>
            <div>
                <button (click)="resetCustomDurations()"
                        *ngIf="showResetButton"
                        class="btn btn-primary btn-border-radius m-r-10"
                        ngbTooltip="Reset"
                        type="button">
                    Reset
                </button>
            </div>
        </div>

        <div class="flex flex-row timeline__area m-t-20 w-full"
             style="max-width: 100%;">
            <div class="flex flex-column">
                <div [style.height.px]="HEIGHT"
                     [style.min-height.px]="HEIGHT"
                     class="timeline__stage_header">
                    <span>Total Duration</span> <span>{{ timeline.totalWeeks }} wks</span>
                </div>
                <div class="timeline__stage_body overflow-y-auto">
                    <div *ngFor="let stageCtrl of stagesFormArray.controls; let i = index"
                         [formGroup]="stageCtrl"
                         [style.height.px]="HEIGHT"
                         [style.min-height.px]="HEIGHT"
                         class="timeline__stage">
                        <div class="flex justify-content-between align-items-center gap-20 w-full">
                            <div>
                                {{ stageCtrl.get('stage')?.value }}
                            </div>
                            <div *ngIf="store.dashboardMode != null">
                                <input (blur)="onBlurDaysControl(stageCtrl)"
                                       [maxlength]="5"
                                       class="timeline__number_input"
                                       formControlName="days"
                                       oninput="onNumberInput(value)"
                                       type="text"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="{{ overflowXClass }} overflow-y-auto"
                 id="timeline-area">
                <div class="flex flex-row">
                    <div *ngFor="let week of weeks"
                         [style.height.px]="HEIGHT"
                         [style.min-width.px]="WIDTH"
                         class="timeline__week-number">
                        {{ week + 1 }}
                    </div>
                </div>

                <div [style.height.px]="timeline.stages?.length * HEIGHT"
                     [style.width.px]="weeks?.length * WIDTH"
                     class="position-relative overflow-y-hidden">
                    <div *ngFor="let week of weeks; let index = index"
                         [ngClass]="{'gray': 0 === index % 2}"
                         [style.height.px]="timeline.stages?.length * HEIGHT"
                         [style.left.px]="week * WIDTH"
                         [style.min-width.px]="WIDTH"
                         [style.top.px]="0"
                         class="timeline__gray-line">
                    </div>

                    <div *ngFor="let stageCtrl of stagesFormArray.controls; let i = index"
                         [style.height.px]="HEIGHT / 2"
                         [style.left.px]="getLeft(i)"
                         [style.top.px]="getTop(i)"
                         [style.width.px]="stageCtrl.get('days')?.value / 5 * WIDTH"
                         class="timeline__rect"
                         title="{{ stageCtrl.get('days')?.value }} days or {{ stageCtrl.get('hours')?.value }} hours">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
