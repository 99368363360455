<div (bpClickOutside)="onClickOutside()"
     class="bp-area-container">
    <a (click)="openAreaDropdown()"
       class="bp-area-dropdown">
        <span class="m-r-10 displayed-value">{{ selectedScheduleArea?.area }}
            <i class="material-icons cursor-pointer">expand_more</i></span>
    </a>
    <div *ngIf="areaDropDown"
         class="bp-dropdown-items-container">
        <a (click)="selectArea(area)"
           *ngFor="let area of getAreasToShow()"
           [ngClass]="[selectedScheduleArea && selectedScheduleArea.id === area.id ? 'selected' : '']"
           class="bp-dropdown-item">
            {{ area.area }}
        </a>
    </div>
</div>
