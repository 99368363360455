import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQuoterTaskCost, IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IProject } from 'app/shared/model/project.model';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { IArea } from 'app/shared/model/area.model';
import { HttpResponse } from '@angular/common/http';
import { IMaterial } from 'app/shared/model/material.model';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';
import { AddMaterialModalService } from 'app/shared/components/schedule/add-material-modal/add-material-modal.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    DefaultUnitValueUpdaterService
} from 'app/flows/scheduler/schedule/services/default-unit-value-updater.service';
import { LoadMaterialsService } from 'app/flows/scheduler/services/load-materials.service';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { ApplicationStateService } from "app/core/application-state.service";
import { ScheduleAreasHelperService } from "app/shared/services/schedule-areas-helper.service";
import { QuoterDefaultPricesService } from "app/shared/dataservices/quoter-default-prices.service";
import { debounce } from "rxjs";
import { debounceTime, take } from "rxjs/operators";

@Component({
    selector: 'bp-edit-schedule-task-in-add-group',
    templateUrl: './edit-schedule-task-in-add-group.component.html',
    styleUrls: ['edit-schedule-task-in-add-group.scss']
})
export class EditScheduleTaskInAddGroupComponent implements OnInit {
    @Input() project: IProject;
    @Input() scheduleTask: IScheduleTask;
    @Input() scheduleAreas: IArea[];
    @Input() scheduleArea: IScheduleArea | null;

    @Output() onDelete = new EventEmitter<IScheduleTask>();

    protected scheduleAreaSelectInputData: SelectInputData;
    protected materialSelectInputData: SelectInputData;

    constructor(
        private _appState: ApplicationStateService,
        private _scheduleAreasHelperService: ScheduleAreasHelperService,
        private _quoterDefaultPricesService: QuoterDefaultPricesService,
        private materialCategoryService: MaterialCategoryService,
        private addMaterialModalService: AddMaterialModalService,
        private defaultUnitValueUpdaterService: DefaultUnitValueUpdaterService,
        private loadMaterialsService: LoadMaterialsService
    ) {
    }

    protected get materialCategory(): IMaterialCategory | null {
        return this.scheduleTask?.materialCategories?.length ? this.scheduleTask.materialCategories[0] : null;
    }

    ngOnInit(): void {
        this.fillMaterialCategory().then(() => {
            this.updateTaskTotal();
        });
        this.fillScheduleAreaSelectInputData();
    }

    protected updateTaskTotal(): void {
        this._quoterDefaultPricesService
            .queryTaskCost(
                this.project.defaultQuoter.id,
                this.scheduleTask.taskId,
                this.scheduleTask.ratio,
                this.scheduleTask.unitValue,
                this.scheduleTask.primeMaterialId,
                this.scheduleTask.id,
                !this.scheduleTask.clientSupplied
            ).pipe(take(1))
            .subscribe((res: HttpResponse<IQuoterTaskCost>) => {
                this.scheduleTask.taskTotal = res.body;
            });
    }

    protected onDeleteClick(): void {
        this.onDelete.emit(this.scheduleTask);
    }

    protected onMaterialSelectionChange(event: IMaterial): void {
        if (!event || event.id === this.scheduleTask.primeMaterialId) {
            return;
        }

        this.scheduleTask.primeMaterialId = event.id;
        this.scheduleTask.materialUrlRef = event.referenceUrl;

        this.defaultUnitValueUpdaterService.setDefaultUnitValue(this.scheduleTask).then(() => {
            this.updateTaskTotal();
        });
    }

    protected onAreaSelectionChange(event: IScheduleArea): void {
        this.scheduleTask.scheduleAreaId = event.id;
        this.defaultUnitValueUpdaterService.setDefaultUnitValue(this.scheduleTask, true).then(() => {
            this.updateTaskTotal();
        });
    }

    private fillMaterialCategory(): Promise<void> {
        return new Promise((resolve) => {
            const complete = () => {
                resolve();
                return;
            };

            if (this.materialCategory == null) {
                complete();
                return;
            }

            this.loadMaterialsService.load(this.project, this.scheduleTask).then(() => {
                if (this.scheduleTask.primeMaterialId != null) {
                    this.fillMaterialSelectInputData();
                    complete();
                    return;
                }

                this.materialCategoryService.primeMaterialId(this.materialCategory.id, this.project.specification.id).subscribe(
                    (res: HttpResponse<number>) => {
                        this.scheduleTask.primeMaterialId = res.body;
                        this.fillMaterialSelectInputData();
                        complete();
                    }
                );
            });
        });
    }

    private fillScheduleAreaSelectInputData(): void {
        this.scheduleAreaSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'area',
            initIndex: this.scheduleArea?.id || this.scheduleTask.scheduleAreaId,
            data: this._appState.scheduleAreas,
            onEnterClickHandler: this.onScheduleAreaSelectInputEnterClick.bind(this),
            dropdownPosition: 'bottom'
        };
    }

    private fillMaterialSelectInputData(): void {
        this.materialSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'material',
            initIndex: this.scheduleTask.primeMaterialId,
            data: this.scheduleTask._materials,
            dropdownPosition: 'bottom',
            onEnterClickHandler: this.onMaterialSelectInputEnterClick.bind(this)
        };
    }

    private onScheduleAreaSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length > 0) {
            this._scheduleAreasHelperService.addScheduleArea(currentTextValue).then((createdScheduleArea) => {
                this.fillScheduleAreaSelectInputData();
                this.scheduleAreaSelectInputData.initItem = createdScheduleArea.area;
                this.scheduleAreaSelectInputData.initIndex = createdScheduleArea.id;

                this.onAreaSelectionChange(createdScheduleArea);
            });
        }
    }

    private onMaterialSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length > 0) {
            const modalRef = this.addMaterialModalService.open(
                this.project,
                this.scheduleTask,
                currentTextValue,
                this.materialCategory?.id,
                this.scheduleTask.unit
            );
            modalRef.result.then(
                (createdMaterial: IMaterial) => {
                    this.scheduleTask.primeMaterialId = createdMaterial.id;

                    this.loadMaterialsService.load(this.project, this.scheduleTask).then(() => {
                        this.fillMaterialSelectInputData();

                        this.materialSelectInputData.initItem = createdMaterial.material;
                        this.materialSelectInputData.initIndex = createdMaterial.id;

                        this.updateTaskTotal();
                    });
                },
                reason => {
                    // This is intentional
                }
            );
        }
    }
}
