import { Component, Input, OnInit } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { ChartData, ChartOptions } from 'chart.js';
import { IResourceCost } from 'app/shared/model/bp.model';

@Component({
    selector: 'bp-dashboard-resource-cost-breakdown',
    templateUrl: './resource-cost-breakdown.component.html',
    styleUrls: ['resource-cost-breakdown.scss']
})
export class DashboardResourceCostBreakdownComponent implements OnInit {
    @Input() useInTile: boolean = false;

    public doughnutChartLabels: string[] = ['Building Materials', 'Labour', 'Finishing Materials'];
    public doughnutChartData: ChartData<'doughnut'> = {
        labels: this.doughnutChartLabels,
        datasets: []
    };

    doughnutChartOptions: ChartOptions<'doughnut'>;

    total = 0;

    constructor(public dashboardStore: DashboardStore) {
    }

    ngOnInit(): void {
        this.doughnutChartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            animation: {
                duration: 800
            },
            layout: {
                padding: 0
            },
            plugins: {
                legend: {
                    display: false,
                }
            }
        };

        this.dashboardStore.resourceCost$.subscribe((resourceCost) => {
            this.total = resourceCost?.total ?? 0;
            this.redraw(resourceCost);
        });
    }

    redraw(resourceCost: IResourceCost | null): void {
        if (resourceCost) {
            const data = [
                resourceCost.buildingMaterial,
                resourceCost.labour,
                resourceCost.finishingMaterial
            ];
            if (resourceCost.profit != null) {
                data.push(resourceCost.profit);
            }

            const backgroundColor = [
                'rgb(255, 138, 87)',
                'rgb(0, 134, 170)',
                'rgb(174, 217, 228)'
            ]

            if (resourceCost.profit != null) {
                backgroundColor.push('rgb(255, 233, 74)');
            }

            this.doughnutChartData.datasets = [
                {
                    data,
                    backgroundColor
                }];
        } else {
            this.doughnutChartData.datasets = [];
        }
    }
}

