import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITag } from 'app/shared/model/tag.model';

@Component({
    selector: 'bp-tag-card',
    templateUrl: './tag-card.component.html',
    styleUrls: ['tag-card.scss']
})
export class TagCardComponent {
    @Input() tag: ITag;
    @Output() onTagClick = new EventEmitter();

    onClick() {
        this.onTagClick.emit(this.tag);
    }
}
