<ng-container *ngIf="Array.from((scheduleService.buildUpMapSubject | async).values()) as values">
    <div class="flex flex-row align-items-center justify-content-between" style="height: 36px;">
        <div (click)="toggleAreaExpand()"
             class="bp-cursor-pointer root-header">
            <span>{{ scheduleArea.area }}</span>
            <i class="material-icons">{{ expanded ? "expand_more" : "chevron_right" }}</i>
        </div>
        <div class="flex-1 root-header flex flex-row align-items-center justify-content-end gap-10">
            <span>{{ getScheduleAreaTotal(values) | currency:'GBP' }}</span>
        </div>
    </div>

    <div *ngIf="expanded"
         class="flex flex-column m-l-20 gap-10">
        <ng-container *ngFor="let value of values">
            <ng-container *ngIf="filteredScheduleTasks(value.scheduleTasks) as scheduleTasks">
                <bp-schedule-build-up
                    *ngIf="scheduleTasks.length"
                    [buildUp]="value.buildUp"
                    [scheduleAreaId]="scheduleArea.id"
                    [scheduleTasks]="scheduleTasks">
                </bp-schedule-build-up>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
