export const environment = {
    production: false,
    env: 'stage',
    server_api_url: 'https://dev-api.buildpartner.com/',
    gocardles_script: 'undefined',
    public_stripe_api_key: 'undefined',
    server_build_version: '6161',
    pdf_monkey_url: 'https://api.pdfmonkey.io/',
    pdf_monkey_template_id: 'bt8-bpsMFroxzcxKs7NP',
    pdf_monkey_document_template_id: 'EBA7D5FC-80D7-412C-B8CC-459E969F74D5',
    stripe_promotion_code: 'promo_1PhuaFIpV58FRykGMLVlVNZA',
    stripe_price_id: 'price_1Mh0xxIpV58FRykGxda3eVS6',
    systemUpgrades: false
};
