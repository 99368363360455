import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ProdConfig } from './blocks/config/prod.config';
import { BpFrontendAppModule } from './app.module';
import { ENVIRONMENT, SERVER_API_URL, SERVER_BUILD_VERSION } from './app.constants';
import * as Sentry from '@sentry/browser';
import { browserTracingIntegration } from "@sentry/angular";

ProdConfig();

platformBrowserDynamic()
    .bootstrapModule(BpFrontendAppModule, { preserveWhitespaces: true })
    .then(success => console.log(`Application started, backend url: ` + SERVER_API_URL))
    .catch(err => console.error(err));

console.log(`Environment: ${ENVIRONMENT}, SERVER_BUILD_VERSION: ${SERVER_BUILD_VERSION}, Server API URL: ${SERVER_API_URL}`);

let SENTRY_TRACES_SAMPLE_RATE = 1.0;

switch (ENVIRONMENT) {
    case 'stage':
        SENTRY_TRACES_SAMPLE_RATE = 1.0;
        initSentry();
        break;
    case 'prod':
        SENTRY_TRACES_SAMPLE_RATE = 0.2;
        initSentry();
        break;
    default:
        break;
}

function initSentry() {
    Sentry.init({
        dsn: "https://825fcfc65a8b4d948e47f9d6780f5522@o257679.ingest.sentry.io/4505398900293632",

        // To set a uniform sample rate
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [browserTracingIntegration()],
    });
}
