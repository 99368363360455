import { Component, OnDestroy, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IStage } from '../../../model/stage.model';
import { ITask } from 'app/shared/model/task.model';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { ITrade } from 'app/shared/model/trade.model';
import { IMaterial } from 'app/shared/model/material.model';
import { IElement } from 'app/shared/model/element.model';
import { TaskApi } from 'app/shared/dataservices/task.api';
import { ElementService } from 'app/shared/dataservices/element.service';
import { StageService } from 'app/shared/dataservices/stage.service';
import { TradeService } from 'app/shared/dataservices/trade.service';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ILabourComponent, LabourComponent } from 'app/shared/model/labour-component.model';
import { IMaterialComponent, MaterialComponent } from 'app/shared/model/material-component.model';
import { IUnit } from 'app/shared/model/unit.model';
import { UnitService } from 'app/shared/dataservices/unit.service';
import { AvailableMaterialService } from 'app/shared/dataservices/available-material.service';
import * as _ from 'lodash';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { MaterialCategoryService } from 'app/shared/dataservices/material-category.service';

@Component({
    selector: 'bp-add-task-modal',
    templateUrl: './add-task-modal.component.html'
})
export class AddTaskModalComponent implements OnInit, OnDestroy {
    task: ITask;
    isSaving: boolean;

    componentareas: IComponentArea[];
    units: IUnit[];
    trades: ITrade[];
    elements: IElement[];
    stages: IStage[];
    materialCategories: IMaterialCategory[];

    NA = 'N/A';

    updateSubject: Subject<void> = new Subject();

    constructor(
        private taskApi: TaskApi,
        private activeModal: NgbActiveModal,
        private elementService: ElementService,
        private unitService: UnitService,
        private stageService: StageService,
        private tradeService: TradeService,
        private availableMaterialService: AvailableMaterialService,
        private materialCategoryService: MaterialCategoryService,
        private componentAreaService: ComponentAreaService
    ) {
        this.task = {
            materialCategories: [{}],
            materialComponents: [],
            labourComponents: [],
            componentarea: null,
            element: null,
            stage: null,
            unit: null
        };
    }

    ngOnInit(): void {
        this.isSaving = false;

        this.componentAreaService.query().subscribe(
            (res: HttpResponse<IComponentArea[]>) => {
                this.componentareas = res.body;
            }
        );

        this.unitService.query().subscribe(
            (res: HttpResponse<IUnit[]>) => {
                this.units = res.body;
            }
        );

        this.stageService.query().subscribe(
            (res: HttpResponse<IStage[]>) => {
                this.stages = res.body;
            }
        );

        this.tradeService.query().subscribe(
            (res: HttpResponse<ITrade[]>) => {
                this.trades = res.body;
            }
        );

        this.materialCategoryService.query().subscribe(
            (res: HttpResponse<IMaterialCategory[]>) => {
                this.materialCategories = res.body;
            }
        );
    }

    ngOnDestroy(): void {
        this.updateSubject.unsubscribe();
    }

    clear(): void {
        this.activeModal.dismiss('cancel');
    }

    create(): void {
        this.isSaving = true;

        const taskToSave = _.cloneDeep(this.task);
        taskToSave.materialComponents = _.map(taskToSave.materialComponents, (mc: IMaterialComponent) => {
            delete mc['_searchBySelectInputData'];
            delete mc.material['_calculatedLabelField'];
            return mc;
        });

        this.subscribeToSaveResponse(this.taskApi.create(taskToSave));
    }

    onStageSelect(): void {
        this.task.element = null;

        if (this.task.stage == null) {
            this.elements = [];
        } else {
            this.elementService.queryByStageId(this.task.stage.id).subscribe(
                (res: HttpResponse<IElement[]>) => {
                    this.elements = res.body;
                }
            );
        }
    }

    onComponentAreaChange(): void {
        if (this.task.componentarea.componentArea !== this.NA) {
            this.task.unit = null;
        }
    }

    trackComponentAreaById(index: number, item: IComponentArea): number {
        return item.id;
    }

    trackUnitById(index: number, item: IUnit): number {
        return item.id;
    }

    trackElementById(index: number, item: IElement): number {
        return item.id;
    }

    trackStageById(index: number, item: IStage): number {
        return item.id;
    }

    trackTradeById(index: number, item: ITrade): number {
        return item.id;
    }

    trackMaterialById(index: number, item: IMaterial): number {
        return item.id;
    }

    trackMaterialCategoryById(index: number, item: IMaterialCategory): number {
        return item.id;
    }

    addLabourComponent(): void {
        this.task.labourComponents.push(new LabourComponent(-1 * Date.now(), 0, null, null));
    }

    removeLabourComponent(item: ILabourComponent): void {
        this.task.labourComponents.splice(this.task.labourComponents.indexOf(item), 1);
        this.updateSubject.next();
    }

    addMaterialComponent(): void {
        const newMaterialComponent = new MaterialComponent(-1 * Date.now(), 0, null, null);
        newMaterialComponent['_searchBySelectInputData'] = {
            searchBy: 'material',
            indexProperty: 'id',
            titleProperty: 'material',
            displayProperty: 'material',
            itemService: this.availableMaterialService,
            dropdownPosition: 'auto'
        };
        this.task.materialComponents.push(newMaterialComponent);
    }

    removeMaterialComponent(item: IMaterialComponent): void {
        this.task.materialComponents.splice(this.task.materialComponents.indexOf(item), 1);
        this.updateSubject.next();
    }

    onMaterialComponentSelectionChange(materialComponent: IMaterialComponent, $event): void {
        materialComponent.material = $event;
        this.updateSubject.next();
    }

    onComponentChange(): void {
        this.updateSubject.next();
    }

    onMaterialCategoryChanged(): void {
        this.updateSubject.next();
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<ITask>>): void {
        result.subscribe(
            (res: HttpResponse<ITask>) => this.onSaveSuccess(),
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess(): void {
        this.isSaving = false;
        this.clear();
    }

    private onSaveError(): void {
        this.isSaving = false;
    }
}
