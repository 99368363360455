import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAccount } from 'app/shared/model/account.model';
import { PaymentProvider } from 'app/shared/model/payment-provider.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BUILD_PARTNER_SUBSCRIBE_URL, PAYMENT_TERMS_URL_SCHEDULER } from 'app/shared/constants/links.constants';
import { Subject } from 'rxjs';
import { SubscribeToStripeService } from 'app/shared/services/payment/subscribe-to-stripe.service';
import { AccountService } from 'app/core/auth/account.service';

export interface SelectPaymentProviderModalSchedulerResult {
    paymentProvider: PaymentProvider;
    error?: string;
}

@Component({
    selector: 'select-payment-provider-scheduler-modal',
    templateUrl: './select-payment-provider-scheduler-modal.component.html',
    styleUrls: ['select-payment-provider-scheduler-modal.scss']
})
export class SelectPaymentProviderSchedulerModalComponent implements OnInit, OnDestroy {
    account: IAccount;
    selectedPaymentProvider: PaymentProvider;

    onOkInProcess = false;
    isWaitingForStripePaymentInProcess = false;

    PAYMENT_TERMS_URL = PAYMENT_TERMS_URL_SCHEDULER;

    /* private onStripeStatusPollingFinished$ = new Subject<PaymentStatus>(); */
    private onDestroy$ = new Subject<void>();

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    constructor(
        public activeModal: NgbActiveModal,
        private subscribeToStripeService: SubscribeToStripeService,
        private accountService: AccountService
    ) {}

    ngOnInit(): void {
        this.accountService.identity().then(account => {
            this.account = account;

            this.ngSelectComponent?.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    inProcess(): boolean {
        return this.onOkInProcess || this.isWaitingForStripePaymentInProcess;
    }

    onOkClick(): void {
        this.onOkInProcess = true;

        const result: SelectPaymentProviderModalSchedulerResult = {
            paymentProvider: this.selectedPaymentProvider
        };

        switch (this.selectedPaymentProvider) {
            case 'GOCARDLESS':
                window.open(BUILD_PARTNER_SUBSCRIBE_URL, '_blank');
                this.onOkInProcess = false;
                this.activeModal.close(result);
                /*this.setupGocardlessService.setup().subscribe(() => {
                    this.paymentProviderGocardlesService.createSubscription().subscribe(() => {
                        this.accountService.identity(true);
                        this.onOkInProcess = false;
                        this.activeModal.close(result);
                    });
                });*/
                break;
            case 'STRIPE':
                this.subscribeToStripeService.do().subscribe(() => {
                    this.accountService.identity(true);
                    this.onOkInProcess = false;
                    this.activeModal.close(result);
                });
                break;
            default:
                this.onOkInProcess = false;
        }
    }

    clear(): void {
        this.activeModal.dismiss('close');
    }

    isPaymentProviderSetup(paymentProvider: PaymentProvider): boolean {
        return (this.account.paymentProviders || []).includes(paymentProvider);
    }
}
