<div class="root row">
    <div class="col-sm-6">
        <i (click)="toggleExpand()"
           class="material-icons cursor-pointer">{{ expanded ? 'expand_more' : 'chevron_right'}}</i>
        <span class="all-stage-name p-l-5">All Stages</span>
    </div>
    <div *ngIf="total != null"
         class="col-sm-6 text-right">
        <span class="total">{{ total || 0 | currency:'GBP' }}</span>
    </div>
</div>
