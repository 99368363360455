import { Component, OnInit } from '@angular/core';
import { QuotesService } from "app/flows/scheduler/quotes/services/quotes.service";

/**
 * Quotes
 */
@Component({
    selector: 'bp-scheduler-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['quotes.scss'],
    providers: [QuotesService]
})
export class SchedulerQuotesComponent implements OnInit {

    constructor(protected context: QuotesService) {
    }

    ngOnInit(): void {
     this.context.init();
    }
}
