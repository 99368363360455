import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectCallbackApi } from "app/shared/dataservices/project-callback.api";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'bp-request-callback-modal',
    templateUrl: './request-callback-modal.component.html',
    styleUrls: ['request-callback-modal.scss']
})
export class RequestCallbackModalComponent implements OnInit {
    protected callbackForm: FormGroup;
    protected submitted = false;

    constructor(
        private fb: FormBuilder,
        private callbackApi: ProjectCallbackApi,
        private activeModal: NgbActiveModal
    ) {
        this.callbackForm = this.fb.group({
            name: ['', Validators.required],
            mail: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email]],
            phone: ['', Validators.required],
            note: ['']
        });
    }

    ngOnInit(): void {
        this.callbackApi.get().subscribe(
            (response) => this.callbackForm.patchValue(response.body || {}),
            (error) => {
                if (error.status !== 404) {
                    console.error('Error fetching callback data', error);
                }
            }
        );
    }

    protected onSubmit(): void {
        this.submitted = true;

        if (this.callbackForm.valid) {
            this.callbackApi.save(this.callbackForm.value).subscribe(
                () => this.activeModal.close(),
                (error) => console.error('Error saving callback', error)
            );
        }
    }

    protected clear(): void {
        this.activeModal.dismiss('close');
    }
}
