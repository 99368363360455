<div class="select-container">
    <div class="select-container__values-container">
        <div class="select-container__values-container__option cursor-pointer"
             *ngFor="let item of items;">
            <div (click)="select(item)">
                {{ item[labelField] }}
            </div>
        </div>
    </div>
</div>
