import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IStage } from 'app/shared/model/stage.model';
import {
    CalculateStageFilteredByAreaTotalService
} from 'app/flows/scheduler/schedule/services/calculate-stage-filtered-by-area-total.service';
import { IElement } from 'app/shared/model/element.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { IBuildUp, ICssElement } from 'app/shared/model/bp.model';
import { IComparisonScheduleTask } from 'app/shared/model/comparison-schedule-task.model';

export interface IMainFilterApplyingToScheduleResult {
    scheduleAreas: IScheduleArea[];
    stages: IStage[];
    cssElementMap: Map<number, { cssElement: ICssElement, scheduleTasks: IScheduleTask[] }>;
    buildUpMap: Map<number, { buildUp: IBuildUp, scheduleTasks: IScheduleTask[] }>;
}

@Injectable({ providedIn: 'root' })
export class ApplyMainFilterToScheduleService {
    constructor(
        private calculateStageFilteredByAreaTotalService: CalculateStageFilteredByAreaTotalService
    ) {
    }

    apply(
        nativeScheduleAreas: IScheduleArea[],
        allStages: IStage[],
        filterState: IMainViewFilterState,
        scheduleAreaItems: IMainViewFilterItemItem[],
        stageItems: IMainViewFilterItemItem[],
        cssElementItems: IMainViewFilterItemItem[],
        buildUpItems: IMainViewFilterItemItem[]
    ): IMainFilterApplyingToScheduleResult {
        const result: IMainFilterApplyingToScheduleResult = {
            scheduleAreas: [],
            stages: [],
            cssElementMap: new Map<number, { cssElement: ICssElement, scheduleTasks: IScheduleTask[] }>(),
            buildUpMap: new Map<number, { buildUp: IBuildUp, scheduleTasks: IScheduleTask[] }>()
        };

        let selectedScheduleAreaIds = filterState.areaIds == null ? scheduleAreaItems.map(i => i.id) : filterState.areaIds;
        let selectedStageIds = filterState.stageIds == null ? stageItems.map(i => i.id) : filterState.stageIds;
        let selectedCssElementIds = filterState.cssElementIds == null ? cssElementItems.map(i => i.id) : filterState.cssElementIds;
        let selectedBuildUpIds = filterState.buildUpIds == null ? buildUpItems.map(i => i.id) : filterState.buildUpIds;

        if (filterState.groupBy === 'area_room') {
            selectedScheduleAreaIds = [filterState.areaRoomId];
        }

        if (filterState.groupBy === 'stage_room') {
            selectedStageIds = [filterState.stageRoomId];
        }

        if (filterState.groupBy === 'css-element_room') {
            selectedCssElementIds = [filterState.cssElementRoomId];
        }

        if (filterState.groupBy === 'build-up_room') {
            selectedBuildUpIds = [filterState.buildUpRoomId];
        }

        result.scheduleAreas = _.filter(_.cloneDeep(nativeScheduleAreas), area => {
            if (!selectedScheduleAreaIds) {
                return true;
            }

            if (!selectedScheduleAreaIds.length) {
                return false;
            }

            return selectedScheduleAreaIds.indexOf(area.id) !== -1;
        });

        switch (filterState.groupBy) {
            case 'area':
            case 'area_room':
                const stages = allStages;

                _.each(result.scheduleAreas, (scheduleArea: IScheduleArea) => {
                    scheduleArea['stages'] = _.filter(stages, (stage: IStage) => {
                        if (!selectedStageIds) {
                            return true;
                        }

                        if (!selectedStageIds.length) {
                            return false;
                        }
                        return selectedStageIds.indexOf(stage.id) !== -1;
                    });

                    _.each(scheduleArea['stages'], (stage: IStage) => {
                        stage._quoterTotals = stage._quoterTotals || {};
                        stage._quoterTotals[scheduleArea.id] = this.calculateStageFilteredByAreaTotalService.calculate(stage, scheduleArea);
                    });
                });
                break;
            case 'stage':
            case 'stage_room':
                const filteredStages2 = _.filter(allStages, (stage: IStage) => {
                    if (!selectedStageIds) {
                        return true;
                    }

                    if (!selectedStageIds.length) {
                        return false;
                    }
                    return selectedStageIds.indexOf(stage.id) !== -1;
                });

                _.each(filteredStages2, (stage: IStage) => {
                    stage._areas = _.filter(_.cloneDeep(result.scheduleAreas), (a: IScheduleArea) => {
                        return _.indexOf(stage.scheduleAreaIds, a.id) !== -1;
                    });

                    stage.elements.forEach((element: IElement) => {
                        element.scheduleTasks = _.filter(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                            return selectedScheduleAreaIds?.indexOf(scheduleTask.scheduleAreaId) !== -1;
                        });
                    });

                    delete stage._areas['stages'];
                });

                result.stages = filteredStages2;

                break;

            case 'css-element': {
                const allScheduleTasks: IScheduleTask[] = [];

                _.each(allStages, (stage: IStage) => {
                    stage.elements?.forEach((element) => {
                        allScheduleTasks.push(...element.scheduleTasks || []);
                    })
                });

                result.cssElementMap = this.createCssElementMap(allScheduleTasks, selectedCssElementIds, cssElementItems);
                break;
            }

            case 'build-up': {
                const allScheduleTasks: IScheduleTask[] = [];

                _.each(allStages, (stage: IStage) => {
                    stage.elements?.forEach((element) => {
                        allScheduleTasks.push(...element.scheduleTasks || []);
                    })
                });

                result.buildUpMap = this.createBuildUpMap(allScheduleTasks, selectedBuildUpIds, buildUpItems);
                break;
            }
        }

        return result;
    }

    private createCssElementMap(allScheduleTasks: IScheduleTask[] = [],
                                selectedCssElementIds: number[],
                                cssElementItems: IMainViewFilterItemItem[]): Map<number, {
        cssElement: ICssElement,
        scheduleTasks: IScheduleTask[]
    }> {
        const res: Map<number, { cssElement?: ICssElement, scheduleTasks?: IScheduleTask[] }> = new Map<number, {
            cssElement: ICssElement,
            scheduleTasks: IScheduleTask[]
        }>;

        allScheduleTasks.forEach(scheduleTask => {
            if (scheduleTask.cssElementId != null && selectedCssElementIds.indexOf(scheduleTask.cssElementId) !== -1) {
                const cssElement: ICssElement = { id: scheduleTask.cssElementId, name: scheduleTask.cssElement };
                const scheduleTasks = res.get(cssElement.id)?.scheduleTasks || [];
                scheduleTasks.push(scheduleTask);
                res.set(cssElement.id, { cssElement: cssElement, scheduleTasks });
            }
        })

        const unspecifiedScheduleTasks = allScheduleTasks.filter(scheduleTask => scheduleTask.cssElement == null);

        if (unspecifiedScheduleTasks.length) {
            res.set(-1, {
                cssElement: {
                    id: -1,
                    name: 'Unspecified',
                    order: Number.MAX_SAFE_INTEGER,
                    archive: false,
                    total: 0
                },
                scheduleTasks: unspecifiedScheduleTasks
            });
        }

        return new Map([...res as Map<number, {
            cssElement: ICssElement,
            scheduleTasks: IComparisonScheduleTask[]
        }>].sort((a, b) => {
            const orderA = a[1].cssElement.order || cssElementItems.find(i => i.id === a[0])?.order;
            const orderB = b[1].cssElement.order || cssElementItems.find(i => i.id === b[0])?.order;

            if (orderA == orderB) {
                return 0;
            }
            return orderA > orderB ? 1 : -1;
        }));
    }

    private createBuildUpMap(allScheduleTasks: IScheduleTask[] = [],
                             selectedBuildUpIds: number[],
                             buildUpItems: IMainViewFilterItemItem[]): Map<number, {
        buildUp: IBuildUp,
        scheduleTasks: IScheduleTask[]
    }> {
        const res: Map<number, { buildUp: IBuildUp, scheduleTasks: IScheduleTask[] }> = new Map<number, {
            buildUp: IBuildUp,
            scheduleTasks: IScheduleTask[]
        }>;

        buildUpItems
            .filter(buildUpItem => selectedBuildUpIds.indexOf(buildUpItem.id) !== -1)
            .forEach((buildUpItem) => {
                const buildUpScheduleTasks = allScheduleTasks.filter(scheduleTask => scheduleTask.buildUpId === buildUpItem.id);
                if (buildUpScheduleTasks.length) {
                    const buildUp: IBuildUp = {
                        id: buildUpItem.id,
                        name: buildUpItem?.title
                    };
                    res.set(buildUpItem.id, { buildUp: buildUp, scheduleTasks: buildUpScheduleTasks });
                }
            })

        return res;
    }
}
