import { Component, Input, OnInit } from '@angular/core';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import {
    AddGroupOfScheduleTasksModalService
} from 'app/flows/scheduler/schedule/components/add-group-of-schedule-tasks-modal/add-group-of-schedule-tasks-modal.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { INewTaskRequestCreationDTO } from 'app/shared/model/new-task-request.model';
import { HttpResponse } from '@angular/common/http';
import { ITask } from 'app/shared/model/task.model';
import {
    TaskRequestModalService
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.service';
import { NewTaskRequestApi } from 'app/shared/dataservices/new-task-request.api';
import { IAddScheduleTaskRequest } from 'app/shared/constants/events.constants';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-schedule-tasks-were-not-found',
    templateUrl: './tasks-were-not-found.component.html',
    styleUrls: ['tasks-were-not-found.scss']
})
export class TasksWereNotFoundComponent implements OnInit {

    @Input() scheduleArea: IScheduleArea;

    constructor(
        protected appStateService: ApplicationStateService,
        protected scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        private taskRequestModalService: TaskRequestModalService,
        private newTaskRequestApi: NewTaskRequestApi,
        private addGroupOfScheduleTasksModalService: AddGroupOfScheduleTasksModalService,
    ) {
    }

    ngOnInit(): void {
    }

    addTask(): void {
        this.addGroupOfScheduleTasksModalService.open(this.scheduleService.project, this.scheduleArea, this.scheduleService.searchKey).result.then(res => {
            if (res.newTaskRequest) {
                this.taskRequestModalService.open(res.newTaskRequest).result.then((updatedData: INewTaskRequestCreationDTO) => {
                    this.newTaskRequestApi.newTask(updatedData).subscribe((res: HttpResponse<ITask>) => {
                        const task = res.body;
                        if (!task) {
                            return;
                        }

                        const stage = this.scheduleService.allStages.find(stage => stage.id === task.stageId);
                        let taskStage = this.appStateService.data.stages.find((s) => s.id === stage.id);

                        if (!taskStage) {
                            this.appStateService.data.stages.push(Object.assign({}, stage));
                            taskStage = this.appStateService.data.stages.find((s) => s.id === stage.id);
                        }

                        this.scheduleService.expandStage(taskStage.id, null, true);

                        const request: IAddScheduleTaskRequest = {
                            area: null,
                            stage: taskStage,
                            element: taskStage.elements.find(el => el.id === task.elementId),
                            task
                        };

                        this.scheduleEventsService.addScheduleTask(request);
                    });
                })
            }
        });
    }
}
