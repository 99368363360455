import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable, Observer } from 'rxjs';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';

@Injectable({ providedIn: 'root' })
export class GetNaComponentAreaService {
    naComponentArea: IComponentArea;

    constructor(private componentAreaService: ComponentAreaService) {}

    public get(): Observable<IComponentArea> {
        return new Observable((observer: Observer<IComponentArea>) => {
            if (this.naComponentArea != null) {
                observer.next(this.naComponentArea);
                observer.complete();
            } else {
                this.componentAreaService.query().subscribe(
                    (res: HttpResponse<IComponentArea[]>) => {
                        this.naComponentArea = _.find(res.body, { componentArea: 'N/A' });
                        observer.next(this.naComponentArea);
                        observer.complete();
                    }
                );
            }
        });
    }
}
