<div class="modal-header">
    <h2>
        Update Material and Labour Rates
    </h2>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="strong m-b-10">
        This feature is only available to subscribed standard accounts
    </div>
    <div class="strong m-b-10">
        To gain access feel free to subscribe here, and we will set you up and book in a walkthrough.
    </div>
    <div class="strong m-b-10">
        Alternatively, if you would like to trial the feature, request a demo below.
    </div>

    <div class="link underlined cursor-pointer text-left"
       (click)="showVideo = !showVideo">
        Learn more
    </div>

    <ng-container *ngIf="showVideo">
        <iframe class="loom_iframe"
                width="100%"
                height="300"
                [src]="LEARN_MORE_RATES_AND_MARGINS_VIDEO_URL | safe: 'resourceUrl'"
                frameborder="0"
                style="padding-top: 20px;"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen>
        </iframe>
    </ng-container>
</div>

<div class="modal-footer m-t-20">
    <button (click)="onBookDemoClick()"
            tabindex="0"
            class="btn btn-secondary btn-border-radius waves-effect book-demo"
            type="button">
        Book Demo
    </button>

    <button (click)="onSubscribeClick()"
            tabindex="1"
            class="btn btn-primary btn-border-radius waves-effect subscribe"
            type="button">
        Subscribe
    </button>
</div>

