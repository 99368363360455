<ng-select #autocompleteSelect
           (close)="setData()"
           (open)="onOpen()"
           (search)="onTyped($event)"
           (scroll)="onScroll($event)"
           (scrollToEnd)="onScrollToEnd()"
           (clear)="onClear()"
           (keydown.enter)="onEnterClick()"
           [(ngModel)]="selectedIndex"
           [clearable]="data.clearable != null ? data.clearable : false"
           [disabled]="disabled"
           [items]="items"
           [multiple]="multiply"
           [virtualScroll]="true"
           [ngClass]="{'remove-underlines': removeUnderlines}"
           class="bp-select {{ additionalClasses }}"
           bindLabel="_calculatedLabelField"
           bindValue="{{data.indexProperty}}"
           dropdownPosition="{{data.dropdownPosition || 'top'}}"
           id="autocompleteSelect"
           notFoundText="{{ inProcess ? 'Please wait.. Loading...' : 'No items found'}}"
           appendTo="{{appendTo}}"
           [placeholder]="placeholder">
    <ng-template ng-label-tmp let-item="item">
        <div style="max-width: 100%; overflow: hidden; word-wrap: normal; white-space: normal; line-height: 1;">
            {{ item[data.displayProperty] || item['_calculatedLabelField'] || item[data.titleProperty] }}
        </div>
    </ng-template>

    <ng-template ng-footer-tmp *ngIf="addNewPossible || suggestionItems?.length">
        <div class="flex flex-column gap-10">
            <div *ngIf="addNewPossible"
                 style="line-height: 40px"
                 [ngClass]="{'border-bottom': suggestionItems?.length }">
                <a (click)="onAddNewTaskClick()">
                    + Add '{{ ngSelectComponent.searchTerm }}'
                </a>
            </div>
            <div *ngIf="suggestionItems?.length"
                 class="suggestions">
                <div style="max-height: 200px; overflow: auto"
                     (scroll)="onScrollSuggestions($event)">
                    <div class="suggestions__title mb-1">
                        You might be looking for:
                    </div>

                    <div class="flex flex-column gap-10">
                        <div *ngFor="let suggestionItem of suggestionItems"
                             (click)="onSuggestionItemClick($event, suggestionItem)"
                             class="suggestions__item">
                            <span>{{ suggestionItem[data.displayProperty] || suggestionItem['_calculatedLabelField'] || suggestionItem[data.titleProperty] }}</span>
                            &nbsp;<span class="suggestions__item__found-in">Found in</span><br/>
                            <span class="font-weight-bold">"{{ suggestionItem.stage }}"</span>&nbsp;<span>stage</span>
                            <ng-container *ngIf="data.additionalQueryPathParams['elementId'];">
                                <br/><span class="font-weight-bold">"{{ suggestionItem.element ?? suggestionItem.elementId }}"</span>&nbsp;<span>element</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-select>
