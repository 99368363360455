import { Component, OnInit } from '@angular/core';
import { IComparisonQoter } from 'app/shared/model/comparison.model';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { take } from "rxjs";

@Component({
    selector: 'bp-dashboard-display-quoters-selector',
    templateUrl: './display-quoters-selector.component.html',
    styleUrls: ['display-quoters-selector.scss']
})
export class DashboardDisplayQuotersSelectorComponent implements OnInit {
    protected MAX_SELECTED_COUNT = 4;

    constructor(private _dashboardService: DashboardService,
                protected dashboardStore: DashboardStore) {
    }

    ngOnInit(): void {
    }

    protected select(quoter: IComparisonQoter, event): void {
        if (this.dashboardStore.selectedQuoters.length === this.MAX_SELECTED_COUNT && !quoter.selected) {
            event.preventDefault();
            return;
        }

        quoter.selected = !quoter.selected;
        this._dashboardService.update().pipe(take(1)).subscribe();
    }
}
