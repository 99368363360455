import { Injectable } from '@angular/core';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ScheduleAreaApi } from "app/shared/dataservices/schedule-area.api";
import { ApplicationStateService } from "app/core/application-state.service";
import { lastValueFrom } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ScheduleAreasHelperService {
    constructor(private applicationStateService: ApplicationStateService,
                private scheduleAreaApi: ScheduleAreaApi) {
    }

    calcCostPerSqm(areaIds: number[], totalCost: number): Promise<number> {
        if (!this.applicationStateService.scheduleAreas) {
            return Promise.resolve(0);
        }

        let gia = 0;
        const scheduleAreas: IScheduleArea[] = this.applicationStateService.scheduleAreas
            .filter(area => areaIds.indexOf(area.id) !== -1 && !area.tag?.name.toLowerCase().includes('shell'));
        scheduleAreas.forEach((scheduleArea: IScheduleArea) => {
            gia += scheduleArea.floorArea;
        })
        if (gia > 0) {
            return new Promise(resolve => resolve(totalCost / gia));
        } else {
            return new Promise(resolve => resolve(0));
        }
    }

    addScheduleArea(name: string): Promise<IScheduleArea> {
        const newScheduleArea: IScheduleArea = {
            projectId: this.applicationStateService.project.id,
            specId: this.applicationStateService.project.specification.id,
            area: name,
            ceilingArea: 0,
            depth: 0,
            doors: 0,
            floorArea: 0,
            height: 0,
            perimeter: 0,
            position: 0,
            wallArea: 0,
            width: 0,
            windows: 0
        }

        return lastValueFrom(this.scheduleAreaApi.batchUpdate([newScheduleArea])).then(res => {
            return this.applicationStateService.refreshScheduleAreas(this.applicationStateService.project.id, true).then(() => {
                const sa: IScheduleArea = {
                    area: name,
                    id: this.applicationStateService.scheduleAreas.find(sa => sa.area === name).id
                }
                return sa;
            });
        });
    }
}
