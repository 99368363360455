<div class="modal-header">
    <h2>
        What kind of service do you require?
    </h2>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="addons-container">
        <div class="addons-container__addon"
             *ngFor="let addon of _addons"
             [ngClass]="{'addons-container__addon__selected': _selectedAddon === addon}"
             (click)="select(addon)">
            <img [alt]="addon.name"
                 height="150"
                 [src]="addon.imageUrl"/>

            <div class="addons-container__addon__title">
                {{ addon.name }}
            </div>

            <div class="addons-container__addon__price">
                <span
                    *ngIf="currencyCode(addon) as currencyCode; else percent">{{ addon.cost | currency:currencyCode :'symbol' : '1.0-2' }}</span>

                <ng-template #percent>
                    <span>{{ addon.cost | percent : '1.0-2' }}</span>
                </ng-template>
            </div>

            <div class="addons-container__addon__subtitle"
                 [innerHTML]="addon.subtitle">
            </div>

            <div class="addons-container__addon__description"
                 [innerHTML]="addon.description">
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div style="width: 300px;">
    </div>

    <div class="flex-1 text-center">
        Bespoke deals and fixed prices available on request to
        <a class="link underlined" href="mailto:{{ESTIMATING_EMAIL}}">{{ ESTIMATING_EMAIL }}</a>
    </div>

    <div class="flex gap-20 text-right" style="width: 300px;">
        <button (click)="close()"
                tabindex="0"
                class="btn btn-secondary btn-border-radius waves-effect cancel"
                type="button">
            Cancel
        </button>

        <button (click)="ok()"
                [disabled]="_selectedAddon == null"
                tabindex="1"
                class="btn btn-border-radius waves-effect ok"
                type="button">
            Select Service
        </button>
    </div>
</div>

