import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ILabelValue } from 'app/shared/components/charts/pie-chart/cost-plan-chart.component';
import { IMultiTogglerStep } from 'app/shared/components/common/multi-toggler/multi-toggler.component';
import { HttpResponse } from '@angular/common/http';
import { ISpecification } from 'app/shared/model/specification.model';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { IInvitation } from 'app/shared/model/invitation.model';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { ProjectSpecificationService } from 'app/shared/dataservices/project-specification.service';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import {
    UpdateInvitationVersionStatusCheckingService
} from 'app/shared/services/update-invitation-version-status-checking.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import {
    IOverviewProjectChartData
} from 'app/shared/components/projects/project-details-cost-visualization/overview-project-chart.service';
import { lastValueFrom, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-dashboard-project-cost',
    templateUrl: './project-cost.component.html',
    styleUrls: ['project-cost.scss']
})
export class DashboardProjectCostComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    @Input() useInTile: boolean = false;

    @ViewChild('costPlanChartComponent') costPlanChartComponent;
    @ViewChild('costPlanChartComponentMobile') costPlanChartComponentMobile;

    mtSteps: IMultiTogglerStep[] | null = null;
    onInitSub = Subscription.EMPTY;
    overviewProjectChartDataSub = Subscription.EMPTY;

    get showMtSteps(): boolean {
        return this._appStateService.project.currentUserRelation.includes('ROLE_SCHEDULER') && this.mtSteps != null;
    }

    get currentSpecStepId(): number {
        return (this.mtSteps.find((mtStep) => (mtStep.id === this._appStateService.project.specification?.id)) || this.mtSteps[0]).id;
    }

    get chartData(): ILabelValue[] {
        const data: ILabelValue[] = [];
        const valueColumn = this.dashboardStore.valueColumns?.length === 1 ?
            this.dashboardStore.valueColumns[0] :
            this.dashboardStore.valueColumns.find(q => q.quoter?.email === this._appStateService.project?.defaultQuoter?.email);

        if (!valueColumn) {
            return [];
        }

        valueColumn.data.forEach((d) => {
            data.push({
                label: d.item.title,
                value: d.total,
                percentage: _.round(d.total / (valueColumn.quoterTotal / 100), 2)
            });
        });
        return data;
    }

    constructor(
        private _appStateService: ApplicationStateService,
        public dashboardService: DashboardService,
        public dashboardStore: DashboardStore,
        private specificationService: SpecificationService,
        private invitationService: InvitationService,
        private projectSpecificationService: ProjectSpecificationService,
        private confirmModalService: ConfirmModalService,
        private updateInvitationVersionStatusCheckingService: UpdateInvitationVersionStatusCheckingService,
        private alertService: BpAlertService
    ) {
    }

    ngOnInit(): void {
        lastValueFrom(this.specificationService.query()).then((res: HttpResponse<ISpecification[]>) => {
            this.mtSteps = res.body.map(spec => {
                const res: IMultiTogglerStep = {
                    id: spec.id,
                    label: spec.title,
                    tooltip: spec.description
                }

                return res;
            });
        })

        this.onInitSub = this.dashboardStore.initedState$.subscribe((inited: boolean) => {
            if (inited) {
                setTimeout(() => {
                    const chartData = this.chartData;
                    if (chartData) {
                        this.costPlanChartComponent?.redraw(chartData);
                        this.costPlanChartComponentMobile?.redraw(chartData);
                    }
                });
            }
        });

        this.overviewProjectChartDataSub = this.dashboardStore.overviewProjectChartDataState$
            .subscribe((res: IOverviewProjectChartData) => {
                if (res != null) {
                    const chartData = this.chartData;
                    if (chartData) {
                        this.costPlanChartComponent?.redraw(chartData);
                        this.costPlanChartComponentMobile?.redraw(chartData);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.onInitSub.unsubscribe();
        this.overviewProjectChartDataSub.unsubscribe();
    }

    onSpecTogglerChanged(event: { step: IMultiTogglerStep, callback: Function }): void {
        this.confirmModalService.open(
            {
                header: 'Update Level of Specification?',
                textHtml: `<div class='strong m-b-10'>
                            This will change all material items to your chosen specification.
                        </div>
                        <div class='strong m-b-10'>
                            Beware, this will override any specification updates you’ve made on the schedule page.
                        </div>`,
                confirmButtonText: 'Update'
            }
        ).result.then(result => {
                event.callback(result);

                if (!result) {
                    return;
                }

                this.blockUI.start('Please wait..');

                this.projectSpecificationService.change(this._appStateService.project.id, event.step.id).subscribe(() => {
                    this.invitationService
                        .query(this._appStateService.project.id)
                        .subscribe((res: HttpResponse<IInvitation[]>) => {
                            const invitations = res.body.filter(invitation => invitation.isDefault ||
                                invitation.quoterId === this._appStateService.project.defaultQuoter.id || invitation.processing);
                            const checkPromises = invitations.map(
                                (invitation: IInvitation) => this.updateInvitationVersionStatusCheckingService.addUpdateVersionStatusChecking(
                                    this._appStateService.project,
                                    invitation
                                ));

                            Promise.all(checkPromises).then(() => {
                                this.refresh();
                                this.blockUI.stop();
                                this.alertService.success('Specification of materials was globally changed');
                            });

                        })
                })
            },
            reason => {
                event.callback(false);
            });
    }

    private refresh(): void {
        this.dashboardService.init();
    }

}
