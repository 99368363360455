import { Component, Input, OnInit } from '@angular/core';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';
import { StorageServiceProvider, WebStorageService } from 'app/shared/services/web-storage.service';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IBuildUp } from 'app/shared/model/bp.model';

@UntilDestroy()
@Component({
    selector: 'bp-schedule-area-for-filter-by-build-up-grouping',
    templateUrl: './area.component.html',
    styleUrls: [
        '../../root-container/stage.scss',
        '../../../schedule-common.scss',
        'area.scss'
    ],
    providers: [
        StorageServiceProvider, {
            provide: 'storageKeyConfig',
            useValue: { prefix: 'scheduler-area-for-build-ups-grouping', separator: '-' }
        }]
})
export class AreaForFilterByBuildUpGroupingComponent implements OnInit {
    @Input() scheduleArea: IScheduleArea;

    protected expanded = false;

    constructor(
        protected scheduleService: ScheduleService,
        private storageService: WebStorageService
    ) {
    }

    private get webStoragePath(): string[] {
        return [String(this.scheduleService.project.id), String(this.scheduleArea.id)];
    }

    ngOnInit(): void {
        this.expanded = this.storageService.retrieve(this.webStoragePath) as boolean;

        this.scheduleService.expandAll$
            .pipe(
                skip(1),
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res;
            this.storageService.store(this.webStoragePath, res);

            setTimeout(() => {
                this.scheduleService.filteredStages(this.scheduleArea['stages'], this.scheduleArea).forEach(stage => {
                    this.scheduleService.expandStage(stage.id, this.scheduleArea.id, res);
                })
            })
        })
    }

    protected toggleAreaExpand(): void {
        this.expanded ? this.collapseArea() : this.expandArea();
    }

    protected getScheduleAreaTotal(values: {
        buildUp: IBuildUp,
        scheduleTasks: IScheduleTask[]
    }[]): number {
        let total = 0;
        values.forEach(value => {
            const scheduleTasks = this.filteredScheduleTasks(value.scheduleTasks);
            scheduleTasks.forEach(st => {
                total += st.taskTotal.total;
            })
        });

        return total;
    }

    protected filteredScheduleTasks(scheduleTasks: IScheduleTask[]): IScheduleTask[] {
        return scheduleTasks.filter(scheduleTask => scheduleTask.scheduleAreaId === this.scheduleArea.id);
    }

    private expandArea(): void {
        this.expanded = true;
        this.storageService.store(this.webStoragePath, true);
    }

    private collapseArea(): void {
        this.expanded = false;
        this.storageService.store(this.webStoragePath, false);
    }

    protected readonly Array = Array;
}
