import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectInputData } from '../../common/select-input/select-input.component';
import { IStage } from '../../../model/stage.model';

@Component({
    selector: 'bp-add-schedule-stage-modal',
    templateUrl: './add-schedule-stage-modal.component.html',
    styleUrls: ['add-schedule-stage-modal.scss']
})
export class AddScheduleStageModalComponent {
    selectedStage: IStage;
    stageSelectInputData: SelectInputData;

    constructor(public activeModal: NgbActiveModal) {}

    clear() {
        this.activeModal.dismiss('cancel');
    }

    onStageSelectionChange(event) {
        this.selectedStage = event;
    }

    addScheduleStage() {
        this.activeModal.close(this.selectedStage);
    }
}
