import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectTimelineApi } from 'app/shared/dataservices/project-timeline.api';
import { IProjectTimeline, IProjectTimelineStage } from 'app/shared/model/project-time-line.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { lastValueFrom, Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-dashboard-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['timeline.scss']
})
export class DashboardTimelineComponent implements OnInit, OnDestroy {
    @BlockUI()
    protected blockUI: NgBlockUI;

    protected tradespeopleControl = new FormControl(null);
    protected tradespeopleControlSub = Subscription.EMPTY;

    protected overflowXClass = 'overflow-x-hidden';

    protected WIDTH = 25;
    protected HEIGHT = 40;

    protected weeks: number[] = [];

    protected timelineForOneTradespeople: IProjectTimeline | null = null;
    protected timeline: IProjectTimeline | null = null;

    protected timelineForm!: FormGroup;

    protected showResetButton = false;

    protected onNumberInput = (value) => {
        value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    };

    constructor(
        private fb: FormBuilder,
        private appState: ApplicationStateService,
        protected store: DashboardStore,
        private projectTimelineApi: ProjectTimelineApi
    ) {
    }

    get stagesFormArray(): FormArray {
        return this.timelineForm.get('stages') as FormArray;
    }

    protected get maxHeight(): number {
        return this.timeline.stages?.length * this.HEIGHT;
    }

    ngOnInit(): void {
        this.updateTimeline().then(() => {
            this.tradespeopleControlSub = this.tradespeopleControl.valueChanges.pipe(
                debounceTime(700),
                distinctUntilChanged(),
                filter(value => value !== this.timeline?.tradespeople),
                switchMap(value => {
                    this.blockUI.start('Please wait..');
                    return this.projectTimelineApi.setTradespeople(this.appState.project.id, value).pipe(
                        switchMap(() => lastValueFrom(this.projectTimelineApi.query(this.appState.project.id))),
                        tap(res => {
                            this.timelineForOneTradespeople = res.body;
                            this.timeline = this.getTimelineForTradespeople(this.timelineForOneTradespeople, value);
                            this.updateTimeline();
                        }),
                        finalize(() => {
                            this.blockUI.stop();
                        })
                    );
                })
            ).subscribe();
        });
    }

    ngOnDestroy(): void {
        this.tradespeopleControlSub.unsubscribe();
    }

    protected onBlurDaysControl(stageCtrl): void {
        this.showResetButton = true;
        this.projectTimelineApi.addCustomDuration(
            this.appState.project.id,
            stageCtrl.controls.stageId.value,
            stageCtrl.controls.days.value * this.tradespeopleControl.value).subscribe();
        this.updateTimelineView();
    }

    protected resetCustomDurations(): void {
        this.blockUI.start('Please wait..');
        this.projectTimelineApi.clearTimeline(this.appState.project.id).pipe(
            tap(() => this.showResetButton = false),
            switchMap(() => this.projectTimelineApi.query(this.appState.project.id)),
            tap(res => {
                this.timelineForOneTradespeople = res.body;
                this.timeline = this.getTimelineForTradespeople(
                    this.timelineForOneTradespeople,
                    this.timeline?.tradespeople ?? 0
                );
                this.updateTimeline();
            }),
            finalize(() => {
                this.blockUI.stop();
            })
        ).subscribe();
    }

    protected getLeft(index: number): number {
        if (index === 0 || index === 1) {
            return 0;
        }

        let left = 0;
        for (let i = 1; i < index; i++) {
            left += this.stagesFormArray.controls[i].get('days').value / 5;
        }

        return left * this.WIDTH;
    }

    protected getTop(index: number): number {
        return this.HEIGHT * index;
    }

    // Метод для обновления отображения таймлайна
    private updateTimelineView(): void {
        this.blockUI.start('Please wait..');
        setTimeout(() => {
            this.weeks = this.getWeeks();
            this.blockUI.stop();
        });
    }

    private getWeeks(): number[] {
        const timelineAreaWidth = document.getElementById('timeline-area')?.clientWidth || 0;
        this.WIDTH = Math.ceil(timelineAreaWidth / (this.timeline.totalWeeks + 1.5));
        if (this.WIDTH === 0) {
            return [];
        }

        const arrayData = Math.ceil(timelineAreaWidth / this.WIDTH);
        this.overflowXClass = arrayData !== this.timeline.totalWeeks ? 'overflow-x-hidden' : 'overflow-x-auto';
        return Array.from(Array(arrayData).keys());
    }

    private async updateTimeline(): Promise<void> {
        const res = await lastValueFrom(this.projectTimelineApi.query(this.appState.project.id));
        this.timelineForOneTradespeople = res.body;
        this.timeline = this.getTimelineForTradespeople(this.timelineForOneTradespeople, res.body.tradespeople);
        this.tradespeopleControl.setValue(res.body.tradespeople);

        this.showResetButton = this.timelineForOneTradespeople.modified;

        setTimeout(() => {
            this.weeks = this.getWeeks();
        });

        this.timelineForm = this.fb.group({
            stages: this.fb.array(this.timeline.stages.map(stage => this.createStageFormGroup(stage)))
        });
    }

    private createStageFormGroup(stage: IProjectTimelineStage): FormGroup {
        return this.fb.group({
            stageId: [stage.stageId],
            stage: [stage.stage],
            hours: [stage.hours],
            days: [stage.days]
        });
    }


    private getTimelineForTradespeople(timeline: IProjectTimeline, tradespeople: number): IProjectTimeline {
        const result = JSON.parse(JSON.stringify(timeline)); // deep copy
        result.totalWeeks = +(result.totalWeeks / tradespeople).toFixed(2);
        result.tradespeople = tradespeople;
        result.stages.forEach((stage: IProjectTimelineStage) => {
            stage.days = +(stage.days / tradespeople).toFixed(2);
            stage.hours = +(8 * stage.days).toFixed(2);
        })
        return result;
    }
}
