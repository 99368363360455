import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'app/shared/components/common/confirm-modal/confirm-modal.component';

export interface IConfirmModalData {
    header: string;
    textHtml: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    closeLinkText?: string;
    classes?: string;
    swapButtons?: boolean;
    showDoNotShowAgain?: boolean;
    showDoNotShowAgainText?: string;
    showDoNotShowAgainPlace?: 'left' | 'bottom',
    dropdownValues?: {id: number, value: string}[];
}

@Injectable({ providedIn: 'root' })
export class ConfirmModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(data: IConfirmModalData): NgbModalRef {
        this.ngbModalRef = this.modalService.open(ConfirmModalComponent as Component, {
            windowClass: 'bp-modal confirm',
            backdrop: 'static'
        });

        this.ngbModalRef.componentInstance.data = data;

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
