<div *ngIf="context.suggestedQuoters$ | async as quoters">
    <div class="flex flex-column gap-20">
        <h3>Suggested in your area:</h3>
        <div *ngIf="quoters.length; else noSuggestions" class="suggestions">
            <div *ngFor="let quoter of quoters"
                 class="suggestion">
                <div class="flex gap-20">
                    <div>
                        <img style="max-height: 50px; max-width: 50px"
                             [src]="quoter.logo ?? '/content/images/bp-logo.svg'"/>
                    </div>
                    <div class="flex flex-column gap-1">
                        <div class="overflow-ellipsis font-weight-bold"
                            [title]="quoter.company || quoter.email">{{ quoter.company || quoter.email }}
                        </div>
                        <div class="overflow-ellipsis">{{ quoter.firstName ?? " " }} {{ quoter.lastName ?? "" }}</div>
                        <div *ngIf="false && quoter.phoneNumber" class="overflow-ellipsis">
                            Tel:
                            <a [href]="'tel:' + quoter.phoneNumber" class="phone-link">
                                {{ quoter.phoneNumber }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex justify-content-between">
                    <button [disabled]="!quoter.website"
                            (click)="view(quoter)"
                            aria-hidden="true"
                            type="button"
                            class="btn btn-secondary btn-outline-info waves-effect">
                        View
                    </button>
                    <button (click)="invite(quoter)"
                            aria-hidden="true"
                            type="button"
                            class="btn btn-primary waves-effect">
                        Invite
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="context.totalSuggestedQuotersCount > quoters.length"
             class="text-center">
            <button (click)="showMore()"
                    aria-hidden="true"
                    type="button"
                    class="btn btn-primary waves-effect">
                Show more...
            </button>
        </div>

        <ng-template #noSuggestions>
            <div class="text-primary text-center">
                No suggestions
            </div>
        </ng-template>
    </div>
</div>
