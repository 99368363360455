import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    BUILD_PARTNER_SUBSCRIBE_URL,
    LEARN_MORE_RATES_AND_MARGINS_VIDEO_URL,
    RATES_ENQUIRY_DEMO_URL
} from 'app/shared/constants/links.constants';

@Component({
    selector: 'bp-update-material-and-labour-rates-info-modal',
    templateUrl: './update-material-and-labour-rates-info-modal.component.html',
    styleUrls: ['update-material-and-labour-rates-info-modal.scss']
})
export class UpdateMaterialAndLabourRatesInfoModalComponent {
    LEARN_MORE_RATES_AND_MARGINS_VIDEO_URL = LEARN_MORE_RATES_AND_MARGINS_VIDEO_URL;
    RATES_ENQUIRY_DEMO_URL = RATES_ENQUIRY_DEMO_URL;

    showVideo = false;

    constructor(private activeModal: NgbActiveModal) {
    }

    close(): void {
        this.activeModal.dismiss('close');
    }

    onBookDemoClick(): void {
        window.open(this.RATES_ENQUIRY_DEMO_URL, '_blank');
    }

    onSubscribeClick(): void {
        window.open(BUILD_PARTNER_SUBSCRIBE_URL, '_blank');
    }
}
