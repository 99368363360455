import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { IProject, Project } from "app/shared/model/project.model";
import { ProjectApi } from "app/shared/dataservices/project.api";
import { Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";
import { ApplicationStateService } from "app/core/application-state.service";

@Injectable({providedIn: 'root'})
export class ProjectResolve implements Resolve<IProject> {
    constructor(private projectApi: ProjectApi,
                private appStateService: ApplicationStateService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
        const id = route.params['project_id'] ? route.params['project_id'] : null;
        if (id) {
            return this.projectApi.find(id).pipe(
                filter((response: HttpResponse<Project>) => response.ok),
                map((projectResponse: HttpResponse<Project>) => {
                    const project: IProject = projectResponse.body;
                    this.appStateService.project = project;
                    return project;
                })
            );
        }

        const project = new Project();
        return of(project);
    }
}
