import { FactoryProvider, Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';

// Interface for describing storage key configuration
interface WebStorageKeyConfig {
    prefix?: string;
    separator?: string;
}

// Factory function for creating service instances
export function createWebStorageServiceFactory(
    webStorage: LocalStorageService,
    accountService: AccountService,
    config: WebStorageKeyConfig
): WebStorageService {
    return new WebStorageService(webStorage, accountService, config);
}

@Injectable()
export class WebStorageService {

    private readonly config: WebStorageKeyConfig; // Store the key configuration

    constructor(
        private webStorage: LocalStorageService,
        private accountService: AccountService,
        @Inject('storageKeyConfig') config: WebStorageKeyConfig // Inject the configuration
    ) {
        this.config = config;
        this.accountService.identity();
    }

    store(path: string[], value: unknown): void {
        this.webStorage.store(this.getLocalStorageKey(path), value);
    }

    retrieve(path: string[], defaultValue = false): unknown {
        return this.webStorage.retrieve(this.getLocalStorageKey(path)) ?? defaultValue;
    }

    clear(path: string[]): void {
        this.webStorage.clear(this.getLocalStorageKey(path));
    }

    // Use the configuration to generate the key
    private getLocalStorageKey(path: string[]): string {
        const { prefix = 'scheduler', separator = '_' } = this.config;
        return `${prefix}${separator}${this.accountService.getAccountId()}${separator}${path.map(p => p).join(separator)}${separator}`;
    }
}

// Provide the service with the factory function
export const StorageServiceProvider: FactoryProvider = {
    provide: WebStorageService,
    useFactory: createWebStorageServiceFactory,
    deps: [LocalStorageService, AccountService, 'storageKeyConfig'],
};
