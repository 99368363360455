import { Injectable } from '@angular/core';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AddNewProjectService {

    constructor(
        private freemiumModalService: FreemiumModalService,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
    }

    public addNew(): void {
        this.freemiumModalService.verify('create_project').then((allowed) => {
            if (allowed) {
                this.router.navigate(['../new-project'], { relativeTo: this.activatedRoute });
            }
        })
    }
}
