import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BpOnErrorService } from 'app/shared/services/bp-on-error.service';
import { Router } from "@angular/router";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private bpOnErrorService: BpOnErrorService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 302 || err.status === 0) {
                    this.router.navigate(['/login']);
                } else if (err.status !== 404) {
                    this.bpOnErrorService.showError(err);
                }
                return throwError(() => err); // Пробрасываем дальше
            })
        );
    }
}
