import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ITag } from 'app/shared/model/tag.model';
import { EntityQueryStorageService, IEntityQuery } from 'app/shared/services/entity-query-storage.service';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { IRegion } from 'app/shared/model/region.model';
import { IAccount } from 'app/shared/model/account.model';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { RegionDeleteDialogComponent } from 'app/entities/region/delete/region-delete-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'bp-tag-list',
    templateUrl: './region-list.component.html',
    styleUrls: ['region-list.scss']
})
export class RegionListComponent implements OnInit {
    protected regions: IRegion[];
    protected currentAccount: IAccount;
    protected itemsPerPage: number;
    protected page: number;
    protected predicate: any;
    protected queryCount: number;
    protected reverse: boolean;
    protected totalItems: number;

    constructor(
        private regionService: RegionApi,
        private modalService: NgbModal,
        private accountService: AccountService,
        private entityQueryStorageService: EntityQueryStorageService
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;
        this.regions = [];

        const entityQuery: IEntityQuery = entityQueryStorageService.retrieve('TAG');

        this.predicate = entityQuery.predicate || 'id';
        this.reverse = entityQuery.reverse != null ? entityQuery.reverse : true;
    }

    ngOnInit(): void {
        this.loadAll();
        this.accountService.identity().then(account => {
            this.currentAccount = account;
        });
    }

    protected reset(): void {
        this.page = 0;
        this.regions = [];
        this.saveEntityQuery();
        this.loadAll();
    }

    protected loadPage(page): void {
        this.page = page;
        this.saveEntityQuery();
        this.loadAll();
    }

    protected trackId(index: number, item: IRegion): number {
        return item.id;
    }

    protected hasMore(): boolean {
        return this.regions.length < this.totalItems;
    }

    protected onDeleteClick(region: IRegion): void {
        const ngbModalRef = this.modalService.open(RegionDeleteDialogComponent as Component, {
            size: 'lg',
            backdrop: 'static'
        });
        ngbModalRef.componentInstance.region = region;

        ngbModalRef.result.then(
            result => {
                this.reset();
            },
            reason => {

            }
        );
    }

    private loadAll(): void {
        this.regionService
            .query({
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort()
            })
            .subscribe(
                (res: HttpResponse<ITag[]>) => this.paginateRegions(res.body, res.headers)
            );
    }

    private sort(): string[] {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    private saveEntityQuery(): void {
        const entityQuery: IEntityQuery = {
            predicate: this.predicate,
            reverse: this.reverse
        };

        this.entityQueryStorageService.store('TAG', entityQuery);
    }

    private paginateRegions(data: ITag[], headers: HttpHeaders): void {
        if (this.page === 0) {
            this.regions = [];
        }

        for (let i = 0; i < data.length; i++) {
            this.regions.push(data[i]);
        }
    }
}
