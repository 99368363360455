import { ChangeDetectorRef, Injectable } from '@angular/core';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IScheduleArea, IScheduleAreaCache } from 'app/shared/model/schedule-area.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IProject } from 'app/shared/model/project.model';
import { IProjectAttachment } from 'app/shared/model/project-attachment.model';
import { FormControl } from '@angular/forms';
import { ReturnPage } from 'app/flows/scheduler/schedule/components/edit-schedule-areas/edit-schedule-areas.component';
import { ScheduleAreaApi } from 'app/shared/dataservices/schedule-area.api';
import { ProjectAreaCacheService } from 'app/shared/dataservices/project-area-cache.service';
import {
    SubmitForAnalysisModalService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/submit-for-analysis-modal/submit-for-analysis-modal.service';
import {
    SuccessfullySubmittedForAnalysisModalService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/components/successfully-submitted-for-analysis-modal/successfully-submitted-for-analysis-modal.service';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { ActivatedRoute, Router } from '@angular/router';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ProjectAttachmentsService } from 'app/shared/dataservices/project-attachments.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import {
    AreaDetailsModeStorageService
} from 'app/flows/scheduler/schedule/components/edit-schedule-areas/area-details-mode-storage.service';
import { LocalStorageService } from 'ngx-webstorage';
import { KreoApi } from 'app/shared/dataservices/kreo.api';
import { BpMathService } from 'app/shared/services/bp-math.service';
import { UserTagApi } from 'app/shared/dataservices/user-tag-api.service';
import { IKreoPropsArray, IKreoSpace } from 'app/shared/model/bp.model';
import { IScheduleAreaScope, ITag } from 'app/shared/model/tag.model';
import * as _ from 'lodash';

export type EditScheduleAreaSwitchMode = 'Width_Depth_Height_Scope' | 'WallArea_FloorArea_Perimeter';
const AREAS_WITHOUT_TAGS = 'Areas without tag';

@Injectable()
export class EditScheduleAreaServiceService {
    @BlockUI() blockUI: NgBlockUI;

    project: IProject;
    initialScheduleAreas: IScheduleArea[];

    groupedActualScheduleAreas: { [key: number]: IScheduleArea[] } = {};
    returnPage: ReturnPage = 'cost_plan';
    inProcessLoadingScheduleAreas = false;
    inProcessSavingScheduleAreas = false;
    submitted = false;
    mode: EditScheduleAreaSwitchMode = 'Width_Depth_Height_Scope';

    projectAttachments: IProjectAttachment[] = [];

    dragScheduleArea?: IScheduleArea | null = null;
    dragIndex = -1;
    dragGroupKey: string = '';
    dropIndex = -1;
    createMode = false;
    hasKreoProjectId = false;
    tagControls: { [key: number | undefined]: FormControl<string> } = {};

    useCache = false;
    autoSaveDate?: any = null;
    scheduleAreas: IScheduleArea[];
    actionWasClicked = false;
    changesWereDone = false;
    updateSub = new Subject<{saveToCache: boolean}>();
    update$ = this.updateSub.asObservable();

    private _subscriptions: Subscription[] = [];
    private _scheduleAreaScopes: IScheduleAreaScope[] = [];

    constructor(private scheduleAreaApi: ScheduleAreaApi,
                private projectAreaCacheService: ProjectAreaCacheService,
                private submitForAnalysisModalService: SubmitForAnalysisModalService,
                private successfullySubmittedForAnalysisModalService: SuccessfullySubmittedForAnalysisModalService,
                private projectApi: ProjectApi,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private alertService: BpAlertService,
                private projectAttachmentsService: ProjectAttachmentsService,
                private freemiumModalService: FreemiumModalService,
                private areaDetailsModeStorageService: AreaDetailsModeStorageService,
                private $localStorage: LocalStorageService,
                private kreoApi: KreoApi,
                private mathService: BpMathService,
                private _userTagApi: UserTagApi,
                private changeDetector: ChangeDetectorRef) {
    }

    public init(project: IProject): void {
        this.project = project;
        this.mode = this.areaDetailsModeStorageService.retrieve(this.project.id);
        this.actionWasClicked = false;
        this.reload();

        const updateSub = this.update$.subscribe((res) => {
            this.changesWereDone = true;
            this.save(false, res.saveToCache);
        })

        this._subscriptions.push(updateSub);
    }

    public close(): void {
        this._subscriptions.forEach(subs => subs.unsubscribe());
    }

    public setMode(value: EditScheduleAreaSwitchMode): void {
        this.mode = value;
        this.areaDetailsModeStorageService.store(this.project.id, value);
    }

    public onRemoveScheduleArea(scheduleArea: IScheduleArea): void {
        this.changesWereDone = true;
        scheduleArea._toRemove = true;
        this.save(false, true).then(() => {
            this.updateGroupedActualScheduleAreas();
        });
    }

    public onCopyScheduleArea(scheduleArea: IScheduleArea): void {
        this.changesWereDone = true;
        this.copyScheduleArea(scheduleArea);
    }

    public onStartToFillScheduleArea(scheduleArea: IScheduleArea): void {
        if (scheduleArea._empty) {
            this.changesWereDone = true;
            delete scheduleArea._empty;
            this.addEmptyArea(scheduleArea.tag);
        }
    }

    public onActionClick(): void {
        this.actionWasClicked = true;

        this.save(false, false).then(() => {
            if (this.createMode) {
                switch (this.returnPage) {
                    case 'cost_plan':
                        this.router.navigate(['../../dashboard', this.project.id], { relativeTo: this.activatedRoute });
                        break;
                    case 'schedule': {
                        this.router.navigate(['../../schedule', this.project.id], { relativeTo: this.activatedRoute });
                        break;
                    }
                }
            } else {
                this.reload();
            }
        })
    }

    public invalid(): boolean {
        return (this.scheduleAreas || []).find((sa) => {
            return sa._invalid
        }) != null;
    }

    public onUploadAndAutoCalculateClick(): void {
        this.freemiumModalService.verify('upload-and-auto-calculate').then((res) => {
            if (res) {
                this.submitForAnalysisModalService.showModal(this.projectAttachments).then((res) => {
                    this.refreshProjectAttachments();

                    if (res) {
                        this.successfullySubmittedForAnalysisModalService.showModal();
                    }
                });
            }
        });
    }

    public onAddTemplateClick(): void {
        this.router.navigate(['../../template-wizard', this.project.id], {
            queryParams: { returnPage: this.returnPage },
            relativeTo: this.activatedRoute
        });
    }

    public isFormValid(): boolean {
        if (this.scheduleAreas == null || this.actualAndNotEmptyScheduleAreas().length === 0) {
            this.alertService.warning('Please add at least one area');
            return false;
        }

        const duplicateSchedulerAreas = this.scheduleAreas.filter((item, index) => item.area?.length && !item._empty && !item._toRemove
            && this.scheduleAreas.filter(sa => sa.area?.toLowerCase() === item.area?.toLowerCase()).length > 1);
        if (duplicateSchedulerAreas.length) {
            duplicateSchedulerAreas.forEach(scheduleArea => scheduleArea._invalid = true);
            this.alertService.warning(`Schedule names should be different: ${duplicateSchedulerAreas.map(sa => sa.area).join(', ')}`);
            return false;
        }

        return !(this.actualAndNotEmptyScheduleAreas().find((scheduleArea: IScheduleArea) => {
            return scheduleArea.area == null || scheduleArea.area?.trim().length === 0;
        }));
    }

    public showTableHeaderForTag(key: string): boolean {
        return _.find(this.groupedActualScheduleAreas[key], sh => sh.id != null || (sh.area != null && sh.area.length > 0)) != null;
    }

    public getProxyAdmin(): boolean {
        return this.$localStorage.retrieve('proxyAdmin') ?? false;
    }

    public onAreaDragStart(event: DragEvent, scheduleArea: IScheduleArea, index: number, groupKey: string): void {
        this.dragScheduleArea = scheduleArea;
        this.dragIndex = index;
        this.dragGroupKey = groupKey;
    }

    public onAreaDragOver(event: DragEvent, index: number, htmlElement: HTMLElement): void {
        event.preventDefault();

        event.dataTransfer.dropEffect = 'move';

        if (index > this.dragIndex) {
            htmlElement.classList.add('dragover-bottom');
        } else if (index < this.dragIndex) {
            htmlElement.classList.add('dragover-top');
        }
    }

    public onAreaDragLeave(event: DragEvent, htmlElement: HTMLElement): void {
        event.preventDefault();
        htmlElement.classList.remove('dragover-top');
        htmlElement.classList.remove('dragover-bottom');
    }

    public onAreaDrop(event: DragEvent, scheduleArea: IScheduleArea, index: number, groupKey: string, htmlElement: HTMLElement): void {
        event.preventDefault();

        if (scheduleArea._empty) {
            this.dragIndex = -1;
            this.dropIndex = -1;
            return;
        }

        if (this.dragGroupKey !== groupKey) {
            this.scheduleAreaApi.move(scheduleArea.id, this.groupedActualScheduleAreas[this.dragGroupKey][this.dragIndex].tag.id)
                .subscribe((res) => {
                    this.reload();
                });
        }

        this.dropIndex = index;

        if (this.dragIndex === this.dropIndex) {
            this.dragIndex = -1;
            this.dropIndex = -1;
            return;
        }

        this.changesWereDone = true;

        this.groupedActualScheduleAreas[groupKey].splice(this.dropIndex, 0, this.groupedActualScheduleAreas[groupKey].splice(this.dragIndex, 1)[0]);

        let pos = this.groupedActualScheduleAreas[groupKey].length - 1;

        for (let i = 0; i < this.groupedActualScheduleAreas[groupKey].length; i++) {
            this.groupedActualScheduleAreas[groupKey][i].position = pos--;
        }

        this.updateGroupedActualScheduleAreas();
        this.save(false, true);
        this.dragScheduleArea = null;
        this.dragIndex = -1;
        this.dropIndex = -1;

        htmlElement.classList.remove('dragover-top');
        htmlElement.classList.remove('dragover-bottom');

        this.changeDetector.detectChanges();
    }

    public inProcess(): boolean {
        return this.inProcessLoadingScheduleAreas || this.inProcessSavingScheduleAreas;
    }

    public createAreasFromKreo(): void {
        this.blockUI.start('Creating areas from Kreo..');

        this.kreoApi.createAreasFromKreo(this.project.id).subscribe((res) => {
            this.reload().finally(() => {
                this.blockUI.stop();
            });
        }, (err: HttpErrorResponse) => {
            const message = err.error?.message || 'Cannot create areas from Kreo. There are no data';
            this.alertService.warning(message);
            this.blockUI.stop();
        });
    }

    public pullMeasurementsFromKreo(): void {
        this.blockUI.start('Pulling measurements from Kreo..');

        this.kreoApi.getKreoSpace(this.project.id).subscribe((res) => {
            const kreoSpace: IKreoSpace = res;

            this.scheduleAreas.forEach((scheduleArea, index) => {
                    for (const [key, value] of Object.entries(kreoSpace)) {
                        const kreoPropsArray: IKreoPropsArray = kreoSpace[key];
                        if (kreoPropsArray != null) {
                            const kreoProps = kreoPropsArray.find((kp) => kp.props.name === scheduleArea.area);
                            if (kreoProps?.props) {
                                if (kreoProps.props.height !== 0) {
                                    scheduleArea.height = this.mathService.roundTo2dps(kreoProps.props.height);
                                }
                                scheduleArea.width = this.mathService.roundTo2dps(kreoProps.props.thickness);
                                scheduleArea.depth = this.mathService.roundTo2dps(kreoProps.props.length);
                                scheduleArea.floorArea = this.mathService.roundTo2dps(kreoProps.props.area);
                                scheduleArea.ceilingArea = this.mathService.roundTo2dps(kreoProps.props.area);
                                scheduleArea.wallArea = this.mathService.roundTo2dps(kreoProps.props.perimeter * scheduleArea.height)
                                scheduleArea.perimeter = this.mathService.roundTo2dps(kreoProps.props.perimeter);
                                scheduleArea._fromKreo = true;
                                scheduleArea._updated.next();
                            }
                        }
                    }
                }
            );
            this.blockUI.stop();
        }, (err: HttpErrorResponse) => {
            let message = 'Cannot pull measurement from Kreo. There are no data';
            if (err.status === 423) {
                message = err.error?.message || message;
            }
            this.alertService.warning(message);
            this.blockUI.stop();
        });
    }

    public actualAndNotEmptyScheduleAreas(): IScheduleArea[] {
        return this.scheduleAreas?.filter((scheduleArea: IScheduleArea) => scheduleArea._toRemove !== true && !scheduleArea._empty).map((scheduleArea: IScheduleArea) => {
            const saCopy = Object.assign({}, scheduleArea);
            for (const [key, value] of Object.entries(saCopy)) {
                if (key.startsWith('_')) {
                    delete saCopy[key];
                }
            }
            return saCopy;
        });
    }

    public scheduleAreasToRemove(): IScheduleArea[] {
        return this.scheduleAreas?.filter((scheduleArea: IScheduleArea) => scheduleArea.id != null && scheduleArea._toRemove);
    }

    public copyScheduleArea(scheduleArea: IScheduleArea): void {
        if (this.useCache) {
            this.save(false, false).then(() => {
                this.blockUI.start('Please wait...');
                lastValueFrom(this.scheduleAreaApi.duplicate(this.project.id, scheduleArea)).then(() => {
                    this.reload().finally(() => {
                        this.blockUI.stop();
                    });
                });
            });
        } else {
            this.blockUI.start('Please wait...');
            lastValueFrom(this.scheduleAreaApi.duplicate(this.project.id, scheduleArea)).then(() => {
                this.reload().finally(() => {
                    this.blockUI.stop();
                });
            })
        }
    }

    public save(calledOnExitFromScreen = false, saveToCache: boolean = false): Promise<boolean> {
        if (calledOnExitFromScreen && this.actionWasClicked) {
            this.actionWasClicked = false;
            return Promise.resolve(true);
        }

        if (!this.changesWereDone && !this.useCache) {
            return Promise.resolve(true);
        }

        if (!saveToCache) {
            this.blockUI.start('Saving..');
        }

        this.scheduleAreas.filter((item) => item != null);
        this.scheduleAreas.forEach(scheduleArea => scheduleArea._invalid = false);

        if (!this.thereWereUpdates() && !this.useCache) {
            this.blockUI.stop();
            return Promise.resolve(true);
        }

        return new Promise(resolve => {
            if (this.inProcessSavingScheduleAreas) {
                this.blockUI.stop();
                resolve(false);
                return;
            }

            this.submitted = true;

            if (!this.isFormValid()) {
                if (!calledOnExitFromScreen) {
                    this.blockUI.stop();
                    resolve(false);
                    return;
                }

                Swal.fire({
                    title: 'You have changes on the screen which are not valid',
                    text: `Do you want to exit without saving? Or prefer to fix your changes?`,
                    icon: 'warning',
                    confirmButtonText: 'Leave the page without saving',
                    showCancelButton: true,
                    cancelButtonText: 'Fix changes'
                }).then((res: any) => {
                    this.blockUI.stop();
                    resolve(res.value);
                    return;
                });
            } else {
                this.inProcessSavingScheduleAreas = true;

                if (saveToCache) {
                    this.projectAreaCacheService.update(
                        this.project.id,
                        this.actualAndNotEmptyScheduleAreas(),
                        _.map(this.scheduleAreasToRemove(), 'id'))
                        .pipe(
                            finalize(() => {
                                this.blockUI.stop();
                                this.inProcessSavingScheduleAreas = false;
                            })
                        )
                        .subscribe(
                            (res: HttpResponse<IScheduleAreaCache>) => {
                                this.autoSaveDate = res.body.timeStamp;
                                this.useCache = true;
                                resolve(true);
                            }
                        );
                } else {
                    this.scheduleAreaApi
                        .batchUpdate(this.actualAndNotEmptyScheduleAreas(), _.map(this.scheduleAreasToRemove(), 'id'))
                        .pipe(
                            finalize(() => {
                                this.blockUI.stop();
                                this.inProcessSavingScheduleAreas = false;
                            })
                        )
                        .subscribe(
                            () => {
                                this.initialScheduleAreas = _.cloneDeep(this.actualAndNotEmptyScheduleAreas());
                                this.useCache = false;

                                if (!calledOnExitFromScreen) {
                                   // this.alertService.success('Save successful');
                                }

                                resolve(true);
                                return;
                            }
                        );
                }
            }
        });
    }

    public async reload(): Promise<void> {
        this.blockUI.start('Reloading...');

        this._userTagApi.query().subscribe((res) => {
            this.changesWereDone = false;
            this._scheduleAreaScopes = res.body;

            return Promise.all([
                this.reloadScheduleAreas(),
                this.refreshProjectAttachments(),
            ]);
        })

        this.blockUI.stop();
    }

    public getTotalAreaForTag(tagId: string): number {
        return _.sumBy(this.groupedActualScheduleAreas[tagId] || [], (scheduleArea: IScheduleArea) => {
            return +scheduleArea.floorArea || 0;
        });
    }

    private actualScheduleAreas(): IScheduleArea[] {
        return _.filter(this.scheduleAreas, (scheduleArea: IScheduleArea) => scheduleArea._toRemove !== true);
    }

    private setScheduleAreas(scheduleAreas: IScheduleArea[]): void {
        this.scheduleAreas = scheduleAreas;

        this.updateGroupedActualScheduleAreas();

        Object.keys(this.groupedActualScheduleAreas).forEach(key => {
            const tag: ITag = this.groupedActualScheduleAreas[key][0].tag;
            const userTag = this._scheduleAreaScopes.find(userTag => ('' + userTag.tag.id) === key);
            this.tagControls[key] = new FormControl(userTag?.name ?? tag?.name ?? AREAS_WITHOUT_TAGS);

            if (tag == null) {
                this.tagControls[key].disable();
            }

            this._subscriptions.push(
                this.tagControls[key].valueChanges
                    .pipe(debounceTime(700), distinctUntilChanged()).subscribe((term: string) => {
                    if (!term) {
                        return;
                    }

                    const userTag = this._scheduleAreaScopes.find(userTag => ('' + userTag.tag.id) === key);
                    if (userTag) {
                        userTag.name = term;
                        this._userTagApi.update(userTag).subscribe(res => {
                            userTag.name = res.body.name;
                            userTag.tag = res.body.tag;
                        });
                    } else {
                        const scheduleAreaScope: IScheduleAreaScope = {
                            tag: this.groupedActualScheduleAreas[key][0].tag,
                            name: term
                        }
                        this._userTagApi.create(scheduleAreaScope).subscribe(res => {
                            this._scheduleAreaScopes.push(res.body);
                        })
                    }

                })
            );
            this.addEmptyArea(tag);
        });

        if (_.find(this.scheduleAreas, sh => !sh.tag) == null) {
            this.addEmptyArea();
        }
    }

    private thereWereUpdates(): boolean {
        if (this.scheduleAreasToRemove().length) {
            return true;
        }

        const actualScheduleAreas = this.actualAndNotEmptyScheduleAreas();

        if (!this.initialScheduleAreas || !this.actualScheduleAreas) {
            return false;
        }

        if (this.initialScheduleAreas.length !== actualScheduleAreas.length) {
            return true;
        }

        for (let i = 0; i < this.initialScheduleAreas.length; i++) {
            const sa1 = this.initialScheduleAreas[i];
            const sa2 = actualScheduleAreas[i];

            if (
                sa1.id !== sa2.id ||
                sa1.area !== sa2.area ||
                sa1.width !== sa2.width ||
                sa1.height !== sa2.height ||
                sa1.depth !== sa2.depth ||
                sa1.wallArea !== sa2.wallArea ||
                sa1.floorArea !== sa2.floorArea ||
                sa1.ceilingArea !== sa2.ceilingArea ||
                sa1.doors !== sa2.doors ||
                sa1.windows !== sa2.windows ||
                sa1.perimeter !== sa2.perimeter ||
                sa1.position !== sa2.position
            ) {
                return true;
            }
        }

        return false;
    }

    private updateGroupedActualScheduleAreas(): void {
        this.groupedActualScheduleAreas = _.groupBy(
            this.actualScheduleAreas(),
            (scheduleArea: IScheduleArea) => (scheduleArea.tag || {}).id
        );

        Object.keys(this.groupedActualScheduleAreas).forEach(key => {
            this.groupedActualScheduleAreas[key] = this.groupedActualScheduleAreas[key].sort((a, b) => {
                if (a._empty) {
                    return 1;
                }
                if (b._empty) {
                    return -1;
                }

                if (a.position === b.position) {
                    return -a.id + b.id;
                }

                return -a.position + b.position;
            });
            this.groupedActualScheduleAreas[key].forEach((sa, index) => {
                if (!sa._empty && sa.id != null && (sa.position === -1 || sa.position === -2)) {
                    sa.position = Math.max(-1, this.groupedActualScheduleAreas[key].length - (index + 3));
                }
            })
        })
    }

    private loadScheduleAreas(): Promise<IScheduleArea[]> {
        return new Promise((resolve) => {
            this.inProcessLoadingScheduleAreas = true;

            this.projectApi
                .queryAvailableScheduleAreas(this.project.id)
                .pipe(
                    finalize(() => {
                        this.inProcessLoadingScheduleAreas = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IScheduleArea[]>) => {
                        resolve(res.body);
                    }
                );
        });
    }

    private addEmptyArea(tag?: ITag): void {
        if (tag === undefined && !this.tagControls["undefined"]) {
            this.tagControls["undefined"] = new FormControl(AREAS_WITHOUT_TAGS);
            this.tagControls["undefined"].disable();
        }
        const emptyArea: IScheduleArea = {
            projectId: this.project.id,
            position: -1,
            tag,
            _empty: true,
            width: 0,
            depth: 0,
            height: 0,
            wallArea: 0,
            floorArea: 0,
            ceilingArea: 0,
            doors: 1,
            windows: 1,
            perimeter: 0,
            _updated: new Subject<void>()
        };

        this.scheduleAreas.push(emptyArea);
        this.updateGroupedActualScheduleAreas();
    }

    private reloadScheduleAreas(): Promise<void> {
        return lastValueFrom(this.projectAreaCacheService.get(this.project.id)).then((scheduleAreasCache: HttpResponse<IScheduleAreaCache>) => {
            this.useCache = true;
            scheduleAreasCache.body.scheduleAreas.forEach((scheduleArea) => {
                scheduleArea._updated = new Subject<void>();
            });
            this.setSA(scheduleAreasCache.body);
        }, async () => {
            this.useCache = false;
            const scheduleAreas = await this.loadScheduleAreas();
            scheduleAreas.forEach((scheduleArea) => {
                scheduleArea._updated = new Subject<void>();
            });
            this.initialScheduleAreas = _.cloneDeep(scheduleAreas);
            this.setScheduleAreas(scheduleAreas);
        })
    }

    private setSA(scheduleAreasCache: IScheduleAreaCache): void {
        this.initialScheduleAreas = _.cloneDeep(scheduleAreasCache.scheduleAreas);
        this.autoSaveDate = scheduleAreasCache.timeStamp;
        this.setScheduleAreas(scheduleAreasCache.scheduleAreas);
    }

    private async refreshProjectAttachments(): Promise<void> {
        const resFloorPlan = await lastValueFrom(this.projectAttachmentsService.query(this.project.id, 'FLOOR_PLAN'));
        this.projectAttachments = resFloorPlan.body;
        this.hasKreoProjectId = this.projectAttachments.find(attachment => attachment.kreoProjectId != null) != null;
    }
}
