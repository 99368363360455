import { Component, Input } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'bp-dashboard-comparison',
    templateUrl: './comparison.component.html',
    styleUrls: ['comparison.scss']
})
export class DashboardComparisonComponent {
    @BlockUI() blockUI: NgBlockUI;

    @Input() useInTile: boolean = false;

    constructor(
        protected dashboardStore: DashboardStore
    ) {
    }

}
