<div [formGroup]="form"
     *ngIf="form">
    <div class="flex-between-container items-center"
         style="column-gap: 15px"
         [ngClass]="{ 'from-kreo': scheduleArea._fromKreo }">
        <div [ngStyle]="{flex: scheduleArea._empty ? '1' : '3'}">
            <div class="form-line">
                <div class="flex-line">
                    <input [formControlName]="'area'"
                           [attr.draggable]="true"
                           (dragstart)="preventDND($event)"
                           (blur)="tryToCreateAreaIfNeeded()"
                           (keyup.enter)="tryToCreateAreaIfNeeded()"
                           [ngClass]="{ 'error': scheduleArea._invalid || (!scheduleArea._empty && (service.submitted || form.controls.area.dirty || form.controls.area.touched) && form.controls.area.invalid), 'disabled': service.inProcess() }"
                           autocomplete="off"
                           class="af-input area-name"
                           placeholder="Add blank area"
                           name="area"
                           type="text">
                    <div
                        *ngIf="!scheduleArea._empty && (service.submitted || form.controls.area.dirty || form.controls.area.touched) && form.controls.area?.errors?.required"
                        class="error-container">
                        <small class="error">
                            This field is required.
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!scheduleArea._empty">

            <ng-container *ngIf="service.mode === 'Width_Depth_Height_Scope'">
                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'width'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.width.dirty || form.controls.width.touched) && form.controls.width.invalid, 'disabled': service.inProcess() }"
                                   autocomplete="off"
                                   class="bordered-input"
                                   min="0"
                                   name="width"
                                   type="number">
                            <span>m</span>
                            <div
                                *ngIf="(service.submitted || form.controls.width.dirty || form.controls.width.touched) && form.controls.width?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'depth'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.depth.dirty || form.controls.depth.touched) && form.controls.depth.invalid, 'disabled': service.inProcess() }"
                                   autocomplete="off"
                                   class="bordered-input bordered-input"
                                   min="0"
                                   name="depth"
                                   type="number"><span>m</span>
                            <div
                                *ngIf="(service.submitted || form.controls.depth.dirty || form.controls.depth.touched) && form.controls.depth?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'height'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.height.dirty || form.controls.height.touched) && form.controls.height.invalid, 'disabled': service.inProcess()  }"
                                   autocomplete="off"
                                   class="bordered-input"
                                   min="0"
                                   name="height"
                                   type="number"><span>m</span>
                            <div
                                *ngIf="(service.submitted || form.controls.height.dirty || form.controls.height.touched) && form.controls.height?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <bp-select-input
                                (onSelectionChange)="onScopeSelectionChange($event)"
                                [disabled]="service.inProcess()"
                                *ngIf="scopeSelectInputData"
                                [data]="scopeSelectInputData"
                                [noFoundText]="noScopeFoundText"
                                class="search-component-scope w-full"
                                [placeholder]="'Open to select scope'">
                            </bp-select-input>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="service.mode === 'WallArea_FloorArea_Perimeter'">
                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'wallArea'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.wallArea.dirty || form.controls.wallArea.touched) && form.controls.wallArea.invalid, 'disabled': service.inProcess() }"
                                   autocomplete="off"
                                   class="bordered-input"
                                   min="0"
                                   name="wallArea"
                                   type="number">
                            <span>m<sup>2</sup></span>
                            <div
                                *ngIf="(service.submitted || form.controls.wallArea.dirty || form.controls.wallArea.touched) && form.controls.wallArea?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'floorArea'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.floorArea.dirty || form.controls.floorArea.touched) && form.controls.floorArea.invalid, 'disabled': service.inProcess() }"
                                   autocomplete="off"
                                   class="bordered-input"
                                   min="0"
                                   name="floorArea"
                                   type="number">
                            <span>m<sup>2</sup></span>
                            <div
                                *ngIf="(service.submitted || form.controls.floorArea.dirty || form.controls.floorArea.touched) && form.controls.floorArea?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'ceilingArea'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   (keydown)="ceilingAreaWasUpdated = true;"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.ceilingArea.dirty || form.controls.ceilingArea.touched) && form.controls.ceilingArea.invalid, 'disabled': service.inProcess() }"
                                   autocomplete="off"
                                   class="bordered-input"
                                   min="0"
                                   name="ceilingArea"
                                   type="number">
                            <span>m<sup>2</sup></span>
                            <div
                                *ngIf="(service.submitted || form.controls.ceilingArea.dirty || form.controls.ceilingArea.touched) && form.controls.ceilingArea?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="flex: 1">
                    <div class="form-line">
                        <div class="flex-line">
                            <input [formControlName]="'perimeter'"
                                   [attr.draggable]="true"
                                   (dragstart)="preventDND($event)"
                                   [ngClass]="{ 'error': (service.submitted || form.controls.perimeter.dirty || form.controls.perimeter.touched) && form.controls.perimeter.invalid, 'disabled': service.inProcess() }"
                                   autocomplete="off"
                                   class="bordered-input"
                                   min="0"
                                   name="perimeter"
                                   type="number"><span>m</span>
                            <div
                                *ngIf="(service.submitted || form.controls.perimeter.dirty || form.controls.perimeter.touched) && form.controls.perimeter?.errors?.required"
                                class="error-container">
                                <small class="error">
                                    This field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="no-border controls">
                <div class="form-line">
                    <div class="flex-line"
                         style="justify-content: flex-end;">
                        <button (click)="onDeleteScheduleAreaClick()"
                                [disabled]="service.inProcess()"
                                class="btn btn-circle btn-small btn-secondary close"
                                ngbTooltip="Delete schedule area"
                                placement="left"
                                type="button">
                            <i class="material-icons md-18">close</i>
                        </button>

                        <button (click)="onCopyScheduleAreaClick()"
                                [disabled]="service.inProcess()"
                                class="btn btn-circle btn-small btn-secondary copy"
                                ngbTooltip="Copy schedule area"
                                placement="left"
                                type="button">
                            <i class="material-icons md-18">content_copy</i>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
