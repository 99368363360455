import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { HttpResponse } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { Observable, Observer, Subscription } from 'rxjs';
import { ProjectFilterStateStorageService } from 'app/shared/services/project-filter-state-storage.service';
import { ProjectListComponent } from 'app/shared/components/projects/project-list/project-list.component';
import { FormControl } from '@angular/forms';
import { PROJECT_STATES, ProjectState } from 'app/shared/constants/project-states.constants';

/**
 * Project list for the Quoter
 */
@Component({
    selector: 'bp-quoter-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['projects.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
    projectCount: number;

    searchControl = new FormControl();
    searchSub = Subscription.EMPTY;

    inProcess = false;

    PROJECT_STATE_FILTER = PROJECT_STATES;

    projectStateSelectInputData: SelectInputData;
    projectStateFilterId: ProjectState;

    @ViewChild(ProjectListComponent) projectListComponent: ProjectListComponent;

    constructor(
        private projectApi: ProjectApi,
        private filterStateStorage: ProjectFilterStateStorageService
    ) {
    }

    ngOnInit(): void {
        this.projectStateFilterId = this.filterStateStorage.retrieve();

        this.projectStateSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'label',
            initIndex: this.projectStateFilterId,
            data: this.PROJECT_STATE_FILTER,
            dropdownPosition: 'bottom',
            clearable: false,
            searchable: false
        };

        this.searchSub = this.searchControl.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe((term: string) => {
            if (this.projectListComponent != null) {
                this.projectListComponent.searchValue = term;
                this.projectListComponent.reset();
            }
        });

        this.loadCountOfProjects().subscribe(() => {
        });
    }

    ngOnDestroy(): void {
        this.searchSub?.unsubscribe();
    }

    loadCountOfProjects(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.inProcess = true;

            const queryParams: any = {
                page: 0,
                size: 1,
                sort: 'id,desc'
            };

            this.projectApi
                .query(queryParams)
                .pipe(
                    finalize(() => {
                        this.inProcess = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IProject[]>) => {
                        this.projectCount = parseInt(res.headers.get('X-Total-Count'), 10);

                        observer.next();
                        observer.complete();
                    }
                );
        });
    }

    onChangeProjectState(event: { id: ProjectState }): void {
        this.projectStateFilterId = event.id;
        this.filterStateStorage.store(this.projectStateFilterId);

        this.searchControl.setValue(null);

        if (this.projectListComponent != null) {
            this.projectListComponent.projectStateFilterId = this.projectStateFilterId;
            this.projectListComponent.searchValue = null;
            this.projectListComponent.reset();
        }
    }
}
