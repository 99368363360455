import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ServiceSelectorModalService
} from "app/shared/components/projects/service-selector-modal/service-selector-modal.service";
import { IEstimatingAddon } from "app/shared/model/bp.model";
import { EstimatingAddonService } from "app/shared/dataservices/estimating-addon.service";
import { Router } from "@angular/router";
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-review-by-qs-modal',
    templateUrl: './review-by-qs-modal.component.html',
    styleUrls: ['review-by-qs-modal.scss']
})
export class ReviewByQsModalComponent {

    constructor(private activeModal: NgbActiveModal,
                private appStateService: ApplicationStateService,
                private router: Router,
                private estimatingAddonService: EstimatingAddonService,
                private serviceSelectorModalService: ServiceSelectorModalService) {
    }

    protected goToStartServiceFlow(): void {
        this.activeModal.dismiss('close');

        this.estimatingAddonService.queryAllServices().then(addons => {
            this.serviceSelectorModalService.open(addons).result.then(
                (res: { addon: IEstimatingAddon }) => {
                    this.appStateService.resetProject();
                    this.router.navigate(
                        ['scheduler', 'new-project'],
                        { queryParams: { addonId: res.addon.id } }
                    )
                })
        })
    }

    protected clear(): void {
        this.activeModal.dismiss('close');
    }
}
