import { Component, OnDestroy } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IScheduleTask, ScheduleTask } from 'app/shared/model/schedule-task.model';
import { HttpResponse } from '@angular/common/http';
import { ScheduleTaskApi } from 'app/shared/dataservices/schedule-task.api';
import {
    IScheduleTaskSaveByQuoterUpd,
    SaveScheduleTasksByQuoterEffect
} from 'app/shared/model/schedule-task-save-by-quoter-upd.model';
import * as _ from 'lodash';
import { IActualLabourComponent } from 'app/shared/model/actual-labour-component.model';
import { IActualMaterialComponent } from 'app/shared/model/actual-material-component.model';
import { finalize } from 'rxjs/operators';
import { BpMathService } from 'app/shared/services/bp-math.service';
import { Subscription } from 'rxjs';
import Swal from "sweetalert2";

export type QuoteTaskReturnPage = 'quote' | 'schedule';

@Component({
    selector: 'bp-quote-task-details',
    templateUrl: './quote-task-details.component.html',
    styleUrls: ['quote-task-details.scss']
})
export class QuoteTaskDetailsComponent implements OnDestroy {
    protected project: IProject;
    protected scheduleTask: IScheduleTask;

    protected routeDataSub: Subscription = Subscription.EMPTY;
    protected routeQuerySub: Subscription = Subscription.EMPTY;

    protected inProcessReloadScheduleTask = false;
    protected inProcessSaveQuoteTask = false;

    protected NA = '';

    protected saveEffect: SaveScheduleTasksByQuoterEffect = 'SINGLE_TASK';

    protected returnPage: QuoteTaskReturnPage = 'quote';
    protected thereWereChanges = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private scheduleTaskApi: ScheduleTaskApi,
        private router: Router,
        private bpMathService: BpMathService
    ) {
        this.routeDataSub = this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
            this.returnPage = data.returnPage;
            this.scheduleTask = data.scheduleTask;
            this.prepareMargins();
        });

        this.routeQuerySub = this.activatedRoute.queryParams.subscribe(params => {
            if (params['returnPage']) {
                this.returnPage = params['returnPage'];
            }
        });
    }

    ngOnDestroy(): void {
        this.routeDataSub.unsubscribe();
        this.routeQuerySub.unsubscribe();
    }

    protected showSwal() {
        Swal.fire({
            title: 'Would you like to save these rates to your project database?',
            text: '(This will affect all other instances of this task in this project)\n',
            icon: 'warning',
            showCancelButton: true,
            backdrop: false,
            customClass: {
                popup: 'quote-task-save-popup',
                title: 'quote-task-save-html-title',
                htmlContainer: 'quote-task-save-html-container',
                actions: 'w-full',
                confirmButton: 'btn btn-primary flex-1 btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-warning flex-1 btn-border-radius waves-effect',
                input: 'top-checkbox'
            },
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, this task only',
            input: 'checkbox',
            inputPlaceholder: 'Overwrite default rates (applies rate to your future projects)',
        }).then((result) => {
            if (result.isDenied) {
                return;
            }

            if (result.isConfirmed) {
                this.saveEffect = result.value === 1 ? 'GLOBAL' : 'PROJECT';
            }
            if (result.isDismissed) {
                this.saveEffect = 'SINGLE_TASK';
            }

            this.onSaveTaskClick();
        });
    }

    protected onSaveTaskClick(): void {
        this.inProcessSaveQuoteTask = true;

        const taskSaveByQuoterUpd: IScheduleTaskSaveByQuoterUpd = {
            id: this.scheduleTask.id,
            isProvisional: this.scheduleTask.isProvisional,
            isSubcontract: this.scheduleTask.isSubcontract,
            provisionalCost: this.scheduleTask.provisionalCost,
            note: this.scheduleTask.note,
            actualLabourComponents: _.map(this.scheduleTask.actualLabourComponents, (l: IActualLabourComponent) => {
                return {
                    id: l.id,
                    hours: l.hours,
                    margin: l._margin / 100,
                    subcontractCost: l.subcontractCost,
                    cost: l.cost
                };
            }),
            actualMaterialComponents: _.map(this.scheduleTask.actualMaterialComponents, (m: IActualMaterialComponent) => {
                return {
                    id: m.id,
                    margin: m._margin / 100,
                    subcontractCost: m.subcontractCost,
                    cost: m.cost
                };
            })
        };

        this.scheduleTaskApi
            .saveByQuoter(taskSaveByQuoterUpd, this.saveEffect)
            .pipe(
                finalize(() => {
                    this.inProcessSaveQuoteTask = false;
                })
            )
            .subscribe(
                () => {
                    // in general next line does not make sense if we navigate to quote screen after saving.
                    // but leave this for consistence
                    this.thereWereChanges = false;
                    this.reloadScheduleTask();
                   // this.alertService.success('Save successful');
                    this.navigateToListScreen();
                }
            );
    }

    protected labourComponentTotalPerUnit(labourComp: IActualLabourComponent): number {
        if (!this.scheduleTask.isSubcontract) {
            return labourComp._ratePerUnit * (1 + labourComp._margin / 100);
        } else {
            return labourComp.subcontractCost * (1 + labourComp._margin / 100);
        }
    }

    protected materialComponentTotalPerUnit(materialComp: IActualMaterialComponent): number {
        if (!this.scheduleTask.isSubcontract) {
            return materialComp._ratePerUnit * (1 + materialComp._margin / 100);
        } else {
            return materialComp.subcontractCost * (1 + materialComp._margin / 100);
        }
    }

    protected total(): number {
        return (
            _.sumBy(this.scheduleTask.actualLabourComponents, alc => this.labourComponentTotalPerUnit(alc)) +
            _.sumBy(this.scheduleTask.actualMaterialComponents, alc => this.materialComponentTotalPerUnit(alc))
        );
    }

    protected actualLabourHoursChanged(value: number, actualLabourComponent: IActualLabourComponent): void {
        this.thereWereChanges = true;
        actualLabourComponent.hours = value;
        actualLabourComponent._hoursPerUnit = this.bpMathService.roundTo2dps(value / this.scheduleTask.unitValue);
        actualLabourComponent._ratePerUnit = this.bpMathService.roundTo2dps(
            actualLabourComponent._hoursPerUnit * actualLabourComponent.cost
        );
    }

    protected actualLabourCostChanged(value: number, actualLabourComponent: IActualLabourComponent): void {
        this.thereWereChanges = true;
        actualLabourComponent.cost = value;
        actualLabourComponent._ratePerUnit = this.bpMathService.roundTo2dps(
            actualLabourComponent._hoursPerUnit * actualLabourComponent.cost
        );
    }

    protected actualLabourHoursPerUnitChanged(value: number, actualLabourComponent: IActualLabourComponent): void {
        this.thereWereChanges = true;
        actualLabourComponent._hoursPerUnit = value;
        actualLabourComponent._ratePerUnit = this.bpMathService.roundTo2dps(
            actualLabourComponent._hoursPerUnit * actualLabourComponent.cost
        );
        actualLabourComponent.hours = this.bpMathService.roundTo2dps(actualLabourComponent._hoursPerUnit * this.scheduleTask.unitValue);
    }

    protected actualLabourRatePerUnitChanged(value: number, actualLabourComponent: IActualLabourComponent): void {
        this.thereWereChanges = true;
        actualLabourComponent._ratePerUnit = value;
        actualLabourComponent._hoursPerUnit = actualLabourComponent._ratePerUnit / actualLabourComponent.cost;
        actualLabourComponent.hours = this.bpMathService.roundTo2dps(actualLabourComponent._hoursPerUnit * this.scheduleTask.unitValue);
    }

    protected actualMaterialCostChanged(value: number, actualMaterialComponent: IActualMaterialComponent): void {
        this.thereWereChanges = true;
        actualMaterialComponent.cost = value;
        actualMaterialComponent._ratePerUnit = this.bpMathService.roundTo2dps(
            actualMaterialComponent._amountPerUnit * actualMaterialComponent.cost
        );
    }

    protected inProcess(): boolean {
        return this.inProcessSaveQuoteTask || this.inProcessReloadScheduleTask;
    }

    protected onIsProvisionalChange(): void {
        this.thereWereChanges = true;

        if (this.scheduleTask.isProvisional) {
            this.scheduleTask.isSubcontract = false;

            this.scheduleTask.provisionalCost =
                this.scheduleTask.unitValue *
                (this.scheduleTask.actualMaterialComponents.reduce(
                        (subtotal, item) => subtotal + this.materialComponentTotalPerUnit(item),
                        0
                    ) +
                    this.scheduleTask.actualLabourComponents.reduce(
                        (subtotal, item) => subtotal + this.labourComponentTotalPerUnit(item),
                        0
                    ));
        } else {
            this.scheduleTask.provisionalCost = null;
        }
    }

    protected onIsSubcontractChange(): void {
        this.thereWereChanges = true;

        if (this.scheduleTask.isSubcontract) {
            this.scheduleTask.isProvisional = false;
        } else {
            this.scheduleTask.actualLabourComponents.forEach(alc => {
                this.actualLabourHoursChanged(alc.previousHours || alc.hours, alc);
            })
        }
    }

    protected showSaveWithConfirmToSaveToDatabase(): boolean {
        return this.thereWereChanges && !this.scheduleTask.isSubcontract && !this.scheduleTask.isProvisional;
    }

    private prepareMargins(): void {
        _.each(this.scheduleTask.actualLabourComponents, (actualLabourComponent: IActualLabourComponent) => {
            actualLabourComponent._margin = this.bpMathService.roundTo2dps(actualLabourComponent.margin * 100);
            actualLabourComponent._hoursPerUnit = this.bpMathService.roundTo2dps(actualLabourComponent.hours / this.scheduleTask.unitValue);
            actualLabourComponent._ratePerUnit = this.bpMathService.roundTo2dps(
                actualLabourComponent._hoursPerUnit * actualLabourComponent.cost
            );
        });

        _.each(this.scheduleTask.actualMaterialComponents, (actualMaterialComponent: IActualMaterialComponent) => {
            actualMaterialComponent._margin = this.bpMathService.roundTo2dps(actualMaterialComponent.margin * 100);
            actualMaterialComponent._amountPerUnit = this.bpMathService.roundTo2dps(
                actualMaterialComponent.amount / this.scheduleTask.unitValue
            );
            actualMaterialComponent._ratePerUnit = this.bpMathService.roundTo2dps(
                actualMaterialComponent._amountPerUnit * actualMaterialComponent.cost
            );
        });
    }

    private reloadScheduleTask(): void {
        this.inProcessReloadScheduleTask = true;

        this.scheduleTaskApi
            .find(this.scheduleTask.id)
            .pipe(
                finalize(() => {
                    this.inProcessReloadScheduleTask = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ScheduleTask>) => {
                    this.scheduleTask = res.body;
                    this.prepareMargins();
                }
            );
    }

    private navigateToListScreen(): void {
        switch (this.returnPage) {
            case 'quote': {
                this.router.navigate(['/', 'quoter', 'quote', this.project.id]);
                break;
            }
            case 'schedule': {
                this.router.navigate(['/', 'scheduler', 'schedule', this.project.id]);
                break;
            }
        }

    }
}
