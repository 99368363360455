import { IScheduleTask } from './schedule-task.model';
import { IStage } from './stage.model';
import { ISchedule } from './schedule.model';
import { ISpecification } from 'app/shared/model/specification.model';
import { ITag } from 'app/shared/model/tag.model';
import { Subject } from 'rxjs';
import { IScope } from "app/shared/model/bp.model";

export interface IScheduleArea {
    id?: number;
    area?: string;
    width?: number;
    depth?: number;
    height?: number;
    copyID?: number;
    masterSchID?: number;
    wallArea?: number;
    floorArea?: number;
    ceilingArea?: number;
    doors?: number;
    windows?: number;
    perimeter?: number;
    clientSupplied?: boolean;
    specId?: number;
    projectId?: number;
    scheduleTasks?: IScheduleTask[];
    stages?: IStage[];
    spec?: ISpecification;
    schedule?: ISchedule;
    tag?: ITag;
    position?: number;
    selected?: boolean;
    expanded?: boolean;
    _empty?: boolean;
    _toRemove?: boolean;
    _invalid?: boolean;
    _fromKreo?: boolean;

    _updated?: Subject<void>;
    scope?: IScope;
}

export interface IScheduleAreaCache {
    timeStamp: number;
    deleteAreas: number[];
    scheduleAreas: IScheduleArea[];
}

export class ScheduleArea implements IScheduleArea {
    constructor(
        public id?: number,
        public area?: string,
        public width?: number,
        public depth?: number,
        public height?: number,
        public copyID?: number,
        public masterSchID?: number,
        public wallArea?: number,
        public floorArea?: number,
        public ceilingArea?: number,
        public doors?: number,
        public windows?: number,
        public perimeter?: number,
        public clientSupplied?: boolean,
        public specId?: number,
        public projectId?: number,
        public scheduleTasks?: IScheduleTask[],
        public stages?: IStage[],
        public spec?: ISpecification,
        public schedule?: ISchedule,
        public tag?: ITag,
        public selected?: boolean,
        public expanded?: boolean,
        public _empty?: boolean,
        public _toRemove?: boolean
    ) {}
}
