<div class="modal-header">
    <div class="text-center w-full">
        <h2>
            Request Callback
        </h2>
    </div>
    <button (click)="clear()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="callbackForm">
        <div class="form-group">
            <label class="form-control-label"
                   for="name">
                Name
            </label>
            <div class="form-line">
                <input
                    class="af-input"
                    formControlName="name"
                    name="name"
                    id="name"
                    type="text"
                    autocomplete="off"
                    required
                    autofocus>
            </div>
            <label *ngIf="submitted && callbackForm.controls.name.invalid && callbackForm.controls.name.touched"
                   class="error">
                Name is required.
            </label>
        </div>

        <div class="form-group">
            <label class="form-control-label"
                   for="mail">
                Email
            </label>
            <div class="form-line">
                <input
                    class="af-input"
                    formControlName="mail"
                    name="mail"
                    id="mail"
                    type="email"
                    autocomplete="off"
                    required
                    autofocus>
            </div>
            <div *ngIf="submitted && callbackForm.controls.mail.dirty && callbackForm.controls.mail.invalid">
                <small *ngIf="callbackForm.controls.mail.errors.required"
                       class="form-text text-danger">
                    Email is required
                </small>
                <small *ngIf="callbackForm.controls.mail.errors.maxlength"
                       class="form-text text-danger">
                    Email cannot be longer than 100 characters.
                </small>
                <small *ngIf="callbackForm.controls.mail.errors.email"
                       class="form-text text-danger">
                    Email is incorrect.
                </small>
            </div>
        </div>

        <div class="form-group">
            <label class="form-control-label"
                   for="mail">
                Phone
            </label>
            <div class="form-line">
                <input
                    class="af-input"
                    formControlName="phone"
                    name="phone"
                    id="phone"
                    type="text"
                    autocomplete="off"
                    required
                    autofocus>
            </div>
            <label *ngIf="submitted && callbackForm.controls.phone.invalid && callbackForm.controls.phone.touched"
                   class="error">
                Phone is required.
            </label>
        </div>

        <div class="form-group">
            <label class="form-control-label"
                   for="note">
                Notes
            </label>
            <div class="form-line">
                <textarea formControlName="note"
                          [placeholder]="'Any additional notes or details'"
                          id="note">
                </textarea>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer flex gap-10 justify-content-end">
    <button class="btn btn-primary btn-border-radius waves-effect"
            (click)="onSubmit()"
            [disabled]="callbackForm.invalid"
            type="button">
        Submit
    </button>
</div>

