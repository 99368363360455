import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'successfully-successfully-updated-plan-modal',
    templateUrl: './successfully-updated-plan-modal.component.html',
    styleUrls: ['successfully-updated-plan-modal.scss']
})
export class SuccessfullyUpdatedPlanModalComponent {

    constructor(
        public activeModal: NgbActiveModal,
    ) {
    }

    onOkClick(): void {
        this.activeModal.close(true);
    }

    clear(): void {
        this.activeModal.close(true);
    }
}
