import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    RequestCallbackModalComponent
} from "app/flows/scheduler/schedule/components/request-callback-modal/request-callback-modal.component";

@Injectable({ providedIn: 'root' })
export class RequestCallbackModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {
    }

    open(): NgbModalRef {
        this.ngbModalRef = this.modalService.open(RequestCallbackModalComponent as Component, {
            windowClass: 'bp-modal request-callback-modal',
            backdrop: 'static'
        });

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
