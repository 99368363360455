import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { QuoterApi } from 'app/shared/dataservices/quoter.api';
import { finalize } from 'rxjs/operators';
import { ITaskLabour } from 'app/shared/model/task-labour.model';
import Swal from "sweetalert2";

@Component({
    selector: 'bp-quoter-task-labour-rates',
    templateUrl: './task-labour-rates.component.html',
    styleUrls: ['task-labour-rates.scss']
})
export class TaskLabourRatesComponent implements OnInit {
    protected taskLabours: ITaskLabour[];

    protected inProcessLoadingTaskLabours = false;
    protected inProcessDeleteTaskLabour = false;

    constructor(
        private quoterService: QuoterApi
    ) {
        this.taskLabours = [];
    }

    ngOnInit(): void {
        this.loadAll();
    }

    protected loadAll(): void {
        this.inProcessLoadingTaskLabours = true;

        this.quoterService
            .queryTaskLabours()
            .pipe(
                finalize(() => {
                    this.inProcessLoadingTaskLabours = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ITaskLabour[]>) => this.paginate(res.body)
            );
    }

    protected inProcess(): boolean {
        return this.inProcessLoadingTaskLabours || this.inProcessDeleteTaskLabour;
    }

    protected confirmDeleteTaskLabour(labour: ITaskLabour): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this task labour!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.inProcessDeleteTaskLabour = true;

                this.quoterService
                    .deleteTaskLabour(labour.id)
                    .pipe(
                        finalize(() => {
                            this.inProcessDeleteTaskLabour = false;
                        })
                    )
                    .subscribe(
                        () => {
                            const index: number = this.taskLabours.indexOf(labour);
                            if (index !== -1) {
                                this.taskLabours.splice(index, 1);
                            }
                        }
                    );
            }
        });
    }

    private paginate(data: ITaskLabour[]): void {
        for (const taskLabour of data) {
            this.taskLabours.push(taskLabour);
        }
    }
}
