import { Component } from '@angular/core';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { ApplicationStateService } from "app/core/application-state.service";

@Component({
    selector: 'bp-schedule-root-container',
    templateUrl: './root-container.component.html',
    styleUrls: [
        '../../schedule-common.scss',
        'root-container.scss',
    ]
})
export class ScheduleRootContainerComponent {

    constructor(protected scheduleService: ScheduleService,
                protected appState: ApplicationStateService) {
    }
}
