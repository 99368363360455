<form (ngSubmit)="create()"
      name="addTaskForm">
    <div class="modal-header">
        <h4 class="modal-title">
            Create a Task
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <form #editForm="ngForm"
              name="editForm"
              novalidate
              role="form">
            <div class="row">
                <div class="col-sm-12">
                    <div [hidden]="!task.id"
                         class="form-group">
                        <input [(ngModel)]="task.id"
                               class="af-input"
                               id="id"
                               name="id"
                               placeholder="Id"
                               readonly
                               type="text"/>
                    </div>
                    <div class="form-group">
                        <input [(ngModel)]="task.task"
                               class="af-input"
                               id="field_task"
                               name="task"
                               placeholder="Task description"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.task?.dirty && editForm.controls.task?.invalid)">
                            <small [hidden]="!editForm.controls.task?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <select (change)="onComponentAreaChange()"
                                [(ngModel)]="task.componentarea"
                                class="form-control af-select"
                                id="field_componentarea"
                                required
                                name="componentarea">
                            <option [ngValue]="null">
                                Component Area
                            </option>
                            <option
                                *ngFor="let componentAreaOption of componentareas; trackBy: trackComponentAreaById"
                                [ngValue]="componentAreaOption.id === task.componentarea?.id ? task.componentarea : componentAreaOption">
                                {{componentAreaOption.componentArea + ', ' + componentAreaOption?.unit?.unit}}
                            </option>
                        </select>
                        <div
                            [hidden]="!(editForm.controls.componentarea?.dirty && editForm.controls.componentarea?.invalid)">
                            <small [hidden]="!editForm.controls.componentarea?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <select [(ngModel)]="task.unit"
                                [disabled]="task?.componentarea?.componentArea !== NA"
                                class="form-control af-select"
                                id="field_unit"
                                name="unit">
                            <option [ngValue]="null">
                                Select Unit
                            </option>
                            <option *ngFor="let unitOption of units; trackBy: trackUnitById"
                                    [ngValue]="unitOption.id === task.unit?.id ? task.unit : unitOption">
                                {{unitOption.unit}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <select (change)="onMaterialCategoryChanged()"
                                [(ngModel)]="task.materialCategories[0]"
                                class="form-control af-select"
                                id="field_material_category"
                                name="material_category">
                            <option [ngValue]="null">
                                Select Material Category
                            </option>
                            <option
                                *ngFor="let materialCategoryOption of materialCategories; trackBy: trackMaterialCategoryById"
                                [ngValue]="materialCategoryOption.id === task.materialCategories[0]?.id ? task.materialCategories[0] : materialCategoryOption">{{materialCategoryOption.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <select (change)="onStageSelect()"
                                [(ngModel)]="task.stage"
                                class="form-control af-select"
                                id="field_stage"
                                required
                                name="stage">
                            <option [ngValue]="null">
                                Select Stage
                            </option>
                            <option *ngFor="let stageOption of stages; trackBy: trackStageById"
                                    [ngValue]="stageOption.id === task.stage?.id ? task.stage : stageOption">
                                {{stageOption.stage}}
                            </option>
                        </select>
                        <div [hidden]="!(editForm.controls.stage?.dirty && editForm.controls.stage?.invalid)">
                            <small [hidden]="!editForm.controls.stage?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <select [(ngModel)]="task.element"
                                class="form-control af-select"
                                id="field_element"
                                required
                                name="element">
                            <option [ngValue]="null">
                                Select Element
                            </option>
                            <option
                                *ngFor="let elementOption of elements; trackBy: trackElementById"
                                [ngValue]="elementOption.id === task.element?.id ? task.element : elementOption">
                                {{elementOption.element}}
                            </option>
                        </select>
                        <div [hidden]="!(editForm.controls.element?.dirty && editForm.controls.element?.invalid)">
                            <small [hidden]="!editForm.controls.element?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="body table-responsive">
                        <table class="af-table">
                            <thead>
                            <tr>
                                <th>
                                    Labour
                                </th>
                                <th style="width: 20%">
                                    Qty
                                </th>
                                <th style="width: 15%">
                                </th>
                            </tr>
                            </thead>
                            <tbody infinite-scroll>
                            <tr *ngFor="let labourComponent of task?.labourComponents;">
                                <td style="padding-right: 20px;">
                                    <select [(ngModel)]="labourComponent.trade"
                                            [name]="'labor-trade-' + labourComponent.id"
                                            class="form-input af-select">
                                        <option [ngValue]="null"></option>
                                        <option
                                            *ngFor="let tradeOption of trades; trackBy: trackTradeById"
                                            [ngValue]="tradeOption.id === labourComponent.trade?.id ? labourComponent.trade : tradeOption">
                                            {{tradeOption.trade}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input [(ngModel)]="labourComponent.quantity"
                                           [name]="'labour-quantity' + labourComponent.id"
                                           (change)="onComponentChange()"
                                           class="af-input"
                                           type="text"/>
                                </td>
                                <td>
                                    <button (click)="removeLabourComponent(labourComponent)"
                                            class="btn btn-circle btn-secondary btn-small float-right">
                                        <i class="material-icons">close</i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <button (click)="addLabourComponent()"
                                class="btn btn-small btn-secondary btn-border-radius m-t-10">
                            Add Labour
                        </button>
                    </div>
                </div>
            </div>
            <div class="row m-t-10">
                <div class="col-sm-12">
                    <div class="body table-responsive">
                        <table class="af-table materials">
                            <thead>
                            <tr>
                                <th>
                                    Material
                                </th>
                                <th style="width: 15%">
                                    Unit
                                </th>
                                <th style="width: 20%">
                                    Qty
                                </th>
                                <th style="width: 15%">
                                </th>
                            </tr>
                            </thead>
                            <tbody infinite-scroll>
                            <tr *ngFor="let materialComponent of task?.materialComponents;">
                                <td style="padding-right: 20px;">
                                    <bp-search-by-select
                                        (onSelectionChange)="onMaterialComponentSelectionChange(materialComponent, $event)"
                                        *ngIf="materialComponent['_searchBySelectInputData']"
                                        [data]="materialComponent['_searchBySelectInputData']"
                                        appendTo="table.materials"
                                        class="select-material-component-id">
                                    </bp-search-by-select>
                                </td>
                                <td>
                                    <span>{{ materialComponent.material?.unit?.unit }}</span>
                                </td>
                                <td>
                                    <input [(ngModel)]="materialComponent.quantity"
                                           [name]="'material-quantity' + materialComponent.id"
                                           (change)="onComponentChange()"
                                           class="af-input"
                                           type="text"/>
                                </td>
                                <td>
                                    <button (click)="removeMaterialComponent(materialComponent)"
                                            class="btn btn-circle btn-secondary btn-small float-right">
                                        <i class="material-icons">close</i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <button (click)="addMaterialComponent()"
                                class="btn btn-small btn-secondary btn-border-radius m-t-10">
                            Add Material
                        </button>
                    </div>
                </div>
            </div>

            <div class="row m-t-20">
                <div class="col-sm-12">
                    <bp-task-total [task]="task"
                                   [updateObservable]="updateSubject">
                    </bp-task-total>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer"
         style="padding: 25px; height: 100px;">
        <button (click)="clear()"
                class="btn btn-secondary btn-border-radius"
                data-dismiss="modal"
                style="margin-right: 10px;"
                type="button">
            Cancel
        </button>
        <button [disabled]="editForm.form.invalid || isSaving"
                class="btn btn-primary btn-border-radius"
                type="submit">
            Save
        </button>
    </div>
</form>
