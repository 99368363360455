import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { IBuildUp, IBuildUpCategory } from 'app/shared/model/bp.model';
import { BuildUpApi } from 'app/shared/dataservices/build-up.api';
import { BuildUpCategoryApi } from 'app/shared/dataservices/build-up-category.api';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ITask } from 'app/shared/model/task.model';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TaskApi } from 'app/shared/dataservices/task.api';
import { NgSelectComponent } from "@ng-select/ng-select";

@UntilDestroy()
@Component({
    selector: 'bp-build-up-update',
    templateUrl: './build-up-update.component.html',
    styleUrls: ['build-up-update.scss']
})
export class BuildUpUpdateComponent implements OnInit {
    @ViewChild('myNgSelect') myNgSelect: NgSelectComponent;

    @BlockUI() protected blockUI: NgBlockUI;

    protected item: IBuildUp;
    protected isSaving: boolean;
    protected tasks: ITask[] = [];
    protected attachedTasks: ITask[] = [];
    protected detachedTasks: ITask[] = [];
    protected newTask: ITask | null = null;
    protected addingTaskInProgress: boolean;
    protected searchInProgress = false;
    protected searchControl = new FormControl();
    protected buildUpCategories: IBuildUpCategory[];

    constructor(
        private taskApi: TaskApi,
        private alertService: BpAlertService,
        private buildUpApi: BuildUpApi,
        private buildUpCategoryApi: BuildUpCategoryApi,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.isSaving = false;

        this.attachedTasks = [];
        this.detachedTasks = [];

        this.activatedRoute.data.subscribe(({ buildUp }) => {
            this.item = buildUp;
            this.item.categoryId = buildUp?.category?.id;
            this.item.tasks = this.item.tasks ?? [];
        });
        this.buildUpCategoryApi.query().subscribe(
            (res: HttpResponse<IBuildUpCategory[]>) => {
                this.buildUpCategories = res.body;
            }
        );

        this.searchControl.valueChanges.pipe(
            debounceTime(700),
            untilDestroyed(this))
            .subscribe((term: string) => {
                this.searchInProgress = true;
                this.tasks = [];
                this.taskApi
                    .query({
                        page: 0,
                        size: 30,
                        searchValue: term,
                        archive: false
                    })
                    .pipe(
                        debounceTime(500),
                        finalize(() => this.searchInProgress = false)
                    ).subscribe(
                    (res: HttpResponse<ITask[]>) => {
                        this.tasks = res.body.filter(task => {
                            return (!this.item.id || this.item.tasks?.filter(t => t.id === task.id).length === 0) &&
                                this.attachedTasks?.filter(t => t.id === task.id).length === 0
                        });
                    }
                );
            });
    }

    protected onAddTaskSelectInput(event: any): void {
        this.searchControl.setValue(this.myNgSelect.searchTerm);
    }

    protected onAddTaskSelectChanged(task: ITask): void {
        if (!task) {
            return;
        }

        this.item.tasks.push({ id: task.id, task: task.task });


        const index1 = this.detachedTasks.findIndex(t => t.id === task.id);
        if (index1 !== -1) {
            this.detachedTasks.splice(index1, 1);
        } else {
            this.attachedTasks.push(task);
        }

        this.tasks = [];
        this.myNgSelect.clearModel();
    }

    protected previousState(): void {
        window.history.back();
    }

    protected save(): void {
        this.isSaving = true;
        if (this.item.id !== undefined) {
            this.subscribeToSaveResponse(this.buildUpApi.update(this.item));
        } else {
            this.subscribeToSaveResponse(this.buildUpApi.create(this.item));
        }
    }

    protected trackById(index: number, item: IBuildUpCategory): number {
        return item.id;
    }

    protected detachTask(task: ITask): void {
        this.detachedTasks.push(task);

        const index1 = this.item.tasks.findIndex(t => t.id === task.id);
        if (index1 !== -1) {
            this.item.tasks.splice(index1, 1);
        }

        const index2 = this.attachedTasks.findIndex(t => t.id === task.id);
        if (index2 !== -1) {
            this.attachedTasks.splice(index2, 1);
        }
    }

    protected async detachAllTasksSequentially(): Promise<void> {
        if (!this.detachedTasks.length)
            return Promise.resolve();

        this.blockUI.start('Detaching all tasks...');

        for (const task of this.detachedTasks) {
            await lastValueFrom(this.buildUpApi.detachTask(this.item.id, task.id));
        }

        this.blockUI.stop();
    }

    protected async attachAllTasksSequentially(): Promise<void> {
        if (!this.attachedTasks.length)
            return Promise.resolve();

        this.blockUI.start('Attaching all tasks...');

        for (const task of this.attachedTasks) {
            await lastValueFrom(this.buildUpApi.attachTask(this.item.id, task.id));
        }

        this.blockUI.stop();
    }

    protected addTask(): void {
        this.newTask = null;
        this.addingTaskInProgress = true;
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<IBuildUp>>) {
        result.subscribe(
            (res: HttpResponse<IBuildUp>) => {
                if (!this.item.id) {
                    this.item = res.body;
                }

                this.attachAllTasksSequentially().then(() => {
                    this.detachAllTasksSequentially().then(() => {
                        this.onSaveSuccess()
                    });
                });

            },
            (res: HttpErrorResponse) => this.onSaveError()
        );
    }

    private onSaveSuccess() {
        this.isSaving = false;
        this.alertService.success('Build Up Successfully saved!');
        this.previousState();
    }

    private onSaveError() {
        this.isSaving = false;
    }
}
