import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { IArea } from 'app/shared/model/area.model';
import { IProject } from 'app/shared/model/project.model';
import { Observable } from 'rxjs';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';

@Component({
    selector: 'bp-select-schedule-area-ext-quote',
    templateUrl: './select-schedule-area-ext-quote.component.html',
    styleUrls: ['select-schedule-area-ext-quote.scss']
})
export class SelectScheduleAreaExtQuoteComponent {
    @Input() project: IProject;
    @Input() scheduleAreas: IScheduleArea[];
    @Input() selectedScheduleArea: IScheduleArea;
    @Input() canReloadScreenOrSaveScheduleCache: () => Observable<boolean>;

    @Output() onChanged = new EventEmitter();

    areaDropDown = false;

    constructor() {}

    onClickOutside(): void {
        this.closeAreaDropDown();
    }

    openAreaDropdown(): void {
        if (!this.areaDropDown) {
            this.areaDropDown = true;
        }
    }

    selectArea(scheduleArea: IScheduleArea): void {
        if (this.canReloadScreenOrSaveScheduleCache != null) {
            this.canReloadScreenOrSaveScheduleCache().subscribe(value => {
                if (value) {
                    this.selectedScheduleArea = scheduleArea;
                    this.onChanged.emit(this.selectedScheduleArea);
                }
                this.closeAreaDropDown();
            });
        } else {
            this.selectedScheduleArea = scheduleArea;
            this.onChanged.emit(this.selectedScheduleArea);
            this.closeAreaDropDown();
        }
    }

    closeAreaDropDown(): void {
        this.areaDropDown = false;
    }

    getAreasToShow(): IArea[] {
        return _.filter(this.scheduleAreas, (scheduleArea: IArea) => {
            return this.selectedScheduleArea.id !== scheduleArea.id;
        });
    }
}
