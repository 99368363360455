import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { IStage } from 'app/shared/model/stage.model';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { ICssElement } from "app/shared/model/bp.model";
import { IScheduleTask } from "app/shared/model/schedule-task.model";
import { IComparisonScheduleTask } from "app/shared/model/comparison-schedule-task.model";
import { ApplicationStateService } from "app/core/application-state.service";

@Injectable()
export class ComparisonStore {
    quoterIdsAsInitialFilter: number[];

    nativeScheduleAreas: IScheduleArea[];
    filteredScheduleAreas: IScheduleArea[];

    stages: IStage[];

    cssElementMap: Map<number, {
        cssElement: ICssElement,
        scheduleTasks: IComparisonScheduleTask[],
        totals: number[]
    }> = new Map<number, {
        cssElement: ICssElement,
        scheduleTasks: IScheduleTask[],
        totals: number[]
    }>();

    quoters: IComparisonQoter[] = [];

    nativeComparison: IComparison = null;
    comparison: IComparison = null;

    filterState: IMainViewFilterState;
    scheduleAreaItems: IMainViewFilterItemItem[] = [];
    stageItems: IMainViewFilterItemItem[] = [];
    cssElementItems: IMainViewFilterItemItem[] = [];

    inited = false;

    expandedAll = false;

    inProcessLoadingQoters = false;
    inProcessLoadingScheduleAreas = false;
    inProcessLoadingStages = false;
    inProcessLoadingComparison = false;
    inProcessLoadingComparisonForArea = false;
    inProcessExportingAsPDF = false;

    get project(): IProject {
        return this.appStateService.project;
    }

    constructor(
        private appStateService: ApplicationStateService
    ) {
    }

    clear(): void {
        this.quoterIdsAsInitialFilter = [];

        this.nativeScheduleAreas = [];
        this.filteredScheduleAreas = [];

        this.stages = [];

        this.quoters = [];

        this.nativeComparison = null;
        this.comparison = null;

        this.filterState = null;
        this.scheduleAreaItems = [];
        this.stageItems = [];
        this.cssElementItems = [];

        this.inited = false;

        this.expandedAll = false;

        this.inProcessLoadingQoters = false;
        this.inProcessLoadingScheduleAreas = false;
        this.inProcessLoadingStages = false;
        this.inProcessLoadingComparison = false;
        this.inProcessLoadingComparisonForArea = false;
        this.inProcessExportingAsPDF = false;
    }
}
