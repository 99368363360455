<div class="wrap-login">
    <img alt=""
         class="login-logo"
         src="../../../content/images/bp_logo.png"/>

    <form #registerForm="ngForm"
          (ngSubmit)="register()"
          class="form validate-form card"
          name="form"
          role="form"
          autocomplete="off">
        <span class="login-form-title p-b-34">
            Create Account
        </span>
        <div class="form-group">
            <select
                [(ngModel)]="region"
                class="form-control"
                id="field_region"
                name="region"
                placeholder="Region"
                required>
                <option *ngFor="let regionOption of regions"
                        [ngValue]="regionOption">{{regionOption.region}}
                </option>
            </select>
        </div>
        <div class="wrap-input validate-input"
             data-validate="Enter password">
            <input #password="ngModel"
                   [(ngModel)]="registerAccount.password"
                   class="af-input"
                   id="password"
                   pattern="^\S{4,50}$"
                   name="password"
                   placeholder="New password"
                   type="password">
            <div *ngIf="password.dirty && password.invalid">
                <small *ngIf="password.errors.pattern"
                       class="form-text text-danger">
                    Your password must not contain whitespaces. Minimum length is 4. Maximum length is 50.
                </small>
            </div>
        </div>

        <div class="wrap-input validate-input"
             data-validate="New password confirmation">
            <input #confirmPasswordInput="ngModel"
                   [(ngModel)]="confirmPassword"
                   class="af-input"
                   id="confirmPassword"
                   maxlength=50
                   minlength=4
                   name="confirmPassword"
                   placeholder="Confirm new password"
                   required
                   type="password">
            <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                <small *ngIf="confirmPasswordInput.errors.required"
                       class="form-text text-danger">
                    Your confirmation password is required.
                </small>
                <small *ngIf="confirmPasswordInput.errors.minlength"
                       class="form-text text-danger">
                    Your confirmation password is required to be at least 4 characters.
                </small>
                <small *ngIf="confirmPasswordInput.errors.maxlength"
                       class="form-text text-danger">
                    Your confirmation password cannot be longer than 50 characters.
                </small>
            </div>
        </div>

        <div class="agree-license-container">
            <div class="form-check">
                <label class="form-check-label"
                       for="agree-license">
                    I agree to Buildpartner <a [href]="TERMS_OF_USE_URL" target="_blank" rel="noopener" class="link">Terms
                    of use</a> and <a [href]="PRIVACY_POLICY_URL" target="_blank" class="link">Privacy Policy</a>
                    <input [(ngModel)]="agreeLicense"
                           class="form-check-input"
                           id="agree-license"
                           name="agree-license"
                           type="checkbox">
                    <span class="form-check-sign">
                            <span class="check"></span>
                        </span>
                </label>
            </div>
        </div>

        <div class="container-signup-form-btn">
            <button [disabled]="registerForm.form.invalid || !agreeLicense"
                    class="signup btn-primary btn-border-radius btn"
                    type="submit">
                Sign up
            </button>
        </div>

        <div class="text-center m-t-40">
            <span class="link-title">Already have an account?</span><br>
            <a (click)="login()"
               class="link">
                <span>Sign In with your credentials</span>
            </a>
        </div>

    </form>
</div>



