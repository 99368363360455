<div>
    <div class="sticky-button-container">
        <ng-container *ngIf="isAdmin">
            <button [disabled]="f.invalid"
                    (click)="showUpdateConfirmation()"
                    ngbTooltip="Save"
                    class="btn btn-primary btn-border-radius save"
                    type="button">
                Save
            </button>
        </ng-container>

        <ng-container *ngIf="!isAdmin">
            <button [disabled]="f.invalid"
                    (click)="isGlobal = false; saveAndApply()"
                    ngbTooltip="Save"
                    class="btn btn-primary btn-border-radius save"
                    type="button">
                Save
            </button>
        </ng-container>
    </div>

    <div class="flex justify-content-between gap-20 flex-wrap">
        <div class="flex-1" style="min-width: fit-content">
            <h1>
                Default labour rates
                <a (click)="showHelpModal()">
                    <i class="material-icons">info_outline</i>
                </a>
            </h1>
        </div>
        <div class="flex-1 text-right" style="margin-right: 80px">
            <button (click)="setToDefaults()"
                    class="btn btn-secondary btn-border-radius"
                    type="button">
                Restore default rates
            </button>
        </div>
    </div>

    <div class="card materials m-t-20">
        <div class="header">
            <div class="flex justify-content-end align-items-center gap-40 flex-wrap m-b-20">
                <div class="flex gap-10 align-items-baseline flex-wrap">
                    <h4>
                        Margin
                    </h4>

                    <div class="percent">
                        <input [(ngModel)]="dataModel.margin"
                               class="af-input num text-right"
                               placeholder="Enter margin"
                               required
                               mask="separator.2"
                               [dropSpecialCharacters]="true"
                               suffix="%"
                               thousandSeparator=","
                               type="text">
                    </div>
                </div>


                <button (click)="applyMarginToAll()"
                        [disabled]="!dataModel.isMarginValid()"
                        class="btn btn-primary btn-small btn-border-radius"
                        ngbTooltip="Apply margin to all"
                        type="button">
                    Apply to All
                </button>
            </div>
        </div>

        <div class="horizontal-line"></div>

        <div class="header m-t-20">
            <div class="row">
                <div class="col-sm-5">
                    <h4>
                        Labour
                    </h4>
                </div>
            </div>

            <div class="row m-t-20">
                <div class="col-sm-3">
                    <span class="table-title">Type</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Cost/hr</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Cost/day</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Margin</span>
                </div>
                <div class="col-sm-2 text-right">
                    <span class="table-title">Rate/day</span>
                </div>
                <div class="col-sm-1 text-right">
                    <span class="table-title"></span>
                </div>
            </div>

            <form #f="ngForm"
                  (ngSubmit)="!f.invalid && saveAndApply()"
                  class="labour-container m-t-20"
                  name="form"
                  novalidate>
                <button #submitFormButton
                        id="submitFormButton"
                        type="submit">
                </button>

                <div *ngFor="let labour of dataModel.labours; trackBy: trackId;"
                     class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <div class="form-line">
                                <input [(ngModel)]="labour.trade"
                                       [disabled]="true"
                                       class="af-input"
                                       name="trade_{{labour.tradeId}}"
                                       type="text">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group">
                            <div class="form-line">
                                <input #cost="ngModel"
                                       [(ngModel)]="labour.cost"
                                       [ngClass]="{ 'error': (f.submitted || cost.dirty || cost.touched) && cost.invalid }"
                                       class="af-input num text-right"
                                       name="cost_{{labour.tradeId}}"
                                       placeholder="Enter cost/hr"
                                       required
                                       mask="separator.2"
                                       thousandSeparator=","
                                       [dropSpecialCharacters]="true"
                                       prefix="&pound;&nbsp;"
                                       type="text">
                                <div *ngIf="(f.submitted || cost.dirty) && cost.invalid">
                                    <label *ngIf="cost.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group">
                            <div class="form-line">
                                <input #costPerDay="ngModel"
                                       [(ngModel)]="labour.costPerDay"
                                       [ngClass]="{ 'error': (f.submitted || costPerDay.dirty || costPerDay.touched) && costPerDay.invalid }"
                                       class="af-input num text-right"
                                       name="costPerDay_{{labour.tradeId}}"
                                       placeholder="Enter cost/day"
                                       required
                                       mask="separator.2"
                                       [dropSpecialCharacters]="true"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                                <div *ngIf="(f.submitted || costPerDay.dirty) && costPerDay.invalid">
                                    <label *ngIf="costPerDay.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group percent">
                            <div class="form-line">
                                <input #margin="ngModel"
                                       [(ngModel)]="labour.margin"
                                       [ngClass]="{ 'error': (f.submitted || margin.dirty || margin.touched) && margin.invalid }"
                                       class="af-input num text-right"
                                       name="margin_{{labour.tradeId}}"
                                       placeholder="Enter margin"
                                       required
                                       mask="separator.2"
                                       [dropSpecialCharacters]="true"
                                       suffix="%"
                                       thousandSeparator=","
                                       type="text">
                                <div *ngIf="(f.submitted || margin.dirty) && margin.invalid">
                                    <label *ngIf="margin.errors.required"
                                           class="error">
                                        This field is required.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2">
                        <div class="form-group">
                            <div class="form-line">
                                <input #ratePerDay="ngModel"
                                       [(ngModel)]="labour.ratePerDay"
                                       class="af-input num text-right"
                                       name="rate_{{labour.tradeId}}"
                                       required
                                       mask="separator.2"
                                       [dropSpecialCharacters]="true"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                            </div>
                            <div *ngIf="(f.submitted || ratePerDay.dirty) && ratePerDay.invalid">
                                <label *ngIf="ratePerDay.errors.required"
                                       class="error">
                                    This field is required.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1 text-right">
                        <button (click)="saveOneLabour(labour)"
                                [disabled]="!labour.updated"
                                ngbTooltip="Apply"
                                class="btn btn-primary btn-border-radius apply"
                                type="button">
                            Apply
                        </button>
                    </div>
                </div>

                <small *ngIf="dataModel?.labours.length === 0">
                    No items
                </small>
            </form>
        </div>
    </div>
</div>
