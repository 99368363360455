<div class="modal-header">
    <div class="flex flex-column gap-20 justify-content-center align-items-center">
        <div class="swal2-icon swal2-warning swal2-icon-show m-0 flex">
            <div class="swal2-icon-content">!</div>
        </div>
        <h2>
            {{ data.header }}
        </h2>
    </div>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div [innerHTML]="data.textHtml">
    </div>

    <div *ngIf="data.dropdownValues?.length"
         class="m-t-20">
        <select [formControl]="dropdownControl"
                class="form-control">
            <option *ngFor="let item of data.dropdownValues"
                    [ngValue]="item">
                {{ item.value }}
            </option>
        </select>
    </div>
</div>

<div class="modal-footer">
    <div class="flex flex-row gap-15 w-full justify-content-between align-items-center">
        <div class="form-check m-b-30 w-full text-left"
             *ngIf="data.showDoNotShowAgainPlace === 'left' && data.showDoNotShowAgain">
            <ng-container *ngTemplateOutlet="doNotShowAgainTpl"></ng-container>
        </div>

        <div class="flex flex-row gap-15 w-full"
             [ngStyle]="{'flex-direction': data.swapButtons ? 'row-reverse' : 'row',
                 'place-content': data.swapButtons ? 'flex-start' : 'flex-end' }">
            <button (click)="onCancelClick()"
                    tabindex="0"
                    class="btn btn-secondary btn-border-radius waves-effect w-full cancel"
                    type="button">
                {{ data.cancelButtonText || 'Cancel' }}
            </button>

            <button (click)="onConfirm()"
                    tabindex="1"
                    class="btn btn-primary btn-border-radius waves-effect w-full confirm"
                    type="button">
                {{ data.confirmButtonText || 'Confirm' }}
            </button>
        </div>
    </div>

    <div *ngIf="data.closeLinkText" class="w-full text-center">
        <a [innerHTML]="data.closeLinkText" (click)="close()" class="link" href="javascript:void(0)"></a>
    </div>

    <div *ngIf="data.showDoNotShowAgainPlace === 'bottom' && data.showDoNotShowAgain">
        <ng-container *ngTemplateOutlet="doNotShowAgainTpl"></ng-container>
    </div>
</div>

<ng-template #doNotShowAgainTpl>
    <label class="form-check-label"
           for="do-not-show-again">
        {{ data.showDoNotShowAgainText || "Don't show again" }}
        <input [formControl]="doNotShowAgain"
               class="form-check-input"
               id="do-not-show-again"
               name="do-not-show-again"
               type="checkbox">
        <span class="form-check-sign"><span class="check"></span></span>
    </label>
</ng-template>
