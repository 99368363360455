<div class="select-payment-provider-container">
    <div class="modal-header">
        <h4 class="modal-title">
            Select payment method
        </h4>
        <button (click)="clear()"
                aria-hidden="true"
                class="close"
                data-dismiss="modal"
                type="button">&times;
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="account"
             class="m-b-20">
            <ng-select [items]="account.availableProviders"
                       [(ngModel)]="selectedPaymentProvider"
                       [disabled]="inProcess()"
                       [placeholder]="'Select payment method'"
                       class="bp-select">
                <ng-template ng-label-tmp let-item="item">
                    {{item | paymentProviderLabel }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{item | paymentProviderLabel}}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="modal-footer">
        <div>
            <a [href]="PAYMENT_TERMS_URL"
               target="_blank"
               rel="noopener"
               class="link">
                Payment Terms
            </a>
        </div>
        <div>
            <button (click)="clear()"
                    tabindex="0"
                    class="btn btn-secondary btn-border-radius mr-2"
                    data-dismiss="modal"
                    type="button">
                Close
            </button>
            <button (click)="onOkClick()"
                    [disabled]="!selectedPaymentProvider || isWaitingForStripePaymentInProcess"
                    tabindex="0"
                    class="btn btn-primary btn-border-radius waves-effect ok"
                    type="button">
                Ok
            </button>
        </div>
    </div>
</div>

