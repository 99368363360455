<div class="card">
    <div class="m-t-10 m-b-10 header-container">
        <div class="total">
            Total
        </div>

        <div *ngIf="!scheduleService.totalCanBeShown"
             class="total">
            <bp-spinner>
            </bp-spinner>
        </div>

        <div *ngIf="scheduleService.totalCanBeShown && scheduleService.total != null"
             class="total">
            {{ scheduleService.total | currency:'GBP' }}
        </div>
    </div>

    <div class="m-b-20 flex flex-column gap-20">
        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'css-element'">

            <div *ngIf="!scheduleService.inProcess() &&
                        !this.scheduleService.cssElementMap.size; else cssElementsContainer">
                <bp-schedule-tasks-were-not-found></bp-schedule-tasks-were-not-found>
            </div>

            <ng-template #cssElementsContainer>
                <bp-schedule-css-elements *ngFor="let cssEl of scheduleService.cssElementMap | keyvalue"
                                          [cssElement]="cssEl.value.cssElement"
                                          [scheduleTasks]="cssEl.value.scheduleTasks">
                </bp-schedule-css-elements>
            </ng-template>
        </ng-container>

        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'build-up'">

            <div *ngIf="!scheduleService.inProcess() &&
                        !(this.scheduleService.buildUpMapSubject | async).size; else buildUpAreasContainer">
                <bp-schedule-tasks-were-not-found></bp-schedule-tasks-were-not-found>
            </div>

            <ng-template #buildUpAreasContainer>
                <div *ngFor="let scheduleArea of scheduleService.filteredForBuildUpsScheduleAreas;">
                    <div>
                        <bp-schedule-area-for-filter-by-build-up-grouping [scheduleArea]="scheduleArea">
                        </bp-schedule-area-for-filter-by-build-up-grouping>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'stage' || scheduleService.filterState.groupBy === 'stage_room'">
            <div
                *ngIf="!scheduleService.inProcess() && !scheduleService.filteredStages(appState.data.stages)?.length">
                <bp-schedule-tasks-were-not-found></bp-schedule-tasks-were-not-found>
            </div>

            <bp-schedule-stage-for-filter-by-stage-grouping
                *ngFor="let stage of scheduleService.filteredStages(appState.data.stages); let first = first;"
                [stage]="stage"
                [first]="first"
                [showGroupNode]="scheduleService.filterState.groupBy === 'stage'">
            </bp-schedule-stage-for-filter-by-stage-grouping>
        </ng-container>

        <ng-container
            *ngIf="scheduleService.filterState.groupBy === 'area' || scheduleService.filterState.groupBy === 'area_room'">
            <div *ngIf="!scheduleService.inProcess() &&
                        !scheduleService.filteredStagesForScheduleAreas(scheduleService.filteredScheduleAreas || []).length; else areasContainer">
                <bp-schedule-tasks-were-not-found></bp-schedule-tasks-were-not-found>
            </div>

            <ng-template #areasContainer>
                <div
                    *ngFor="let scheduleArea of scheduleService.filteredSheduleAreasWithTasksOnly(scheduleService.filteredScheduleAreas); let first = first; let last = last;">
                    <div *ngIf="scheduleService.filterState.groupBy === 'area'">
                        <bp-schedule-area-for-filter-by-area-grouping [scheduleArea]="scheduleArea">
                        </bp-schedule-area-for-filter-by-area-grouping>
                    </div>

                    <div *ngIf="scheduleService.filterState.groupBy === 'area_room'"
                         [ngClass]="{'m-l-20': scheduleService.filterState.groupBy === 'area'}"
                         class="flex flex-column gap-10">
                        <bp-schedule-stage-for-filter-by-area-grouping
                            *ngFor="let stage of scheduleService.filteredStages(scheduleArea['stages'], scheduleArea); let first = first;"
                            [stage]="stage"
                            [scheduleArea]="scheduleArea">
                        </bp-schedule-stage-for-filter-by-area-grouping>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>

